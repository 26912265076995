<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-edit-user-form {
  }
</style>


<template>
  <b-form @submit.prevent="submit">

    <!-- username -->
    <b-form-group label="Username">
      <b-form-input :value="form.username" :disabled="true" type="text"/>
    </b-form-group>

    <!-- display name -->
    <b-form-group label="Display Name">
      <b-form-input v-model="form.display_name" :state="states.display_name" type="text"/>
      <b-form-invalid-feedback v-show="errors.display_name">
        Please enter a valid display name.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- email -->
    <b-form-group label="Email">
      <b-form-input v-model="form.email" type="text" :disabled="true"/>
    </b-form-group>

    <!-- date joined -->
    <b-form-group label="Date joined">
      <b-form-input :value="form.created_at | date" type="text" :disabled="true"/>
    </b-form-group>

    <!-- last login -->
    <b-form-group label="Last login">
      <b-form-input :value="form.last_login | date" type="text" :disabled="true"/>
    </b-form-group>

    <!-- change password -->
    <b-form-group label="Change password">
      <b-form-input v-model="form.password" :state="states.password" autocomplete="off"
                    type="password" />
      <b-form-invalid-feedback>
        Please enter a password with at least 8 characters including numbers,
        lowercase letters and uppercase letters.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- new signup -->
    <b-form-group label="New signup / unactivated ">
      <b-form-checkbox size="lg" v-model="form.new_signup" :state="states.new_signup" switch>
      </b-form-checkbox>
    </b-form-group>

    <!-- is staff -->
    <b-form-group label="Staff">
      <b-form-checkbox size="lg" v-model="form.staff" :state="states.staff" switch>
      </b-form-checkbox>
    </b-form-group>

    <!-- cancel billing -->
    <b-form-group label="Cancel billing">
      <b-form-checkbox size="lg"
                       v-model="form.cancel_billing" :state="states.cancel_billing" switch>
      </b-form-checkbox>
    </b-form-group>

    <!-- profile products -->
    <b-form-group label="Profile products">
      <multiselect v-model="form.selected_products"
                   label="name"
                   track-by="name"
                   :options="products"
                   :hide-selected="true"
                   :multiple="true"></multiselect>
    </b-form-group>


    <!--profile connections -->
    <b-form-group label="Profile connections">
      <multiselect v-model="form.selected_connections"
                   label="username"
                   track-by="username"
                   :options="connections"
                   @search-change="search"
                   :loading="searching"
                   :searchable="true"
                   :internal-search="false"
                   :clear-on-select="false"
                   :close-on-select="false"
                   :hide-selected="true"
                   :limit="5"
                   :multiple="true"></multiselect>
    </b-form-group>

    <!-- submit -->
    <b-form-group>
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button variant="warning" class="mr-2 text-white" @click.prevent="reset">
        Reset
      </b-button>
    </b-form-group>
  </b-form>
</template>


<script>
import { debounce, pick } from 'lodash';
import api from '@/api';
import models from '@/models';
import form from '@/mixins/form';
import Multiselect from 'vue-multiselect';


export default {
  name: 'r-edit-user-form',
  mixins: [form],
  props: ['user'],
  components: { Multiselect },
  data() {
    return {
      searching: false,
    };
  },
  methods: {
    async submitted() {
      const data = pick(this.form, [
        'display_name',
        'password',
        'new_signup',
        'staff',
        'cancel_billing']);
      data.products = this.form.selected_products.map((p) => p.id);
      data.connections = this.form.selected_connections.map((c) => c.id);
      await api.admin.users.update(this.user.id, data);
      // this.$router.push({ name: 'admin-users' });
      api.alerts.success('Successfully updated user.');
    },
    async rejected() {
      api.alerts.error('Unable updated user.');
    },
    async reset() {
      if (this.user) {
        this.form = { ...this.user };
      } else {
        this.form = {};
      }
      this.errors = {};
    },
    async search(query) {
      this.searching = true;
      await this.loadConnections(query);
    },
    loadConnections: debounce(async function (query) {
      this.offset = this.page * this.limit;
      await api.admin.connections.load({
        query,
        limit: 100,
      });
      this.searching = false;
    }, 600),
  },
  computed: {
    products() {
      return models.AdminProduct.query().all().map((p) => ({
        id: p.id,
        name: p.name,
      }));
    },
    connections() {
      return models.admin.Connection.query().orderBy('created_at', 'desc').all().map((p) => ({
        id: p.id,
        username: p.username,
      }));
    },
  },
  watch: {
    user() { this.reset(); },
  },
  async created() {
    await api.adminProducts.load();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .invalid /deep/ .multiselect__tags {
    border-color: #d33c34 !important;
  }
</style>


<template>
  <b-form @submit.prevent="submit">

    <!-- name -->
    <b-form-group label="Name">
      <b-form-input v-model="form.name" :state="states.name" type="text" />
      <b-form-invalid-feedback>
        Please enter a name.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- code -->
    <b-form-group label="Referrer code">
      <b-form-input v-model="form.code" :state="states.code" type="text" />
      <b-form-invalid-feedback>
        Please enter a referrer code.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- coaches -->
    <b-form-group label="Coaches">
      <multiselect v-model="form.coaches" :custom-label="coachLabel" :options="coachOptions"
                   :multiple="true"></multiselect>
    </b-form-group>

    <div class="h-box">
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button type="submit" variant="warning" class="mr-2 text-white" @click.prevent="reset()">
        Reset
      </b-button>
      <b-button v-if="form.id && data.deletable"
                type="submit" variant="danger" class="mr-2 text-white" @click.prevent="remove()">
        Delete
      </b-button>
    </div>

  </b-form>
</template>


<script>
import api from '@/api';
import models from '@/models';
import form from '@/mixins/form';
import Multiselect from 'vue-multiselect';

export default {
  name: 'r-referrer-form',
  mixins: [form],
  props: ['data', 'onSubmit', 'onRemove'],
  components: { Multiselect },
  methods: {
    async submitted() {
      await this.onSubmit(this.form);
    },
    async rejected() {
      api.alerts.error('Unable to save referrer.');
    },
    async remove() {
      await this.onRemove(this.form.id);
    },
    async reset() {
      const { ...fields } = this.data;
      this.form = fields;
      if ('coaches' in fields) {
        this.form.coaches = fields.coaches.map((c) => c.id);
      }
      delete this.form.deletable;
      this.errors = {};
    },
    coachLabel(id) {
      return this.coaches.get(id).username;
    },
  },
  computed: {
    coaches() {
      return new Map(models.AdminProfile.query().orderBy('id', 'desc').all().map((p) => [p.id, p]));
    },
    coachOptions() {
      return models.AdminProfile.query().orderBy('id', 'desc').all().map((p) => p.id);
    },
  },
  created() {
    this.reset();
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

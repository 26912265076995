<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-password-form {
  }
</style>


<template>
  <b-form @submit.prevent="submit">
    <!-- password -->
    <b-form-group>
      <b-form-input v-model="form.password" :state="states.password"
                    type="password" placeholder="New password"/>
      <b-form-invalid-feedback>
        Please enter a password with at least 8 characters including numbers,
        lowercase letters and uppercase letters.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- confirm -->
    <b-form-group>
      <b-form-input v-model="form.confirm" :state="states.confirm"
                    type="password" placeholder="Re-enter password"/>
      <b-form-invalid-feedback>
        The provided passwords do not match.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <b-button type="submit" variant="primary" class="mr-2">Update</b-button>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';
import confirm from '@/mixins/confirm';

export default {
  name: 'r-password-form',
  mixins: [form, confirm],
  methods: {
    async submitted() {
      await api.password.update(this.$route.query.uid, this.$route.query.token, this.form.password);
      api.alerts.success('Your password has been updated.');
      this.$router.push({ name: 'home' }).catch(() => {});
    },
    async rejected() {
      if (this.errors.token) {
        api.alerts.error('Unable to reset your password, the password reset link is invalid or expired, please try again.');
        this.$router.push({ name: 'forgot' }).catch(() => {});
      }
    },
  },
};
</script>

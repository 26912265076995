<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .a-articles {
  }
</style>


<template>
  <r-admin-layout class="a-articles">
    <template slot="title"><b-icon icon="file-earmark-richtext"/>Articles</template>
    <template slot="buttons">
      <router-link v-if="loaded && !newArticle" :to="{ name: 'article', params: {id: article.id} }">
        <button class="admin-btn">View article on site</button>
      </router-link>
    </template>
    <r-admin-card title="Article Information">
      <r-article-form v-if="loaded" :onSubmit="submitted" :article="article" />
    </r-admin-card>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RArticleForm from '@/components/forms/r-article-form';

export default {
  name: 'a-article',
  components: {
    RArticleForm,
  },
  methods: {
    async load() {
      this.newArticle = this.$route.params.id === 'new';
      if (!this.newArticle) {
        this.article = models.Article.find(this.$route.params.id);
        if (!this.article) {
          this.article = await api.articles.get(this.$route.params.id);
        }
      }
      this.loaded = true;
    },
    async submitted(form) {
      if (this.newArticle) {
        this.article = await api.articles.create(form);
        this.$router.push({ name: 'admin-articles' });
        api.alerts.success('Article created.');
      } else {
        this.article = await api.articles.update(form.id, form);
        api.alerts.success('Article updated.');
      }
    },
  },
  data() {
    return {
      article: {},
      newArticle: false,
      loaded: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

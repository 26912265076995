<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .a-billing {
  }
</style>


<template>
  <r-admin-layout class="a-billing">
    <!-- title -->
    <template slot="title">
      <b-icon icon="credit-card"/>Billing
    </template>

    <!-- billing table -->
    <r-admin-table>
      <template #head>
        <tr>
          <th>Username</th>
          <th>Card</th>
          <th>Product</th>
          <th>Amount</th>
          <th>Begins</th>
          <th>Expires</th>
          <th>Last Billing Attempt</th>
          <th>Renew</th>
          <th>Fake</th>
        </tr>
      </template>
      <template #body>
        <tr v-for="payment in payments" :key="payment.id">
          <td>{{payment.username}}</td>
          <td>{{payment.card || '-'}}</td>
          <td>{{payment.product.name}}</td>
          <td>{{payment.product.cost}}</td>
          <td>{{payment.begin_date | date}}</td>
          <td>{{payment.expire_date | date}}</td>
          <td>{{payment.last_billing_attempt | relative}}</td>
          <td><b-button @click="rebill(payment.id)">Renew</b-button></td>
          <td><b-button @click="rebill(payment.id, true)">Fake</b-button></td>
        </tr>
      </template>
    </r-admin-table>
  </r-admin-layout>
</template>

<script>
import api from '@/api';
// import { debounce } from 'lodash';

export default {
  name: 'a-billing',
  data() {
    return {
      payments: [],
    };
  },
  methods: {
    async load() {
      this.payments = [];
      this.payments = await api.admin.billing.index();
    },
    async rebill(profile, fake) {
      const payment = await api.admin.billing.rebill(profile, fake);
      if (payment.status === 'SETTLED' && !fake) {
        api.alerts.success('User successfully rebilled');
      } else if (payment.status === 'SETTLED' && fake) {
        // Also for a fake payment reference should be "Manual" but looks like right now server
        // doesnt return that value. Not sure if that is exsclusive to fake payments.
        api.alerts.info('User successfully given a free renwal');
      } else if (payment.status === 'FAILED') {
        api.alerts.error('Unable to renew, payment failed');
      } else {
        api.alerts.warning('Unsure if payment was successful');
      }
      // WARNING: Should reload data after but not sure if we should always do that.
      // maybe best thing would be to prune it from list here then reload data in background.
      // Wonder if its worth normalizing data into a id indexed object with order list.
      // Maybe sorting by LBA on the frontend.
      this.load();
    },
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

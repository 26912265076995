import { Model } from '@vuex-orm/core';

export default class Topic extends Model {
  static entity = 'topics'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      created: this.attr(null),
      name: this.attr(null),
      author: this.attr(''),
      closed: this.attr(false),
      sticky: this.attr(false),
      last_post: this.attr({}),
      forum: this.attr({}),
      post_count: this.attr(0),
      views: this.attr(0),
    };
  }
}

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-focus-layout {
    height: 100%;
    background: $color-gray-1;

    .focus {
      width: 100%;
    }

    @screen md {
      :not(&.center) {
        justify-content: start;
        .focus {
           margin-top: 150px;
           max-width: 420px;
         }
      }
    }
  }
</style>


<template>
  <div class="r-focus-layout c-box" :class="{center}">
    <r-alerts/>
    <div class="focus">
      <slot>
      </slot>
    </div>
  </div>
</template>


<script>
export default {
  name: 'r-focus-layout',
  props: {
    center: {
      default: false,
    },
  },
};
</script>

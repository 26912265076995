import { Model } from '@vuex-orm/core';

export default class Forum extends Model {
  static entity = 'forums'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      created: this.attr(null),
      name: this.attr(null),
      description: this.attr(null),
      topic_count: this.attr(0),
      post_count: this.attr(0),
      last_post: this.attr({}),
      category: this.attr(''),
      position: this.attr(0),
    };
  }
}

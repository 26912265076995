<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>

<template>
  <div id="app">
    <r-loading v-if="loading"/>
    <router-view v-else/>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

export default {
  name: 'app',
  computed: {
    loading: get('loading'),
  },
};
</script>

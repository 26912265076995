<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-setbacks-form {
    background: white;
    padding: 20px;
    border: 1px solid $color-gray-3;
    border-radius: 3px;
  }
</style>


<template>
  <b-form class="r-setbacks-form" @submit.prevent="submit">

    <!-- title -->
    <div class="form-title h-box align-items-center">
      <b-icon icon="dash-circle-fill" variant="danger"></b-icon>
      <div class="pl-3">Setbacks</div>
    </div>

    <!-- setbacks -->
    <b-form-group>
      <b-form-textarea rows="6" max-rows="6" v-model="form.setbacks" :state="states.setbacks"
                       placeholder="Type your setbacks here..."/>
      <b-form-invalid-feedback>
        Please enter a valid value.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
  </b-form>
</template>


<script>
import api from '@/api';
import store from '@/store';
import form from '@/mixins/form';

export default {
  name: 'r-setbacks-form',
  mixins: [form],
  methods: {
    async submitted() {
      await api.profile.update(this.form);
      api.alerts.success('Your profile has been updated.');
    },
    reset() {
      this.form = {
        setbacks: this.profile.setbacks,
      };
      this.errors = {};
    },
  },
  computed: {
    profile: store.getter('profile'),
  },
  created() {
    this.reset();
  },
};
</script>

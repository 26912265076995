<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-card {
    width: 100%;
    background: $color-white;
    &.padded {
      padding: 20px;
    }
    @screen md {
      max-width: 420px;
    }
  }
</style>

<template>
  <div class="r-card" :class="{padded}">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'r-card',
  props: {
    padded: {
      default: true,
    },
  },
};
</script>

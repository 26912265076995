class FencedError extends Error {
  constructor(result, ...args) {
    super(...args);
    this.result = result;
    this.fenced = true;
  }
}

// wraps an async function, if the function is called again before an earlier one is resolved
// this function ignores the earlier call's results by throwing an error or optionally
// returning null

// remember to use this function you cant re-wrap the function on every call you
// gotta reuse the same wrapped function for every call

// example:
// // outside onclick
// const fenced = fence(delayedfunction);
// // in onclick
// try {
//   result = await fenced(args);
// } catch (e) {
//   if (!e.fenced) throw e;
// }

function fence(wrapped, silent) {
  let previous = null;

  async function fenced(...args) {
    const current = wrapped(...args);
    previous = current;
    const result = await current;
    if (current === previous) {
      return result;
    }
    if (silent) {
      return null;
    }
    throw new FencedError(result);
  }

  return fenced;
}

export default fence;

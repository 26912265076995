
import store from '@/store';
import profile from './profile';
import products from './products';


export default async function () {
  await profile.load();
  await products.load();
  store.set('loaded', true);
  store.set('loading', false);
}

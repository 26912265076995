<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-focus-layout>

    <r-logo-card>
      <template #title>Sign in Reclaim HS</template>

      <template #content>
        <r-signin-form/>
      </template>

      <template #footer>
        <router-link to="forgot">Forgot password</router-link>
        <span>|</span>
        <a href="" @click.prevent="$refs.support.show()">Support</a>
        <span>|</span>
        <a href="" @click.prevent="$refs.privacy.show()">Privacy policy</a>
      </template>
    </r-logo-card>

    <r-support-modal ref="support"/>
    <r-privacy-modal ref="privacy"/>
  </r-focus-layout>
</template>


<script>
import RSigninForm from '@/components/forms/r-signin-form';
import RSupportModal from './r-support-modal';
import RPrivacyModal from './r-privacy-modal';


export default {
  name: 'signin',
  components: {
    RSigninForm,
    RSupportModal,
    RPrivacyModal,
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="camera-video"/>Trainings</template>
    <template slot="buttons">
      <router-link v-if="loaded" :to="this.viewTraining">
        <button class="admin-btn">View training on site</button>
      </router-link>
    </template>
    <r-admin-card title="Training Information">
      <r-training-form v-if="loaded" :onSubmit="submitted" :training="training" />
    </r-admin-card>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RTrainingForm from '@/components/forms/r-training-form';

export default {
  name: 'a-training',
  components: {
    RTrainingForm,
  },
  methods: {
    async load() {
      this.training = models.AdminTraining.find(this.$route.params.id)
                      || await api.adminTrainings.get(this.$route.params.id);
      this.viewTraining = {
        name: 'training',
        params: {
          course: this.training.course,
          level: this.training.level_number,
          segment: this.training.segment,
        },
      };
      this.loaded = true;
    },
    async submitted(form) {
      const payload = { ...form };
      Object.keys(payload).forEach((k) => payload[k] == null && delete payload[k]);
      this.training = await api.adminTrainings.update(form.id, payload);
      api.alerts.success('Training updated.');
    },
  },
  data() {
    return {
      training: {},
      viewTraining: {},
      loaded: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

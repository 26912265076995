<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-navigation {
    color: white;
    background: $color-blue;
    .content {
      margin: 0 auto;
      max-width: $content-width;
    }
    .nav {
      font-size: 14px;
      font-weight: 400;
      min-height: 32px;
      line-height: 32px;
      padding: 5px 6px;
      border-color: $color-white;
      border-style: solid;
      border-width: 0px 1px;
      &.router-link-active {
        background: $color-gray-7;
      }
      cursor: pointer;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      align-content: center;
      &:first-child {
        border-width: 0px 1px 0px 2px;
      }
      &:last-child {
        border-width: 0px 2px 0px 1px;
      }
      &:hover {
        background: $color-gray-8;
        text-decoration: underline;
      }
    }
  }
</style>


<template>
  <div class="r-navigation">
    <div class="content h-box">
      <router-link v-show="profile.student" class="nav light" :to="{ name: 'activities'}">
        Daily Activities
      </router-link>
      <router-link class="nav light" :to="{ name: 'home'}">
        What's New
      </router-link>
      <router-link v-show="profile.student" class="nav light" :to="{ name: 'messages'}">
        Messages
      </router-link>
      <router-link class="nav light" :to="{ name: 'forums'}">
        Forum
      </router-link>
      <router-link v-show="profile.student" class="nav light" :to="{ name: 'student-trainings'}">
        <div>My Training</div>
      </router-link>
      <router-link v-show="profile.support" class="nav light" :to="{ name: 'support-trainings'}">
        <div>Support Trainings</div>
      </router-link>
      <router-link v-show="profile.student" class="nav light" :to="{ name: 'crisis-and-setbacks'}">
        <div>Crisis &amp; Setbacks</div>
      </router-link>
      <router-link v-show="profile.student || profile.support || profile.admin"
                   class="nav light" :to="{ name: 'articles'}">
        RECLAIM Plus
      </router-link>
      <router-link v-show="profile.admin" class="nav light" :to="{ name: 'admin-users'}">
        Admin
      </router-link>
    </div>
  </div>
</template>


<script>
import store from '@/store';

export default {
  name: 'r-navigation',
  computed: {
    profile: store.getter('profile'),
  },
};
</script>

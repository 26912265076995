import multiguard from 'vue-router-multiguard';
import store from '@/store';
import Home from '../views/home';
import Subscribe from '../views/subscribe';
import Conversation from '../views/conversation/conversation';
import Signin from '../views/signin/signin';
import Signup from '../views/signup';
import Forum from '../views/forum/forum';
import NewPost from '../views/forum/new-post';
import EditPost from '../views/forum/edit-post';
import Topic from '../views/forum/topic';
import Forums from '../views/forum/forums';
import Forgot from '../views/forgot';
import Reset from '../views/reset';
import Error from '../views/error';
import Support from '../views/support';
import Photos from '../views/photos';
import Activities from '../views/activities/activities';
import Profile from '../views/profile';
import Account from '../views/account/account';
import Messages from '../views/messages/messages';
import Articles from '../views/articles';
import Article from '../views/article';
import CrisisAndSetbacks from '../views/crisis/crisis-and-setbacks';
import Crisis from '../views/crisis/crisis';
import Setbacks from '../views/crisis/setbacks';
import StudentTrainings from '../views/trainings/student-trainings';
import SupportTrainings from '../views/trainings/support-trainings';
import Training from '../views/trainings/training';
import NotFound from '../views/notfound';
import Interface from '../views/interface/interface';
import AdminArticles from '../views/admin/articles/a-articles';
import AdminArticle from '../views/admin/articles/a-article';
import AdminBans from '../views/admin/bans/a-bans';
import AdminBan from '../views/admin/bans/a-ban';
import AdminTrainings from '../views/admin/trainings/a-trainings';
import AdminTraining from '../views/admin/trainings/a-training';
import AdminBilling from '../views/admin/billing/a-billing';
import AdminCoupons from '../views/admin/coupons/a-coupons';
import AdminCoupon from '../views/admin/coupons/a-coupon';
import AdminPayments from '../views/admin/payments/a-payments';
import AdminPayment from '../views/admin/payments/a-payment';
import AdminProducts from '../views/admin/products/a-products';
import AdminProduct from '../views/admin/products/a-product';
import AdminReferrers from '../views/admin/referrers/a-referrers';
import AdminReferrer from '../views/admin/referrers/a-referrer';
import AdminReports from '../views/admin/reports/a-reports';
import AdminTopics from '../views/admin/topics/a-topics';
import AdminTopic from '../views/admin/topics/a-topic';
import AdminUsers from '../views/admin/users/a-users';
import AdminCreateUser from '../views/admin/users/a-create-user';
import AdminEditUser from '../views/admin/users/a-edit-user';
import guards from './guards';


// admin routes
const admin = [
  {
    path: '/admin',
    redirect: '/admin/users',
  },
  {
    path: '/admin/articles',
    name: 'admin-articles',
    component: AdminArticles,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/articles/:id',
    name: 'admin-article',
    component: AdminArticle,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/bans',
    name: 'admin-bans',
    component: AdminBans,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/bans/:id',
    name: 'admin-ban',
    component: AdminBan,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/referrers',
    name: 'admin-referrers',
    component: AdminReferrers,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/referrers/:id',
    name: 'admin-referrer',
    component: AdminReferrer,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/trainings',
    name: 'admin-trainings',
    component: AdminTrainings,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/trainings/:id',
    name: 'admin-training',
    component: AdminTraining,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/billing',
    name: 'admin-billing',
    component: AdminBilling,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/coupons',
    name: 'admin-coupons',
    component: AdminCoupons,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/coupons/:id',
    name: 'admin-coupon',
    component: AdminCoupon,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/payments',
    name: 'admin-payments',
    component: AdminPayments,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/payments/:id',
    name: 'admin-payment',
    component: AdminPayment,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/products',
    name: 'admin-products',
    component: AdminProducts,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/products/:id',
    name: 'admin-product',
    component: AdminProduct,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/reports',
    name: 'admin-reports',
    component: AdminReports,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/topics',
    name: 'admin-topics',
    component: AdminTopics,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/topics/:id',
    name: 'admin-topic',
    component: AdminTopic,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/users',
    name: 'admin-users',
    component: AdminUsers,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/users/create',
    name: 'admin-create-user',
    component: AdminCreateUser,
    meta: {
      before: guards.admin,
    },
  },
  {
    path: '/admin/users/:id',
    name: 'admin-edit-user',
    component: AdminEditUser,
    meta: {
      before: guards.admin,
    },
  },
];

// program routes
const program = [
  {
    path: '/',
    meta: {
      before: multiguard([guards.protected, (to, from, next) => {
        const profile = store.get('profile');
        if (!profile.student) {
          next('/whats-new');
        } else {
          next('/activities');
        }
      }]),
    },
  },
  {
    path: '/whats-new',
    name: 'home',
    component: Home,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: Subscribe,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/photos',
    name: 'photos',
    component: Photos,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/forum',
    name: 'forums',
    component: Forums,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/forum/search',
    name: 'search',
    component: Topic,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/forum/recent',
    name: 'recent',
    component: Topic,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/forum/:id',
    name: 'forum',
    component: Forum,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/forum/:id/new',
    name: 'new-post',
    component: NewPost,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/forum/post/:id/edit',
    name: 'edit-post',
    component: EditPost,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/forum/topic/:id',
    name: 'topic',
    component: Topic,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/activities',
    name: 'activities',
    component: Activities,
    meta: {
      before: guards.student,
    },
  },
  {
    path: '/student-trainings',
    name: 'student-trainings',
    component: StudentTrainings,
    meta: {
      before: guards.student,
    },
  },
  {
    path: '/support-trainings',
    name: 'support-trainings',
    component: SupportTrainings,
    meta: {
      before: guards.support,
    },
  },
  {
    path: '/crisis-and-setbacks',
    name: 'crisis-and-setbacks',
    component: CrisisAndSetbacks,
    meta: {
      before: guards.student,
    },
  },
  {
    path: '/crisis',
    name: 'crisis',
    component: Crisis,
    meta: {
      before: guards.student,
    },
  },
  {
    path: '/setback',
    name: 'setback',
    component: Setbacks,
    meta: {
      before: guards.student,
    },
  },
  {
    path: '/course/:course/section/:level/training/:segment',
    name: 'training',
    component: Training,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: Messages,
    meta: {
      before: multiguard([guards.student, (to, from, next) => {
        const profile = store.get('profile');
        if (!profile.staff) {
          next({ name: 'conversation', params: { id: profile.coach } });
        }
        next();
      }]),
    },
  },
  {
    path: '/messages/:id',
    name: 'conversation',
    component: Conversation,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin,
    meta: {
      before: guards.public,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      before: guards.public,
    },
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot,
    meta: {
      before: guards.public,
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: Reset,
    meta: {
      before: guards.tokened,
    },
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/articles',
    name: 'articles',
    component: Articles,
    meta: {
      before: guards.plus,
    },
  },
  {
    path: '/article/:id',
    name: 'article',
    component: Article,
    meta: {
      before: guards.plus,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      before: guards.protected,
    },
  },
  {
    path: '/notfound',
    component: NotFound,
    meta: {
      before: guards.public,
    },
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
    meta: {
      before: guards.public,
    },
  },
  {
    path: '/dailyactivities',
    redirect: '/activities',
  },
  {
    path: '*',
    redirect: '/notfound',
  },
];

// development routes
const development = [
  {
    path: '/interface',
    name: 'interface',
    component: Interface,
    meta: {
      before: guards.public,
    },
  },
];

// all routes
export default [
  ...admin,
  ...program,
  ...development,
];

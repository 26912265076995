import Payment from '@/models/payment';
import restify from '@/extensions/restify';
import rest from '@/api/rest';

const api = restify(Payment, '/admin/payments');

async function settle(id) {
  const object = await rest.post(`/admin/payments/${id}/settle`);
  Payment.update({ where: id, data: [object] });
  return Payment.find(object.id);
}

async function refund(id) {
  const object = await rest.post(`/admin/payments/${id}/refund`);
  Payment.update({ where: id, data: [object] });
  return Payment.find(object.id);
}

export default {
  load: api.load,
  get: api.get,
  update: api.update,
  create: api.create,
  settle,
  refund,
};

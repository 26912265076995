<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-focus-layout>
    <r-logo-card>
      <template #title>Reset password</template>
      <template #content><r-forgot-form/></template>
      <template #footer>
        <router-link to="signin">Sigin in</router-link>
        <span>|</span>
        <a href="" @click.prevent="$refs.support.show()">Support</a>
        <span>|</span>
        <a href="" @click.prevent="$refs.privacy.show()">Privacy policy</a>
      </template>
    </r-logo-card>

    <r-support-modal ref="support"/>
    <r-privacy-modal ref="privacy"/>
  </r-focus-layout>
</template>


<script>
import RForgotForm from '@/components/forms/r-forgot-form';
import RSupportModal from './signin/r-support-modal';
import RPrivacyModal from './signin/r-privacy-modal';

export default {
  name: 'forgot',
  components: {
    RForgotForm,
    RSupportModal,
    RPrivacyModal,
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-profile-form {
  }
</style>


<template>
  <b-form class="r-profile-form" @submit.prevent="submit">
    <!-- display name -->
    <b-form-group label="Display name">
      <b-form-input v-model="form.display_name" :state="states.display_name" type="text"/>
      <b-form-invalid-feedback v-if="errors.display_name === 'expected_unique'">
        The display name you have selected is not availiable.
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-else>
        Please enter a valid display name.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- email -->
    <b-form-group label="Email" >
      <b-form-input v-model="form.email" type="text" :state="states.email"/>
      <b-form-invalid-feedback v-if="errors.email === 'expected_unique'">
        The email address you have entered is not availiable.
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-else>
        Please enter a valid email address.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- notifications -->
    <b-form-group label="Notifications" >
      <b-form-checkbox size="lg" v-model="form.email_notifications">Allow email notifications</b-form-checkbox>
    </b-form-group>

    <!-- password -->
    <b-form-group label="New password">
      <b-form-input v-model="form.password" :state="states.password" type="password" />
      <b-form-invalid-feedback>
        Please enter a password with at least 8 characters including numbers,
        lowercase letters and uppercase letters.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- confirm -->
    <b-form-group label="Re-enter password">
      <b-form-input v-model="form.confirm" :state="states.confirm" type="password"/>
      <b-form-invalid-feedback>
        The provided passwords do not match.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- avatar -->
    <div class="h-box align-items-center">
      <b-form-group class="flex-grow" label="Avatar">
        <b-form-file
          v-model="form.avatar"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
      <r-identicon class="flex-limit justify-self-bottom pl-5 pr-5" :src="profile.avatar" size="64px"/>
    </div>

    <!-- submit -->
    <b-button type="submit" variant="primary" class="mr-2">Update</b-button>
  </b-form>
</template>


<script>
import { get } from 'vuex-pathify';
import api from '@/api';
import form from '@/mixins/form';
import confirm from '@/mixins/confirm';

export default {
  name: 'r-profile-form',
  mixins: [form, confirm],
  methods: {
    async submitted() {
      // don't dubmit password if its an empty string
      if (this.form.password === '') {
        this.$delete(this.form, 'password');
      }
      await api.profile.update(this.form);
      if (this.form.password) {
        api.alerts.success('Your profile and password have been updated.');
      } else {
        api.alerts.success('Your profile has been updated.');
      }
      this.reset();
    },
    async rejected() {
      api.alerts.error('Unable to update profile.');
    },
    reset() {
      this.form = {
        display_name: this.profile.display_name,
        email: this.profile.email,
        email_notifications: this.profile.email_notifications,
      };
      this.errors = {};
    },
  },
  computed: {
    profile: get('profile'),
  },
  created() {
    this.reset();
  },
};
</script>

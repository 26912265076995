import Payment from '@/models/payment';
import rest from './rest';

async function load() {
  const payments = await rest.get('/payments');
  Payment.create({ data: Object.values(payments) });
}

export default {
  load,
};

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-admin-search {
    margin-top: 3px;
    .search-button {
      border: 1px solid #ced4da;
      &:hover {
        background-color: $color-gray-5;
      }
    }
  }
</style>

<template>
  <div class="r-admin-search">
    <b-input-group>
      <b-form-input type="text" :placeholder="placeholder"
                    :value="value" @input="input($event)">
      </b-form-input>
      <b-input-group-append>
        <b-button @click="search(term)" class="search-button" variant="outline-dark">
          <b-icon icon="search"/>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'r-admin-search',
  props: ['value', 'placeholder'],
  data() {
    return {
      term: '',
    };
  },
  methods: {
    input(event) {
      this.term = event;
      this.$emit('input', this.term);
      this.search(this.term);
    },
    search: debounce(function (query) {
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { ...this.$router.currentRoute.query, query },
      });
      this.$emit('search', query);
    }, 600),
  },
};
</script>

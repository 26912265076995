<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .new-post {

  }
</style>


<template>
  <r-forum-layout class="new-post" :searchbar="false">
    <r-forum-table>
      <template slot="title">
        New topic
      </template>
      <template slot="contents">
        <r-post-form :forum="$route.params.id"/>
      </template>
    </r-forum-table>
  </r-forum-layout>
</template>


<script>
import RPostForm from '@/components/forms/r-post-form';
import RForumTable from './r-forum-table';
import RForumLayout from './r-forum-layout';


export default {
  name: 'new-post',
  components: {
    RForumLayout,
    RForumTable,
    RPostForm,
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .interface {
    .toc {
      position: absolute;
      top: 30px;
      left: 20px;
      a {
        color: $color-gray-9;
      }
    }
    .title {
      @apply text-3xl;
      margin: 30px 0px;
      border-bottom: 1px solid $color-gray-7;
    }
  }
</style>


<template>
  <r-basic-layout ref="layout" class="interface">

    <h1 class="title">User Interface</h1>

    <div class="toc">
      <div v-for="demo in demos" :key="demo.title">
        <a href="" @click.prevent="scroll(demo.element)">{{demo.title}}</a>
      </div>
    </div>

    <demos/>

  </r-basic-layout>
</template>

<script>
import Demos from './demos';

export default {
  name: 'interface',
  components: {
    Demos,
  },
  data() {
    return {
      demos: [{
        title: '# Top',
      }],
    };
  },
  methods: {
    scroll(element) {
      if (!element) {
        this.$refs.layout.$el.scrollTo(0, 0);
      } else {
        element.scrollIntoView();
      }
    },
  },
  mounted() {
    document.querySelectorAll('.r-demo').forEach((element) => {
      this.demos.push({
        title: element.querySelector('.title').textContent,
        element,
      });
    });
  },
};
</script>

import { Model } from '@vuex-orm/core';

export default class AdminCoupon extends Model {
  static entity = 'admin-coupons'

  static fields() {
    return {
      id: this.attr(null),
      code: this.attr(''),
      discount: this.attr(''),
      expiration: this.attr(''),
    };
  }
}

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-message-form {
    .error {
      border: 1px solid #d33c34;
      border-radius: 4px;
    }
  }
</style>


<template>
  <b-form class="r-message-form" @submit.prevent="submit">

    <!-- title -->
    <b-form-group label="Title">
      <b-form-input v-model="form.title" :state="states.title" type="text" />
      <b-form-invalid-feedback>
        Please enter a title.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- author -->
    <b-form-group label="Author">
      <b-form-input v-model="form.author" :state="states.author" type="text" />
      <b-form-invalid-feedback>
        Please enter an author.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- description -->
    <b-form-group label="Description">
      <b-form-textarea rows="6" max-rows="6" v-model="form.description" :state="states.description" />
      <b-form-invalid-feedback>
        Please enter a description.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Featured">
      <b-form-checkbox size="lg" v-model="form.featured" :state="states.featured" switch></b-form-checkbox>
    </b-form-group>

    <!-- thumbnail -->
    <div class="h-box align-items-center">
      <b-form-group class="flex-grow" label="Thumbnail">
        <b-form-file
          v-model="form.thumbnail"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        ></b-form-file>
      </b-form-group>
      <r-identicon class="flex-limit justify-self-bottom pl-5 pr-5" :src="article.thumbnail" size="64px"/>
    </div>

    <!-- video -->
    <b-form-group label="Video">
      <b-form-input v-model="form.video" :state="states.video" type="text" />
      <b-form-invalid-feedback>
        Please enter a video.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- audio -->
    <b-form-group label="Audio">
      <b-form-input v-model="form.audio" :state="states.audio" type="text" />
      <b-form-invalid-feedback>
        Please enter an audio.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- body -->
    <b-form-group label="Body">
      <r-editor-input v-model="form.body" :state="states.body"/>
    </b-form-group>

    <!-- submit -->
    <div class="h-box">
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button type="submit" variant="warning" class="mr-2 text-white" @click.prevent="reset()">
        Reset
      </b-button>
    </div>

  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';


export default {
  name: 'r-article-form',
  mixins: [form],
  props: ['article', 'onSubmit'],
  methods: {
    async submitted() {
      await this.onSubmit(this.form);
    },
    async rejected() {
      api.alerts.error('Unable to save article.');
    },
    async reset() {
      const { thumbnail, ...fields } = this.article;
      this.form = fields;
      this.thumbnail = null;
      this.errors = {};
    },
  },
  created() {
    this.reset();
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

import { Model } from '@vuex-orm/core';

export default class Frc extends Model {
  static entity = 'frcs'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      title: this.attr(''),
      triggers: this.attr(''),
      face_it: this.attr(''),
      replace_it: this.attr(''),
      connect: this.attr(''),
    };
  }
}

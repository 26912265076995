<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-status-card {
    .author {
      padding-bottom: 3px;
      font-weight: normal;
    }
    .date, .scope {
      padding-top: 3px;
      font-size: 12px;
      color: $color-gray-7;
    }
    .scope {
      padding-left: 3px;
    }
    .delete {
      color: $color-gray-7;
      cursor: pointer;
      &:hover {
          color: $color-red-bright;
      }
    }
  }
</style>

<template>
  <r-border-card class="r-status-card">
    <div class="h-box align-items-center">
      <r-identicon class="flex-limit" :src="status.avatar" size="64px"/>
      <div class="v-box p-2">
        <div class="author">{{status.author}}</div>
        <div>{{status.body}}</div>
        <div>
          <span class="date">{{status.created_at | date}}</span>
          <span class="scope">({{scope}})</span>
        </div>
      </div>
      <div class="flex-spacer"></div>
      <div class="self-center">
        <div class="delete" @click="remove(status.id)">Delete</div>
      </div>
    </div>
  </r-border-card>
</template>

<script>
import api from '@/api';


export default {
  name: 'r-status-card',
  props: ['status'],
  computed: {
    scope() {
      if (this.status.private) {
        return 'Private';
      }
      return 'Public';
    },
  },
  methods: {
    remove(id) {
      api.statuses.remove(id);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .a-users {
  }
</style>


<template>
  <r-admin-layout class="a-users">

    <!-- title -->
    <template slot="title">
      <b-icon icon="person-fill"/>Users
    </template>

    <!-- buttons -->
    <template slot="buttons">
      <r-admin-button :to="{ name: 'admin-create-user' }">Add User</r-admin-button>
    </template>

    <!-- search -->
    <r-admin-search placeholder="Search for User" :value="query" @search="search"/>

    <!-- users table -->
    <r-admin-table>
      <template v-slot:head>
        <tr>
          <th>Username</th>
          <th>Display Name</th>
          <th>Email</th>
          <th>Joined</th>
          <th>Last Login</th>
        </tr>
      </template>
      <template v-slot:body>
        <tr v-for="user in users" :key="user.id">
          <td>
            <router-link :to="{ name: 'admin-edit-user', params: {id: user.id} }">
              {{user.username}}
            </router-link>
          </td>
          <td>{{user.display_name}}</td>
          <td>{{user.email}}</td>
          <td>{{user.created_at | date}}</td>
          <td>{{user.last_login | date}}</td>
        </tr>
      </template>
    </r-admin-table>

    <!-- paginator -->
    <r-paginator variant="buttons" @changed="paged" :limit="limit" :total="total"/>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';


export default {
  name: 'a-users',
  data() {
    return {
      query: this.$route.query.query || '',
      page: this.$route.query.page || 0,
      limit: 15,
      total: undefined,
    };
  },
  computed: {
    users() {
      return models.admin.User.query().orderBy('created_at', 'desc').all();
    },
  },
  methods: {
    async paged(page) {
      this.page = page;
      this.load();
    },
    async search(query) {
      this.query = query;
      this.load();
    },
    async load() {
      const data = await api.admin.users.load({
        query: this.query,
        limit: this.limit,
        offset: this.limit * (this.page || 0),
      });
      this.total = data.count;
    },
  },
  async created() {
    await this.load();
  },
};
</script>

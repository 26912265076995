<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  @responsive {
    .r-admin-button {
      @apply border px-2 py-1 text-base text-gray-600 hover:bg-gray-200;
    }
  }
</style>


<template>
  <button @click="click" class="r-admin-button"><slot></slot></button>
</template>


<script>
export default {
  name: 'r-admin-button',
  props: ['to'],
  methods: {
    click() {
      if (this.to) {
        this.$router.push(this.to).catch(() => {});
      }
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="newspaper"/>Products</template>
    <template slot="buttons">
      <router-link :to="{ name: 'admin-product', params: {id: 'new'} }">
        <button class="admin-btn">Add Product</button>
      </router-link>
    </template>
    <div class="h-3 flex-shrink-0"></div>

    <div class="h-3 flex-shrink-0"></div>
    <b-table-lite striped bordered :items="products">
      <template #cell(name)="data">
        <router-link :to="{ name: 'admin-product', params: {id: data.value.id} }">
          {{ data.value.name }}
        </router-link>
      </template>
    </b-table-lite>
    <r-paginator @changed="load"/>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';

export default {
  name: 'al-products',
  methods: {
    async load(page) {
      api.adminProducts.load({
        limit: this.limit,
        offset: this.limit * (page || 0),
        query: this.term,
      });
    },
  },
  computed: {
    products() {
      return models.AdminProduct.query().orderBy('id', 'desc').all()
        .map((p) => ({
          name: { name: p.name, id: p.id },
          cost: p.cost,
          cycle: p.cycle,
          recurring: p.recurring,
          enabled: p.enabled,
        }));
    },
  },
  data() {
    return {
      limit: 25,
      term: '',
    };
  },
  async created() {
    await this.load();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .invalid /deep/ .multiselect__tags {
    border-color: #d33c34 !important;
  }
</style>


<template>
  <b-form @submit.prevent="submit">

    <b-form-group label="User">
      <multiselect v-model="form.user"
                   :class="{ 'invalid': states.user === false }"
                   :custom-label="userLabel"
                   :options="userOptions"
                   @search-change="searchUsers">
        <span slot="noResult">
          No profiles found.
        </span>
      </multiselect>
      <b-form-invalid-feedback :state="states.user">
        Please select a user.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- start -->
    <b-form-group label="Start">
      <b-form-datepicker v-model="form.ban_start" :state="states.ban_start" />
      <b-form-invalid-feedback>
        Please enter a start date.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- end -->
    <b-form-group label="End">
      <b-form-datepicker v-model="form.ban_end" :state="states.ban_end" />
      <b-form-invalid-feedback>
        Please enter an end date.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- reason -->
    <b-form-group label="Reason">
      <b-form-input v-model="form.reason" :state="states.reason" type="text" />
      <b-form-invalid-feedback>
        Please enter a reason.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <div class="h-box">
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button type="submit" variant="warning" class="mr-2 text-white" @click.prevent="reset()">
        Reset
      </b-button>
      <b-button v-if="form.id" type="submit" variant="danger" class="mr-2 text-white" @click.prevent="remove()">
        Delete
      </b-button>
    </div>

  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';
import Multiselect from 'vue-multiselect';

export default {
  name: 'r-ban-form',
  mixins: [form],
  props: ['ban', 'onSubmit', 'onRemove'],
  components: { Multiselect },
  methods: {
    async submitted() {
      await this.onSubmit(this.form);
    },
    async rejected() {
      api.alerts.error('Unable to save ban.');
    },
    async remove() {
      await this.onRemove(this.form.id);
    },
    async reset() {
      const { ...fields } = this.ban;
      this.form = fields;
      this.errors = {};
    },
    async searchUsers(query) {
      this.usersLoading = true;
      const profiles = await api.adminProfiles.load({
        limit: 25,
        query,
      });
      this.users = new Map(Object.values(profiles).map((p) => [p.user, { id: p.user, username: p.username }]));
      this.userOptions = Array.from(this.users.keys());
      this.usersLoading = false;
    },
    userLabel(id) {
      if (id === this.ban.user) {
        return this.ban.username;
      }
      return this.users.get(id).username;
    },
  },
  data() {
    return {
      users: new Map(),
      userOptions: [],
      usersLoading: false,
    };
  },
  created() {
    this.reset();
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

import { Model } from '@vuex-orm/core';

export default class Indicator extends Model {
  static entity = 'indicators'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      setback: this.attr(null),
      trigger: this.attr(null),
      routine: this.attr(null),
      date: this.attr(null),
    };
  }
}

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .forum {
    .new-topic {
      font-size: 14px;
    }
    .topic-name {
      font-weight: bold;
    }
  }
</style>


<template>
  <r-forum-layout class="forum">
    <div class="h-box">
      <r-forum-breadcrumb :forum="forum"/>
      <div class="flex-spacer"></div>
      <router-link class="new-topic" :to="{name: 'new-post', id: forum.id}">New topic</router-link>
    </div>
    <r-forum-paginator v-model="page" :pages="pages"/>
    <r-forum-table>
      <template slot="title">
        <router-link class="light" :to="$route">
          {{forum.name}}
        </router-link>
      </template>
      <template slot="head">
        <tr>
          <th class="w-3/6">Topic</th>
          <th class="w-5">Replies</th>
          <th class="w-5">Views</th>
          <th class="">Last post</th>
        </tr>
      </template>
      <template slot="body">
        <tr v-for="topic in topics" :key="topic.id">
          <td>
            <router-link class="topic-name" :to="{ name: 'topic', params: { id: topic.id }}">
              {{topic.name}}
            </router-link>
            by {{topic.author}}
          </td>
          <td class="text-center">
            {{topic.post_count}}
          </td>
          <td class="text-center">
            {{topic.views}}
          </td>
          <td>
            <div class="post-date">
              <router-link :to="{name: 'topic', hash: `#${topic.last_post.id}`,
                                 params: { id: topic.last_post.topic },
                                 query: { page: Math.floor(topic.last_post.topic_post_count / 10)}} ">
                {{topic.last_post.created | date}}
              </router-link>
            </div>
            <div>by {{topic.last_post.author}}</div>
          </td>
        </tr>
      </template>
    </r-forum-table>
    <r-forum-paginator v-model="page" :pages="pages"/>
  </r-forum-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RForumTable from './r-forum-table';
import RForumLayout from './r-forum-layout';
import RForumPaginator from './r-forum-paginator';
import RForumBreadcrumb from './r-forum-breadcrumb';


export default {
  name: 'forum',
  components: {
    RForumLayout,
    RForumTable,
    RForumPaginator,
    RForumBreadcrumb,
  },
  data() {
    return {
      forum: {},
      page: this.$route.query.page || 0,
      pages: undefined,
      limit: 15,
    };
  },
  computed: {
    topics() {
      return models.Topic.query().orderBy((t) => t.last_post.created, 'desc').all();
    },
  },
  methods: {
    async load() {
      const results = await api.topics.load(this.$route.params.id, {
        offset: this.page * this.limit,
        limit: this.limit,
      });
      this.pages = Math.ceil(results.topic_count / this.limit);
      this.forum = results;
    },
  },
  watch: {
    async page() {
      this.$router.replace({ name: 'forum', query: { page: this.page } });
      this.load();
    },
  },
  async created() {
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    models.Topic.deleteAll();
    next();
  },
};
</script>

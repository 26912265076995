<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .crisis_page {
    background:url('~@/assets/images/reclaim/crisis_and_setbacks/bg_crisis_body.png') no-repeat;
    box-sizing: content-box;
    width:865px;
    height:415px;
    padding:25px;
    margin:0 auto;
    margin-top:20px;

    .blue_button {
      display: block;
      background: url('~@/assets/images/reclaim/training/button_blank_blue.png');
      width: 153px;
      height: 37px;
      padding-top: 19px;
      text-align: center;
      color: #ebe8e8;
      font-size: 14px;
      margin-top:10px;
      margin-left:80px;
      height:55px;
    }

    .sb_action {
      height:415px;
      overflow:auto;
      border:solid 2px #b7b8b9;
      background-color: #e6e7e8;
    }

    .sb_action_title {
      color:#2c90bd;
      font-size:17px;
    }

    .crisis_box {
      padding:10px 20px;
      border:solid 2px #b3b4b5;
      background:#ffffff;
    }
  }

  .carousel_mid {
    background:url('~@/assets/images/reclaim/crisis_and_setbacks/bg_carousel.png') no-repeat left top;
    height:125px;
    width:865px;
    box-sizing: content-box;
    margin: 0 auto;
    padding-top:5px;
    padding-left:25px;
    padding-right:25px;

    .thumbnail {
      padding: 0 7px;
    }
  }
</style>

<template>
  <r-program-layout>
    <div class="crisis_page flex">
      <div class="p-4 sb_action">
        <div class="font-semibold mb-2">Action Steps</div>
        <div class="my-3"></div>
        <div class="mb-2">Putting My Director Back in Control</div>
        <div class="mb-2">Get my body moving!</div>
        <div class="mb-2">Gratitude breath at least 4 times</div>
        <div class="mb-2">Use my Crisis Tools (as many as needed)</div>
        <div class="mb-2">Call my Crisis Contact</div>
        <div class="mb-2">Connect with others in a meaningful way</div>
        <div class="mb-2">Engage in a Healthy Outlet</div>
        <router-link :to="{name:'training',params:{course:'crisis',level:0,segment:1}}">
          <div class="blue_button font-semibold">Crisis Training</div>
        </router-link>
      </div>
      <div class="w-5"></div>
      <div class="crisis_box flex-grow">
        <div class="font-bold">
          My Letter
        </div>
        <b-form @submit.prevent="submit">
          <b-form-group>
            <b-form-textarea rows="12" max-rows="12" v-model="form.crisis_letter"
                             placeholder="Type your crisis letter here..."/>
          </b-form-group>
          <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
        </b-form>
      </div>
    </div>
    <div class="h-5"></div>
    <div class="crisis_gallery">
      <div class="pl-6 font-bold" style="margin:20px; margin-bottom:5px;">Video Gallery</div>
      <div class="carousel_mid flex items-center">
        <div class="flex-grow"></div>
        <a v-for="video in videos" :key="video.id" class="thumbnail" @click.prevent="$refs.modal1.select(video)">
          <img :src="require(`@/assets/images/reclaim/crisis_and_setbacks/` + video.thumbnail)" alt="">
        </a>
        <div class="flex-grow"></div>
      </div>
    </div>
    <r-crisis-modal ref="modal1"/>
  </r-program-layout>
</template>


<script>
import api from '@/api';
import store from '@/store';
import form from '@/mixins/form';
import RCrisisModal from './r-crisis-modal';

export default {
  name: 'crisis',
  mixins: [form],
  components: {
    RCrisisModal,
  },
  methods: {
    async submitted() {
      await api.profile.update(this.form);
      api.alerts.success('Your crisis letter has been updated.');
    },
    async rejected() {
      api.alerts.error('Unable to save crisis letter.');
    },
    reset() {
      this.form = {
        crisis_letter: this.profile.crisis_letter,
      };
      this.errors = {};
    },
  },
  computed: {
    profile: store.getter('profile'),
    // letter() {
    //   return this.profile.crisis_letter || '';
    // },
  },
  data() {
    return {
      videos: [
        {
          url: 'https://reclaim-public.s3.us-west-2.amazonaws.com/video/crisis_banner/cv_bernell_2.mp4',
          thumbnail: 'bernell2.jpg',
        },
        {
          url: 'https://reclaim-public.s3.us-west-2.amazonaws.com/video/crisis_banner/cv_mark.mp4',
          thumbnail: 'mark.jpg',
        },
        {
          url: 'https://reclaim-public.s3.us-west-2.amazonaws.com/video/crisis_banner/cv_bucky.mp4',
          thumbnail: 'bf.jpg',
        },
        {
          url: 'https://reclaim-public.s3.us-west-2.amazonaws.com/video/crisis_banner/cv_bernell.mp4',
          thumbnail: 'bernell1.jpg',
        },
        {
          url: 'https://reclaim-public.s3.us-west-2.amazonaws.com/video/crisis_banner/cv_janet.mp4',
          thumbnail: 'lady.jpg',
        },
        {
          url: 'https://reclaim-public.s3.us-west-2.amazonaws.com/video/crisis_banner/cv_jim.mp4',
          thumbnail: 'jm.jpg',
        },
        {
          url: 'https://reclaim-public.s3.us-west-2.amazonaws.com/video/crisis_banner/cv_bernell_3.mp4',
          thumbnail: 'bernell3.jpg',
        },
      ],
    };
  },
};
</script>

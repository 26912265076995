import Status from '@/models/status';
import restify from '@/extensions/restify';

const api = restify(Status, '/statuses');

export default {
  load: api.load,
  get: api.get,
  create: api.create,
  update: api.update,
  remove: api.remove,
};

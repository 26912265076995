<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-forum-paginator {
    .pages {
      color: black;
      background: $color-white;
      padding: 5px 0px;
      font-size: 14px;
      font-weight: normal;
    }
    .disabled {
      color: $color-gray-4 !important;
      text-decoration: none !important;
      cursor: default;
    }
  }
</style>


<template>
  <div class="r-forum-paginator">
    <div class="pages h-box" v-if="value !== undefined && (this.pages !== undefined && this.pages > 1)">
      <div class="pr-2" v-if="pages">
        <a href="" :class="{disabled: start}" @click.prevent="first">First</a>
      </div>
      <div class="pr-2">
        <a href="" :class="{disabled: start}" @click.prevent="prev">Prev</a>
      </div>
      <div class="flex-spacer"></div>
      <div class="pl-2">
        <a href="" :class="{disabled: end}" @click.prevent="next">Next</a>
      </div>
      <div class="pl-2" v-if="pages">
        <a href="" :class="{disabled: end}" @click.prevent="last">Last</a>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'r-forum-paginator',
  props: ['value', 'pages'],
  computed: {
    val() {
      return parseInt(this.value, 10);
    },
    start() {
      return this.val === 0;
    },
    end() {
      return this.val === this.pages - 1;
    },
  },
  methods: {
    first() {
      this.$emit('input', 0);
    },
    last() {
      if (this.pages !== null && this.pages !== undefined) {
        this.$emit('input', this.pages - 1);
      }
    },
    prev() {
      this.$emit('input', Math.max(this.val - 1, 0));
    },
    next() {
      if (!this.pages) {
        this.$emit('input', this.val + 1);
      } else {
        this.$emit('input', Math.min(this.val + 1, this.pages - 1));
      }
    },
  },
};
</script>

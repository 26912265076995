<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="tags-fill"/>Coupons</template>
    <r-admin-card title="Coupon Information">
      <r-coupon-form v-if="loaded" :onSubmit="submitted" :onRemove="removed" :coupon="coupon" />
    </r-admin-card>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RCouponForm from '@/components/forms/r-coupon-form';

export default {
  name: 'a-coupon',
  components: {
    RCouponForm,
  },
  methods: {
    async load() {
      this.newCoupon = this.$route.params.id === 'new';
      if (!this.newCoupon) {
        this.coupon = models.AdminCoupon.find(this.$route.params.id);
        if (!this.coupon) {
          this.coupon = await api.adminCoupons.get(this.$route.params.id);
        }
      }
      this.loaded = true;
    },
    async submitted(form) {
      if (this.newCoupon) {
        this.coupon = await api.adminCoupons.create(form);
        this.$router.push({ name: 'admin-coupons' });
        api.alerts.success('Coupon created.');
      } else {
        this.coupon = await api.adminCoupons.update(form.id, form);
        api.alerts.success('Coupon updated.');
      }
    },
    async removed(form) {
      await api.adminCoupons.remove(form);
      this.$router.push({ name: 'admin-coupons' });
    },
  },
  data() {
    return {
      coupon: {},
      newCoupon: false,
      loaded: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

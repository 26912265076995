<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-creed-form {

  }
</style>


<template>
  <b-form class="r-creed-form" @submit.prevent="submit">
    <label>Life Creed</label>
    <b-form-group>
      <b-form-textarea rows="3" max-rows="6" v-model="form.creed" :state="states.creed"
                       placeholder="Type your creed here..."/>
      <b-form-invalid-feedback>
        Please enter a creed.
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
  </b-form>
</template>


<script>
import api from '@/api';
import store from '@/store';
import form from '@/mixins/form';


export default {
  name: 'r-creed-form',
  mixins: [form],
  methods: {
    async submitted() {
      await api.profile.update(this.form);
      api.alerts.success('Your creed has been updated.');
    },
    async reset() {
      this.form = {
        creed: store.get('profile.creed'),
      };
      this.errors = {};
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .invalid /deep/ .multiselect__tags {
    border-color: #d33c34 !important;
  }
</style>


<template>
  <b-form @submit.prevent="submit">

    <b-form-group label="Profile">
      <multiselect v-model="form.profile"
                   :class="{ 'invalid': states.profile === false }"
                   :custom-label="profileLabel" :options="profileOptions"
                   @search-change="searchProfile">
        <span slot="noResult">
          No profiles found.
        </span>
      </multiselect>
      <b-form-invalid-feedback :state="states.profile">
        Please select a profile.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Product">
      <b-form-select v-model="form.product" :options="productOptions" :state="states.product"></b-form-select>
      <b-form-invalid-feedback :state="states.product">
        Please select a product.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Coupon">
      <b-form-select v-model="form.coupon" :options="couponOptions" :state="states.coupon"></b-form-select>
    </b-form-group>

    <b-form-group label="Amount">
      <b-input-group prepend="$">
        <b-form-input v-model="form.amount" :state="states.amount" type="text" />
      </b-input-group>
      <b-form-invalid-feedback :state="states.amount">
        Please enter an amount.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Status">
      <b-form-input :value="form.status" type="text" disabled />
    </b-form-group>

    <b-form-group v-if="!form.id" label="Charge Card">
      <b-form-checkbox size="lg" v-model="form.charge" :state="states.charge" switch></b-form-checkbox>
    </b-form-group>

    <!-- submit -->
    <div class="h-box">
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button type="submit" variant="warning" class="mr-2 text-white" @click.prevent="reset()">
        Reset
      </b-button>
      <b-button v-if="form.id" variant="success" class="mr-2 text-white" @click.prevent="settle()">
        Settle
      </b-button>
      <b-button v-if="form.id" variant="danger" class="mr-2 text-white" @click.prevent="refund()">
        Refund
      </b-button>
    </div>

  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';
import models from '@/models';
import Multiselect from 'vue-multiselect';

export default {
  name: 'r-payment-form',
  mixins: [form],
  props: ['payment', 'onSubmit', 'onSettle', 'onRefund'],
  components: { Multiselect },
  methods: {
    async submitted() {
      await this.onSubmit(this.form);
    },
    async rejected() {
      api.alerts.error('Unable to save payment.');
    },
    async reset() {
      const { ...fields } = this.payment;
      this.form = fields;
      this.errors = {};
    },
    async searchProfile(query) {
      this.profilesLoading = true;
      const results = await api.adminProfiles.load({
        limit: 25,
        query,
      });
      this.profiles = results;
      this.profileOptions = Object.keys(results);
      this.profilesLoading = false;
    },
    profileLabel(id) {
      if (id === this.payment.profile) {
        return this.payment.username;
      }
      return this.profiles[id].username;
    },
    async settle() {
      await this.onSettle();
      await this.reset();
    },
    async refund() {
      await this.onRefund();
      await this.reset();
    },
  },
  data() {
    return {
      productOptions: models.AdminProduct.query().all().map((p) => ({
        value: p.id,
        text: p.name,
      })),
      couponOptions: models.AdminCoupon.query().all().map((c) => ({
        value: c.id,
        text: c.code,
      })),
      profiles: new Map(),
      profileOptions: [],
      profilesLoading: false,
    };
  },
  created() {
    this.reset();
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .interface {
    .toc {
      position: absolute;
      top: 30px;
      left: 20px;
      a {
        color: $color-gray-9;
      }
    }
    .title {
      @apply text-3xl;
      margin: 30px 0px;
      border-bottom: 1px solid $color-gray-7;
    }
  }
</style>


<template>
  <div>

    <!-- form -->
    <r-demo>
      <template #title>Form</template>
      <div>
        <b-form-group label="Email address:" description="We'll never share your email with anyone else.">
          <b-form-input type="email" placeholder="Enter email"/>
        </b-form-group>

        <b-form-group label="Your Name:">
          <b-form-input placeholder="Enter name" :state="false"/>
          <b-form-invalid-feedback :state="false">
            Your user ID must be 5-12 characters long.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Food:">
          <b-form-select :options="[{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn']"/>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox-group>
            <b-form-checkbox value="me">Vegetables</b-form-checkbox>
            <b-form-checkbox value="that">Fruit</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <b-button type="submit" variant="primary" class="mr-2">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>
      </div>
    </r-demo>

    <!-- button -->
    <r-demo>
      <template #title>Button</template>
      <div class="v-box space-y-2">
        <div class="space-x-2">
          <b-button variant="primary">Primary</b-button>
          <b-button variant="secondary">Secondary</b-button>
          <b-button variant="success">Success</b-button>
          <b-button variant="danger">Danger</b-button>
          <b-button variant="warning">Warning</b-button>
          <b-button variant="info">Info</b-button>
          <b-button variant="light">Light</b-button>
          <b-button variant="dark">Dark</b-button>
        </div>
        <div class="space-x-2">
          <b-button>Enabled</b-button>
          <b-button disabled>Disabled</b-button>
          <b-button variant="outline-primary">Primary</b-button>
        </div>
        <div class="">
          <b-button variant="primary" block>Block</b-button>
        </div>

      </div>
    </r-demo>

    <!-- card -->
    <r-demo>
      <template #title>Card</template>
      <r-card>
        <r-ipsum/>
      </r-card>
    </r-demo>

    <!-- logo card -->
    <r-demo>
      <template #title>Logo Card</template>
      <r-logo-card>
        <template #title>Title</template>
        <template #content><r-ipsum/></template>
        <template #footer>Footer</template>
      </r-logo-card>
    </r-demo>

  </div>
</template>

<script>
import RDemo from './r-demo';

export default {
  name: 'demos',
  components: {
    RDemo,
  },
};
</script>

import { Model } from '@vuex-orm/core';

export default class AdminReferrer extends Model {
  static entity = 'admin-referrers'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      code: this.attr(''),
      coaches: this.attr([]),
      deletable: this.attr(null),
    };
  }
}

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <b-form @submit.prevent="submit">

    <!-- code -->
    <b-form-group label="Code">
      <b-form-input v-model="form.code" :state="states.code" type="text" />
      <b-form-invalid-feedback>
        Please enter a code.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- discount -->
    <b-form-group label="Discount">
      <b-input-group prepend="$">
        <b-form-input v-model="form.discount" :state="states.discount" type="text" />
      </b-input-group>
      <b-form-invalid-feedback :state="states.discount">
        Please enter a discount.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- expiration -->
    <b-form-group label="Expiration">
      <b-form-datepicker v-model="form.expiration" :state="states.expiration" type="text" />
      <b-form-invalid-feedback>
        Please enter an expiration.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <div class="h-box">
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button type="submit" variant="warning" class="mr-2 text-white" @click.prevent="reset()">
        Reset
      </b-button>
      <b-button v-if="form.id" type="submit" variant="danger" class="mr-2 text-white" @click.prevent="remove()">
        Delete
      </b-button>
    </div>

  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';

export default {
  name: 'r-coupon-form',
  mixins: [form],
  props: ['coupon', 'onSubmit', 'onRemove'],
  methods: {
    async submitted() {
      await this.onSubmit(this.form);
    },
    async rejected() {
      api.alerts.error('Unable to save coupon.');
    },
    async remove() {
      await this.onRemove(this.form.id);
    },
    async reset() {
      const { thumbnail, ...fields } = this.coupon;
      this.form = fields;
      this.thumbnail = null;
      this.errors = {};
    },
  },
  created() {
    this.reset();
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

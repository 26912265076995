import api from '@/api';
import store from '@/store';
import multiguard from 'vue-router-multiguard';


async function load(to, from, next) {
  try {
    if (!store.get('loaded')) {
      await api.load();
    }
    next();
  } catch (error) {
    next({ name: 'error' });
    console.error(error);
  }
}

async function noload(to, from, next) {
  store.set('loading', false);
  next();
}


async function authenticated(to, from, next) {
  let authd = store.get('authenticated');
  api.auth.authenticated();
  if (!authd) {
    authd = await api.auth.authenticated();
  }
  if (!authd) {
    next({ path: '/signin', query: { next: to.fullPath } });
  } else {
    next();
  }
}

async function token(to, from, next) {
  const validated = await api.password.validate(to.query.uid, to.query.token);
  if (validated) {
    next();
  } else {
    api.alerts.error('The password reset link is invalid or expired, please try again.');
    next({ path: '/forgot' });
  }
}

async function admin(to, from, next) {
  if (Object.keys(store.get('profile')).length === 0) {
    await api.profile.load();
  }
  const profile = store.get('profile');
  if (!profile.admin) {
    next('/');
  } else {
    next();
  }
}

async function student(to, from, next) {
  if (Object.keys(store.get('profile')).length === 0) {
    await api.profile.load();
  }
  const profile = store.get('profile');
  if (!profile.student) {
    next('/');
  } else {
    next();
  }
}

async function support(to, from, next) {
  if (Object.keys(store.get('profile')).length === 0) {
    await api.profile.load();
  }
  const profile = store.get('profile');
  if (!profile.support) {
    next('/');
  } else {
    next();
  }
}

async function plus(to, from, next) {
  if (Object.keys(store.get('profile')).length === 0) {
    await api.profile.load();
  }
  const profile = store.get('profile');
  if (!profile.student && !profile.support && !profile.admin) {
    next('/');
  } else {
    next();
  }
}

export default {
  public: multiguard([noload]),
  protected: multiguard([authenticated, load]),
  admin: multiguard([authenticated, admin, load]),
  student: multiguard([authenticated, student, load]),
  support: multiguard([authenticated, support, load]),
  plus: multiguard([authenticated, plus, load]),
  tokened: multiguard([noload, token]),
};

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .edit-post {}
</style>


<template>
  <r-forum-layout class="edit-post" :searchbar="false">
    <r-forum-table>
      <template slot="title">
        Edit post
      </template>
      <template slot="contents">
        <r-post-form v-if="post" :post="post" @success="success"/>
      </template>
    </r-forum-table>
  </r-forum-layout>
</template>


<script>
import api from '@/api';
import RPostForm from '@/components/forms/r-post-form';
import RForumTable from './r-forum-table';
import RForumLayout from './r-forum-layout';


export default {
  name: 'edit-post',
  data() {
    return {
      post: null,
    };
  },
  components: {
    RForumLayout,
    RForumTable,
    RPostForm,
  },
  methods: {
    async load() {
      this.post = await api.posts.get(this.$route.params.id);
    },
    success() {
      this.$router.push({
        name: 'topic',
        params: { id: this.post.topic },
        query: this.$route.query,
      });
    },
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

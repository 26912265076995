<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-message-form {
    .error {
      border: 1px solid #d33c34;
      border-radius: 4px;
    }
  }
</style>


<template>
  <b-form class="r-message-form" @submit.prevent="submit">

    <!-- title -->
    <b-form-group label="Segment name">
      <b-form-input v-model="form.segment_name" :state="states.segment_name" type="text" />
      <b-form-invalid-feedback>
        Please enter a segment name.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- video -->
    <b-form-group label="Video">
      <b-form-input v-model="form.video" :state="states.video" type="text" />
      <b-form-invalid-feedback>
        Please enter a video.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- audio -->
    <b-form-group label="Audio">
      <b-form-input v-model="form.audio" :state="states.audio" type="text" />
      <b-form-invalid-feedback>
        Please enter an audio.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- body -->
    <b-form-group label="Body">
      <r-editor-input v-model="form.body" :state="states.body"/>
    </b-form-group>

    <b-form-group label="Active">
      <b-form-checkbox size="lg" v-model="form.active" :state="states.active" switch></b-form-checkbox>
    </b-form-group>

    <!-- submit -->
    <div class="h-box">
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button type="submit" variant="warning" class="mr-2 text-white" @click.prevent="reset()">
        Reset
      </b-button>
    </div>

  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';


export default {
  name: 'r-training-form',
  mixins: [form],
  props: ['training', 'onSubmit'],
  methods: {
    async submitted() {
      await this.onSubmit(this.form);
    },
    async rejected() {
      api.alerts.error('Unable to save training.');
    },
    async reset() {
      const { ...fields } = this.training;
      this.form = fields;
      this.errors = {};
    },
  },
  created() {
    this.reset();
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

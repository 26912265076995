import Photo from '@/models/photo';
import rest from './rest';

async function load() {
  const photos = await rest.get('/photos');
  Photo.create({ data: Object.values(photos) });
}

async function create(fields) {
  const photo = await rest.upload('/photos', fields);
  Photo.insert({ data: [photo] });
}

async function remove(id) {
  await rest.delete(`/photos/${id}`);
  Photo.delete(id);
}

export default {
  load, create, remove,
};

import rest from '@/api/rest';
import Post from '@/models/post';


async function load(id, params) {
  const objects = await rest.get(`/topics/${id}`, params);
  Post.create({ data: Object.values(objects.posts) });
  return objects;
}

async function search(id, params) {
  const objects = await rest.get('/posts', params);
  Post.create({ data: Object.values(objects.posts) });
  return objects;
}

async function get(id) {
  return rest.get(`/posts/${id}`);
}

async function create(fields) {
  return rest.post('/posts', fields);
}

async function edit(id, fields) {
  return rest.put(`/posts/${id}`, fields);
}

async function remove(id) {
  return rest.delete(`/posts/${id}`);
}

async function report(id) {
  return rest.post('/reports', { post: id });
}

async function recent(params, count) {
  const objects = await rest.get('/posts/recent', params);
  if (!count) {
    Post.create({ data: Object.values(objects.posts) });
  }
  return objects;
}

async function markRead() {
  return rest.post('/posts/recent');
}


export default {
  load,
  search,
  get,
  create,
  edit,
  remove,
  report,
  recent,
  markRead,
};

import Indicator from '@/models/indicator';
import restify from '@/extensions/restify';

const api = restify(Indicator, '/indicators');

export default {
  load: api.load,
  get: api.get,
  create: api.create,
  update: api.update,
  ensure: api.ensure,
  remove: api.remove,
};

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-billing-form {}
</style>


<template>
  <div>
    <b-form @submit.prevent="submit" class="r-billing-form">
      <r-billing-fields :form="form" :errors="errors" :states="states"/>
      <b-form-group>
        <b-form-text v-show="errors['_']" style="font-size: 14px" text-variant="danger">
          There was a problem verifying your credit card.  Please confirm your card information
          and try again.
        </b-form-text>
      </b-form-group>
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
    </b-form>
  </div>
</template>


<script>
import api from '@/api';
import store from '@/store';
import form from '@/mixins/form';
import RBillingFields from './r-billing-fields';


export default {
  name: 'r-billing-form',
  mixins: [form],
  components: {
    RBillingFields,
  },
  computed: {
    profile: store.getter('profile'),
  },
  methods: {
    async submitted() {
      await api.billing.update(this.form);
      this.$emit('success');
      api.alerts.success('Your billing information has been updated.');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <div>
    <b-form @submit.prevent="submit" class="r-photo-form">

      <!-- photo -->
      <div class="h-box align-items-center">
        <b-form-group class="flex-grow" label="Photo">
          <b-form-file
            v-model="form.image"
            placeholder="Choose an image file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
      </div>

      <!-- submit -->
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
    </b-form>
  </div>
</template>


<script>
import api from '@/api';
import store from '@/store';
import form from '@/mixins/form';


export default {
  name: 'r-photo-form',
  mixins: [form],
  computed: {
    profile: store.getter('profile'),
  },
  methods: {
    async submitted() {
      await api.photos.create(this.form);
      this.$emit('success');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-privacy-modal {

  }
</style>


<template>
  <r-clean-modal ref="modal" size="lg">
    <template slot="title">Privacy Policy</template>
    <p>Reclaim is designed to be a learning program that you can experience and use anonymously in your home or
      office. This site uses technology designed to protect your right to privacy. We are committed to
      maintaining confidentiality in every phase of our relationship with you as you experience the transformation
      that comes through the Reclaim Learning System.
    </p>
    <p>
      We value having you as a student in this important, educational environment and we support your
      efforts to be strong, healthy and porn free. We will make every effort to make your experience here
      helpful by being very discreet.
    </p>
    <p>This learning program requires that we regularly communicate with you via email, automated
      reminders and other important feedback. We assure you that your email remains confidential and
      will never be used for any other purpose.
    </p>
  </r-clean-modal>
</template>


<script>
import modal from '@/extensions/modal';

export default {
  name: 'r-privacy-modal',
  methods: {
    ...modal.methods,
  },
};
</script>

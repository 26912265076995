<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .support-trainings {
    .box {
      background: red;
    }
  }
</style>


<template>
  <r-program-layout class="support-trainings">
    <div class="grid grid-cols-10 gap-4">

      <!-- section 0 -->
      <r-section-box class="col-start-1 col-span-2" :arrows="['down']">
        <r-section-button
          :to="{ name: 'training', params: {course: 'support', level: 0, segment: 1} }"
          :src="require('@/assets/images/reclaim/support_training/sp_intro_thumb.png')"/>
      </r-section-box>


      <r-section-box class="col-start-9 col-span-2 h-box" theme="grey">

        <!-- crisis and setbacks -->
        <r-section-button :bar="false"
                          :to="{ name: 'forums'}"
                          :src="require('@/assets/images/reclaim/support_training/forum_thumb.png')"/>

        <!-- continue or startover -->
        <!-- removed: support trainings are multiple courses, so these don't really make sense in that context -->
        <!-- <div class="v-box justify-center pr-1">
          <b-button variant="primary" class="mb-2 ml-2" @click.prevent="leftOff">
            Where I Left Off
          </b-button>
          <b-button variant="light" class="mt-2 ml-2" @click.prevent="startOver">
            Start Over
          </b-button>
        </div> -->
      </r-section-box>

      <r-section-box class="col-span-10">
        <div class="grid grid-cols-10 gap-4">

          <!-- section 1 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'spouse', level: 0, segment: 1} }" :arrows="['right']"
              :src="require('@/assets/images/reclaim/support_training/spouse_thumb.png')"/>
          </div>

          <!-- section 2 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'parent', level: 0, segment: 1} }" :arrows="['right']"
              :src="require('@/assets/images/reclaim/support_training/parent_thumb.png')"/>
          </div>

          <!-- section 3 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'friends', level: 0, segment: 1} }" :arrows="['right']"
              :src="require('@/assets/images/reclaim/support_training/friends_thumb.png')"/>
          </div>

          <!-- section 4 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'clergy', level: 0, segment: 1} }" :arrows="['right']"
              :src="require('@/assets/images/reclaim/support_training/clergy_thumbv2.png')"/>
          </div>

          <!-- section 5 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'clinician', level: 0, segment: 1} }"
              :src="require('@/assets/images/reclaim/support_training/clinician_thumb.png')"/>
          </div>
        </div>
      </r-section-box>

      <r-confirm-modal ref="over" @confirm="clearProgress">
        Are you sure you want to clear all progress and start over?
      </r-confirm-modal>

    </div>
  </r-program-layout>
</template>


<script>
import api from '@/api';
import store from '@/store';
import RSectionButton from './r-section-button';
import RSectionBox from './r-section-box';


export default {
  name: 'support-trainings',
  components: {
    RSectionButton,
    RSectionBox,
  },
  methods: {
    startOver() {
      this.$refs.over.show();
    },
    clearProgress() {
      api.program.reset();
    },
    leftOff() {
      this.$router.push(this.next);
    },
  },
  computed: {
    program: store.getter('program'),
    next() {
      if (this.program.next) {
        return {
          name: 'training',
          params: {
            course: this.program.next.course,
            level: this.program.next.level_number,
            segment: this.program.next.segment,
          },
        };
      }
      return { name: 'training', params: { course: 'support', level: 0, segment: 1 } };
    },
  },
  async created() {
    api.program.load();
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-forum-recent {
    margin-bottom: 15px;
    .recent-posts {
      font-weight: 500;
    }
    .mark-read {
      width: 114px;
      font-weight: 500;
    }
    .total-unread {
      font-weight: 500;
      text-align: center;
    }
  }
</style>


<template>
  <div class="r-forum-recent">
    <r-forum-table>
      <template slot="title">Recent Posts</template>
      <template slot="body">
        <tr>
          <td class="w-8/12 recent-posts">
            <router-link :to="{ name: 'recent' }">
              View Recent Posts
            </router-link>
          </td>
          <td class="total-unread">
            <span v-if="count">
              Total recent posts: {{count}}
            </span>
            <span v-else>
              No recent posts
            </span>
          </td>
          <td class="mark-read text-center">
            <b-button @click="$refs.mark.show();" variant="primary" size="sm">Mark read</b-button>
          </td>
        </tr>
      </template>
    </r-forum-table>
    <r-confirm-modal ref="mark" @confirm="markRead">
      Are you sure you want to mark all recent posts as read?
    </r-confirm-modal>
  </div>
</template>


<script>
import api from '@/api';
import RForumTable from './r-forum-table';


export default {
  name: 'r-forum-recent',
  props: ['forum', 'topic'],
  data() {
    return {
      count: 0,
    };
  },
  components: {
    RForumTable,
  },
  methods: {
    async markRead() {
      try {
        await api.posts.markRead();
        this.load();
        api.alerts.success('Marked all recent posts as read.');
      } catch {
        api.alerts.error('Unable to mark all recent posts as read.');
      }
    },
    async load() {
      const results = await api.posts.recent({}, true);
      this.count = results.post_count;
    },
  },
  created() {
    this.load();
  },
};
</script>

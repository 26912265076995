<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-journal {
    .add {
      margin: 0px 20px;
    }
  }
</style>


<template>
  <div class="r-journal grid gap-5 grid-cols-8">
    <div class="col-span-5">
      <r-journal-form v-model="selected" @create="$refs.mlist.reset()"/>
    </div>
    <div class="col-span-3 v-box">
      <b-button variant="primary" class="add" @click="selected = null">
        New Journal Entry
      </b-button>
      <r-model-list ref="mlist" :model="model" :load="load" :limit="limit" title="title" @select="select"/>
    </div>
  </div>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RJournalForm from '@/components/forms/r-journal-form';

export default {
  name: 'r-journal',
  components: {
    RJournalForm,
  },
  data() {
    return {
      selected: null,
      limit: 10,
    };
  },
  computed: {
    model: () => models.Journal,
  },
  methods: {
    select(value) {
      this.selected = value;
    },
    load(page) {
      api.journals.load({
        limit: this.limit,
        offset: this.limit * (page || 0),
      });
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-border-card {
    &.outer-box {
      border: 1px solid $color-gray-3;
      margin-top: 30px;
      background: $color-gray-2;
      border-radius: 5px;
    }
    .inner-box {
      border: 1px solid $color-gray-3;
      padding: 20px;
      margin: 20px;
      background: $color-white;
    }
  }
</style>

<template>
  <div class="r-border-card outer-box">
    <div class="inner-box">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'r-border-card',
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  /deep/ .modal-body {
    padding-top: 0px;
  }
  .r-clean-modal {
    padding: 10px;
  }
</style>


<template>
  <b-modal ref="modal" :busy="true" v-bind="$attrs">
    <template #modal-title>
      <slot name="title"></slot>
    </template>
    <div class="r-clean-modal">
      <slot></slot>
    </div>
    <template #modal-footer>
      <div class="footer"></div>
    </template>
  </b-modal>
</template>


<script>
import modal from '@/extensions/modal';

export default {
  name: 'r-clean-modal',
  methods: { ...modal.methods },
};
</script>

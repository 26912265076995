<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-forum-breadcrumb {
    padding-top: 0px;
    padding-bottom: 5px;
    font-size: 14px;
  }
</style>


<template>
  <div class="r-forum-breadcrumb">
    <span class="pr-2">
      <router-link :to="{ name: 'forums', params: {} }">Forum</router-link>
    </span>
    <span v-if="forum">&raquo;
      <router-link class="pl-1" :to="{ name: 'forum', params: {id: forum.id} }">
        {{forum.name}}
      </router-link>
    </span>
    <span v-if="topic">&raquo;
      <router-link class="pl-1" :to="{ name: 'topic', params: {id: topic.id} }">
        {{topic.name}}
      </router-link>
    </span>
  </div>
</template>


<script>
export default {
  name: 'r-forum-breadcrumb',
  props: ['forum', 'topic'],
  components: {
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-practice {
    .add {
      margin: 0px 20px;
    }
  }
</style>


<template>
  <div class="r-practice grid gap-5 grid-cols-8">
    <div class="col-span-5">
      <r-frc-form v-model="selected"  @create="$refs.mlist.reset()"/>
    </div>
    <div class="col-span-3 v-box">
      <b-button variant="primary" class="add" @click="selected = null">
        New FRC Practice
      </b-button>
      <r-model-list ref="mlist" :model="model" :load="load" :limit="limit" title="title" @select="select"/>
    </div>
  </div>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RFrcForm from '@/components/forms/r-frc-form';

export default {
  name: 'r-practice',
  components: {
    RFrcForm,
  },
  data() {
    return {
      selected: null,
      limit: 28,
    };
  },
  computed: {
    model: () => models.Frc,
  },
  methods: {
    select(value) {
      this.selected = value;
    },
    load(page) {
      api.frcs.load({
        limit: this.limit,
        offset: this.limit * (page || 0),
      });
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-program-layout>
    <div class="max-w-screen-sm mx-auto">
      <div class="flex">
        <r-section-button
          class="flex-grow-0"
          :to="(frcCompleted) ? 'setback' : ''"
          :bar="false"
          :src="require('@/assets/images/reclaim/crisis_and_setbacks/setback_thumb.png')"/>
        <div class="flex-grow"></div>
        <r-section-button
          class="flex-grow-0"
          :bar="false"
          :to="(frcCompleted) ? 'crisis' : ''"
          :src="require('@/assets/images/reclaim/crisis_and_setbacks/crisis2_thumb.png')"/>
      </div>
      <div class="mt-6 font-semibold">
        The Setbacks and Crisis page are most effective after you have
        completed the Face it-Replace it-Connect Training – They will be
        unlocked for your use after the final section of that module.
      </div>
    </div>

  </r-program-layout>
</template>


<script>
import api from '@/api';
import store from '@/store';
import RSectionButton from '../trainings/r-section-button';

export default {
  name: 'crisis-and-setbacks',
  components: {
    RSectionButton,
  },
  computed: {
    program: store.getter('program'),
    frcCompleted() {
      return this.program && this.program.student && this.program.student.levels[2].progress > 99;
    },
  },
  async created() {
    api.program.load();
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-connection-details {
    .waiting, .unreached {
      color: $color-red-bright;
    }
    .details {
      text-align: right;
    }
    &.unread {
      background: $color-blue-dull !important;
    }
  }
</style>

<template>
  <div class="r-connection-details">
    <div class="completion">Progress: {{connection.progress | percentage}}</div>
    <div v-if="connection.unreached" class="unreached">Never been contacted.</div>
    <div v-if="connection.waiting" class="waiting">Waiting: {{connection.waitingSince | relative}}</div>
    <div class="signup">Signup: {{connection.signup | relative}}</div>
    <div class="login">Login: {{connection.login | relative}}</div>
    <div class="notes"><a href="#" @click.prevent="$refs.note.show()">Notes</a></div>

    <!-- note modal -->
    <r-clean-modal ref="note" size="lg">
      <template slot="title">Note for {{connection.display_name}}</template>
      <r-note-form @success="$refs.note.hide()" :connection="connection"/>
    </r-clean-modal>
  </div>
</template>

<script>
import RNoteForm from '@/components/forms/r-note-form';

export default {
  name: 'r-connection-details',
  props: ['connection'],
  components: {
    RNoteForm,
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .photos {
    .photo-button {
      cursor: pointer;
      position: relative;
      height: 200px;
      width: 200px;
      border: 1px solid $color-gray-3;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &:hover {
          opacity: 75%;
        }
      }
      &.add {
        font-size: 20px;
        color: $color-gray-6;
        border: 2px dashed $color-gray-5;
        .plus {
          font-size: 50px;
          font-weight: bold;
        }
        &:hover {
          opacity: 75%;
        }
      }
      &:hover {
        .remove {
          display: flex;
        }
      }
      .remove {
        display: none;
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }
</style>


<template>
  <r-program-layout class="photos">
    <div class="grid md:grid-cols-4 gap-4">

      <!-- add photo button -->
      <div class="photo-button add c-box" @click="$refs.add.show()">
        <div class="plus">+</div>
        <div >Add Photo</div>
      </div>

      <!-- photos -->
      <div class="photo-button" v-for="photo in photos" :key="photo.id" @click="expand(photo)" >

        <!-- thumbnail -->
        <img :src="photo.image">

        <!-- delete photo button-->
        <div class="remove c-box">
          <b-button variant="danger" size="sm" class="mb-2" @click.stop="remove(photo)">
            <b-icon icon="trash" aria-hidden="true"></b-icon>
          </b-button>
        </div>

      </div>
    </div>

    <!-- expand modal -->
    <r-clean-modal ref="expand" size="lg">
      <div class="c-box">
        <img v-if="expanded" :src="expanded.image">
      </div>
    </r-clean-modal>

    <!-- delete modal -->
    <r-confirm-modal ref="remove" @confirm="confirm">
      Are you sure you want to delete this photo?
    </r-confirm-modal>

    <!-- add photo modal -->
    <r-clean-modal ref="add" size="lg">
      <template slot="title">Add Photo</template>
      <r-photo-form @success="$refs.add.hide()"/>
    </r-clean-modal>
  </r-program-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RPhotoForm from '@/components/forms/r-photo-form';

export default {
  name: 'photos',
  components: {
    RPhotoForm,
  },
  data() {
    return {
      selected: null,
      expanded: null,
    };
  },
  computed: {
    photos: () => models.Photo.query().orderBy('created_at', 'asc').get(),
  },
  methods: {
    remove(photo) {
      this.selected = photo;
      this.$refs.remove.show();
    },
    expand(photo) {
      this.expanded = photo;
      this.$refs.expand.show();
    },
    confirm() {
      api.photos.remove(this.selected.id);
    },
  },
  created() {
    api.photos.load();
  },
};
</script>

import { render, staticRenderFns } from "./r-loading.vue?vue&type=template&id=4231cf40&scoped=true&"
import script from "./r-loading.vue?vue&type=script&lang=js&"
export * from "./r-loading.vue?vue&type=script&lang=js&"
import style0 from "./r-loading.vue?vue&type=style&index=0&id=4231cf40&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4231cf40",
  null
  
)

export default component.exports
import { Model } from '@vuex-orm/core';

export default class AdminTraining extends Model {
  static entity = 'admin-trainings'

  static fields() {
    return {
      id: this.attr(null),
      segment: this.attr(''),
      segment_name: this.attr(''),
      level_number: this.attr(''),
      level_name: this.attr(''),
      course: this.attr(''),
      video: this.attr(''),
      audio: this.attr(''),
      body: this.attr(''),
      active: this.attr(false),
    };
  }
}

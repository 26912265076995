<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  #wrapper {
    width: 800px;
    background-color: rgba(255, 255, 255, 0.80);
    border-radius: 5px;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    margin: 40px auto;
    margin-top: 0px;
    padding: 20px;
    font-weight: 400;
    font-size: 14px;
    font-family: helvetica, arial, sans-serif;
  }
  footer {
    font-family: helvetica, arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .control-label {
    text-align: right;
    font-weight: bold;
    padding: 7px;
    padding-bottom: 0;
    @apply w-1/4 px-3;
  }

  .btn-submit {
    @apply mr-2 text-lg;
    background-color: #428ab4;
    border-color: #428ab4;
    border-radius: 6px;
    padding: 8px 16px;
  }
  #background {
    background-image: url('~@/assets/images/reclaim/chain.jpg');
    background-size: cover;
    background-position:top center;
    background-repeat: no-repeat;
    font-family: helvetica, arial, sans-serif;
    height: 100%;
    padding-top: 40px;
  }
</style>

<template>
  <div id="background">

    <div id="wrapper">
      <a href="http://candeobehaviorchange.com/healthy-sexuality/"><img src="@/assets/images/reclaim/reclaim_logo.png" style="display: block; margin: 5px auto 30px auto;"></a>
      <p style="font-size: 135%; text-align: center; color: gray;">
        Sign up to get started!
      </p>
      <hr style="margin-bottom:20px;margin-top:20px;border-top:1px solid #eee;">

      <b-form @submit.prevent="submit" style="padding: 10px;">
        <!-- username -->
        <b-form-group>
          <div class="flex">
            <label class="control-label">
              Username
            </label>
            <div class="w-1/2 px-3" >
              <b-form-input v-model="form.username" :state="states.username"
                            type="text" style="font-size:14px;" />
              <b-form-invalid-feedback v-if="errors.username === 'expected_unique'">
                The username you have selected is not availiable.
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-else>
                Please enter a valid username.
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-form-group>

        <!-- email -->
        <b-form-group>
          <div class="flex">
            <label class="control-label">
              Email
            </label>
            <div class="w-1/2 px-3" >
              <b-form-input v-model="form.email" type="text" :state="states.email" style="font-size:14px;" />
              <b-form-invalid-feedback v-if="errors.email === 'expected_unique'">
                The email address you have entered is not availiable.
              </b-form-invalid-feedback>
              <b-form-invalid-feedback v-else>
                Please enter a valid email address.
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-form-group>

        <!-- password -->
        <b-form-group>
          <div class="flex">
            <label class="control-label">
              Password
            </label>
            <div class="w-1/2 px-3">
              <b-form-input v-model="form.password" :state="states.password"
                            type="password" />
              <b-form-invalid-feedback>
                Please enter a password with at least 8 characters including numbers,
                lowercase letters and uppercase letters.
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-form-group>

        <!-- confirm -->
        <b-form-group>
          <div class="flex">
            <label class="control-label">
              Confirm
            </label>
            <div class="w-1/2 px-3">
              <b-form-input v-model="form.confirm" :state="states.confirm"
                            type="password" />
              <b-form-invalid-feedback>
                The provided passwords do not match.
              </b-form-invalid-feedback>
            </div>
          </div>
        </b-form-group>

        <!-- submit -->
        <div class="flex justify-center mb-3">
          <b-button type="submit" variant="primary" class="btn-submit">Get Your Life Back</b-button>
        </div>
      </b-form>


    </div>

    <footer style="font-size: 70%; text-align: center; color: gray;">
      Copyright 2021 Candeo. All rights reserved.
    </footer>

  </div>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';
import confirm from '@/mixins/confirm';

export default {
  name: 'signup',
  mixins: [form, confirm],
  methods: {
    async submitted() {
      const referrer = new URLSearchParams(window.location.search).get('referrer');
      await api.auth.signup(this.form.username, this.form.email, this.form.password, referrer);
      this.$router.push({ name: 'subscribe' }).catch(() => {});
    },
  },
};
</script>

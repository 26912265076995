<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-note-form {
    .error {
      border: 1px solid #d33c34;
      border-radius: 4px;
    }
  }
</style>


<template>
  <b-form class="r-note-form" @submit.prevent="submit">
    <!-- body -->
    <b-form-group>
      <b-form-textarea rows="12" max-rows="12" v-model="form.body" :state="states.body"
                       placeholder="Note"/>
      <b-form-invalid-feedback>
        Please enter a note.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <div class="h-box">
      <div class="flex-spacer"></div>
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
    </div>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';


export default {
  name: 'r-note-form',
  mixins: [form],
  props: ['connection'],
  methods: {
    async reset() {
      try {
        this.form.body = (await api.notes.get(this.connection.id)).body;
      } catch (error) {
        if (error.status === 404) {
          this.form.body = '';
        } else {
          this.$emit('success');
          api.alerts.error('Failed to load note.');
        }
      }
      this.errors = {};
    },
    async submitted() {
      this.form.body = (await api.notes.set(this.connection.id, this.form.body)).body;
      api.alerts.success('Note has been saved.');
      this.$emit('success');
    },
    async rejected() {
      api.alerts.error('Unable to save note.');
    },
  },
};
</script>

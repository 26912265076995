<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-home {

  }
  .r-program-layout {
    height: 100%;
    background: $color-gray-1;
    overflow-y: scroll;
    .copyright {
      font-size: 14px;
      color: $color-gray-6;
      margin: 10px auto 10px auto;
    }
  }
  .nav-placeholder {
    background-color: #347fa8;
    height: 42px;
    flex-shrink: 0;
  }
  .welcome {
    font-size: 32px;
    padding-bottom: 10px;
    font-weight: 600;
    line-height: 22px;
  }
  .sub-wrapper {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.75);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    height: auto;
    padding: 0 10px;
    font-size: 14px;
    h3 {
      font-size: 24px;
      font-family: helvetica, arial, sans-serif;
      padding-top: 10px;
      margin-bottom: 0px;
      font-weight: 500;
      line-height: 1;
    }
  }
  .price {
    color: #428AB4;
    font-size: 250%;
    font-weight: 900;
  }
  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .control-label {
    text-align: right;
    font-weight: bold;
    padding: 7px;
    padding-bottom: 0;
    @apply w-1/4 px-3;
  }
  .fine-print {
    font-family: "proxima-nova", "Proxima Nova", "Helvetica Neue","Helvetica",Arial,sans-serif;
  }
</style>

<template>
  <div class="r-program-layout v-box align-center">
    <r-alerts/>
    <r-banner class="flex-limit"/>
    <div class="nav-placeholder"></div>
    <div class="p-2 mt-6 flex-grow">
      <h2 class="text-center welcome">Welcome! Please select a subscription below.</h2>

      <div>
        <b-form @submit.prevent="submit" class="r-subscription-form">

          <!-- subscription -->
          <b-form-group>
            <div class="flex">
              <div class="w-3/12"></div>

              <div class="w-4/12 px-3">
                <div class="sub-wrapper">
                  <div class="flex">
                    <div v-for="product in products" :key="product.value" class="w-1/2 text-center px-3">
                      <h3>{{ product.header }}</h3>
                      <label>
                        <span class="price">{{ product.price }}</span>
                        <span class="price-details">{{ product.cycle }}<br>{{ product.length }}</span>
                      </label>
                      <div><b-form-radio v-model="form.product" :value="product.value"></b-form-radio></div>
                    </div>
                  </div>
                  <div class="text-center">
                    <hr class="my-3">
                    <h3>After 6 months</h3>
                    <span class="price">$49</span>
                    <span class="price-details">/mo<br>month to month</span>
                    <div class="h-3"></div>
                  </div>
                </div>

                <b-form-invalid-feedback>
                  Please select a subscription.
                </b-form-invalid-feedback>
              </div>

              <div class="w-2/12 px-3">
                <img class="img-responsive" src="@/assets/images/reclaim/guarantee.png">
              </div>

            </div>

          </b-form-group>

          <!-- billing information -->

          <!-- first name -->
          <b-form-group>
            <div class="flex">
              <label class="control-label">
                First Name
              </label>
              <div class="w-1/2 px-3" >
                <b-form-input v-model="form.first" type="text" :state="states.first"/>
                <b-form-invalid-feedback>
                  Please enter your first name.
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>


          <!-- last name -->
          <b-form-group>
            <div class="flex">
              <label class="control-label">
                Last Name
              </label>
              <div class="w-1/2 px-3">
                <b-form-input v-model="form.last" type="text" :state="states.last"/>
                <b-form-invalid-feedback>
                  Please enter your last name.
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>

          <!-- card number -->
          <b-form-group>
            <div class="flex">
              <label class="control-label">
                Card Number
              </label>
              <div class="w-1/2 px-3" >
                <b-form-input v-model="form.number" type="text" :state="states.number"/>
                <b-form-invalid-feedback>
                  Please enter a valid credit card number.
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>


          <!-- month -->
          <b-form-group>
            <div class="flex">
              <label class="control-label">
                Month
              </label>
              <div class="w-1/2 px-3" >
                <div class="grid grid-cols-2 gap-2">
                  <div>
                    <b-form-select v-model="form.month" :options="months" :state="states.month"/>
                    <b-form-invalid-feedback>
                      Please select a valid expiration month.
                    </b-form-invalid-feedback>
                  </div>
                  <div>
                    <b-form-select v-model="form.year" :options="years" :state="states.year"/>
                    <b-form-invalid-feedback>
                      Please select a valid expiration year.
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </div>
            </div>
          </b-form-group>

          <!-- cvv number -->
          <b-form-group>
            <div class="flex">
              <label class="control-label">
                <div>CVV Number</div>
                <div><a class="text-xs cvv-modal-btn" href="" @click.prevent="$refs.cvv.show()">Help me find it!</a>
                </div>
              </label>

              <div class="w-1/2 px-3" >
                <b-form-input v-model="form.cvv" type="text" :state="states.cvv"/>
                <b-form-invalid-feedback>
                  Please select a valid cvv number.
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>

          <!-- coupon -->
          <b-form-group>
            <div class="flex">
              <label class="control-label">
                Coupon Code
              </label>
              <div class="w-1/2 px-3" >
                <b-form-input v-model="form.coupon" type="text" :state="states.coupon"/>
                <b-form-invalid-feedback>
                  Please enter a valid coupon code.
                </b-form-invalid-feedback>
              </div>
            </div>
          </b-form-group>

          <b-form-group>
            <b-form-text v-show="errors['_']" style="font-size: 14px" text-variant="danger">
              There was a problem charging your credit card.  Please confirm your card information
              and try again.
            </b-form-text>
          </b-form-group>

          <!-- submit -->
          <b-form-group>
            <div class="flex">
              <div class="control-label"></div>
              <div class="w-1/2 px-3 text-center">
                <b-button type="submit" variant="primary" class="mr-2 mb-3">Subscribe and Pay
                  ${{ currentProduct.cost }}
                </b-button>
                <p class="fine-print" style="font-size:11px; text-align:center;">
                  <em>Your subscription will show up as "You-Turn LLC" on your bank or credit card statement.</em>
                  <br>
                  <em>
                    If you do not cancel your account within the first 14 days,
                    you are agreeing to our standard 6 month commitment. For
                    questions or to cancel, please 
                    <a href="mailto:support@candeocan.com?Subject=Payment Page Help" target="_top">contact us</a>. 
                  </em>
                  <br>
                  By submitting this form you agree to the <a href="javascript:void(0)" onclick="window.open('http://candeotraining.com/agreement.html','linkname','height=500, width=600,scrollbars=yes')" rel="prettyPhoto">Terms of Service</a> and <a href="javascript:void(0)" onclick="window.open('http://candeotraining.com/privacy-policy.html','linkname','height=500, width=600,scrollbars=no')" rel="prettyPhoto">Privacy Policy</a>
                </p>
              </div>
            </div>

          </b-form-group>


        </b-form>
      </div>

    </div>
    <div class="copyright content">
      Copyright © {{new Date().getFullYear()}} Candeo &amp; Elizabeth Ministry International. All Rights Reserved.
    </div>
    <r-cvv-modal ref="cvv"/>
  </div>
</template>

<script>
import api from '@/api';
import store from '@/store';
import models from '@/models';
import RBanner from '@/components/layouts/program-layout/r-banner';
import { capitalize, range } from 'lodash';
import form from '@/mixins/form';
import RCvvModal from './subscribe/r-cvv-modal';

export default {
  name: 'subscribe',
  mixins: [form],
  components: {
    RBanner,
    RCvvModal,
  },
  computed: {
    profile: store.getter('profile'),
    products() {
      return models.Product.all().map((p) => {
        let cost;
        if (Math.floor(p.cost * 100) % 100 === 0) {
          cost = Math.floor(p.cost);
        } else {
          cost = p.cost;
        }
        let header = '';
        let cycle = '';
        let length = '';
        if (!p.recurring || p.cycle === 'LIFETIME') {
          header = 'Up Front';
          length = 'one time';
        } else if (p.cycle === 'MONTHLY') {
          header = 'Monthly';
          cycle = '/mo';
          length = 'for 6 months';
        } else if (p.cycle === 'SEMIANNUAL') {
          header = 'Semiannually';
          cycle = '/6 months';
        } else if (p.cycle === 'ANNUAL') {
          header = 'Annually';
          cycle = '/year';
        }
        return {
          value: p.id,
          text: `$${p.cost} ${capitalize(p.cycle)} ${p.name}`,
          price: `$${cost}`,
          header,
          cycle,
          length,
        };
      });
    },
    currentProduct() {
      return models.Product.all().filter((p) => (
        p.id === this.form.product
      ))[0] || {};
    },
    months() {
      return range(1, 13);
    },
    years() {
      const year = new Date().getFullYear();
      return range(year, year + 51);
    },
  },
  created() {
    api.statuses.load();
  },
  methods: {
    async reset() {
      this.form = {
        product: this.products[0].value,
      };
      this.errors = {};
    },
    async submitted() {
      await api.billing.subscribe(this.form);
      this.$emit('success');
      await api.payments.load();
      this.$router.push({ name: 'activities' }).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-section-button {
    .box {
      position: relative;
      &.blue {
        background: linear-gradient(180deg, rgba(193,205,218,1) 0%, rgba(166,180,191,1) 50%, rgba(205,218,229,1) 100%);
      }
      &.grey {
        background: linear-gradient(180deg, hsl(211, 0%, 81%) 0%, hsl(206, 0%, 70%) 50%, hsl(208, 0%, 85%) 100%);
      }
      &.blue, &.grey{
        border: 1px solid $color-gray-5;
        padding: 5px;
        border-radius: 20px;
        box-shadow: 0px 0px 5px 2px $color-gray-4;
      }
      .arrow-right {
        z-index: 99;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(0%, -50%);
      }
      .arrow-down {
        z-index: 99;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%, -25%);
      }
    }
  }
</style>


<template>
  <div class="r-section-button c-box">
    <div class="box" :class="theme">
      <router-link :to="to">
        <img :src="src">
      </router-link>
      <b-progress v-if="bar" :value="progress" :max="100" height="10px" class="mx-2 my-1"/>
      <img v-show="arrows.includes('right')" class="arrow-right" src="@/assets/images/reclaim/training/arrow_right.png">
      <img v-show="arrows.includes('down')" class="arrow-down" src="@/assets/images/reclaim/training/arrow_down.png">
    </div>
  </div>
</template>


<script>
import store from '@/store';

export default {
  name: 'r-section-button',
  props: {
    to: null,
    src: null,
    bar: {
      default: true,
    },
    theme: {
      default: null,
    },
    arrows: {
      default: () => [],
    },
  },
  computed: {
    progress() {
      try {
        return this.program[this.to.params.course].levels[this.to.params.level].progress;
      } catch {
        return 0;
      }
    },
    program: store.getter('program'),
  },
};
</script>

import { Model } from '@vuex-orm/core';

export default class Photo extends Model {
  static entity = 'photos'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(''),
      image: this.attr(''),
    };
  }
}

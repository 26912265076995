const settings = {};

if (process.env.VUE_APP_BUILD === 'development') {
  // development settings
  settings.env = 'development';
  settings.api = 'http://localhost:8000';
  settings.domains = {
    api: 'localhost:8000',
    media: [
      'reclaim-dev-bucket.s3.amazonaws.com',
      'reclaim-dev-bucket.s3.us-west-2.amazonaws.com',
      'reclaim-public.s3.amazonaws.com',
      'reclaim-public.s3.us-west-2.amazonaws.com',
      'rhs-trainingroom.s3.amazonaws.com',
      'rhs-trainingroom.s3.us-east-1.amazonaws.com',
    ],
  };
} else if (process.env.VUE_APP_BUILD === 'staging') {
  // staging settings
  settings.env = 'staging';
  settings.api = 'https://reclaim-api-staging.you-turn.org';
  settings.domains = {
    api: 'reclaim-api-staging.you-turn.org',
    media: [
      'reclaim-dev-bucket.s3.amazonaws.com',
      'reclaim-dev-bucket.s3.us-west-2.amazonaws.com',
      'reclaim-public.s3.amazonaws.com',
      'reclaim-public.s3.us-west-2.amazonaws.com',
      'rhs-trainingroom.s3.amazonaws.com',
      'rhs-trainingroom.s3.us-east-1.amazonaws.com',
    ],
  };
} else {
  // production settings
  settings.env = 'production';
  settings.api = 'https://api.you-turn.org';
  settings.domains = {
    api: 'api.you-turn.org',
    media: [
      'reclaim-bucket.s3.amazonaws.com',
      'reclaim-bucket.s3.us-west-2.amazonaws.com',
      'reclaim-public.s3.amazonaws.com',
      'reclaim-public.s3.us-west-2.amazonaws.com',
      'rhs-trainingroom.s3.amazonaws.com',
      'rhs-trainingroom.s3.us-east-1.amazonaws.com',
    ],
  };
}

module.exports = settings;

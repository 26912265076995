<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-admin-card {
    border: 1px solid $color-gray-4;
    border-radius: 5px;
    .title {
      background: $color-gray-2;
      padding: 10px 15px;
      border-bottom: 1px solid $color-gray-4;
    }
    .main {
      padding: 15px;
    }
  }
</style>

<template>
  <div class="r-admin-card">
    <div class="title">{{title}}</div>
    <div class="main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'r-admin-card',
  props: ['title'],
};
</script>

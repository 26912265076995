<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-post-form {
    padding: 20px;
  }
</style>


<template>
  <b-form class="r-post-form" @submit.prevent="submit">
    <!-- subject -->
    <b-form-group label="Topic" v-if="forum">
      <b-form-input v-model="form.name" :state="states.name" type="text"/>
      <b-form-invalid-feedback>
        Please enter a subject for this new topic.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- body -->
    <b-form-group label="Post">
      <r-editor-input ref="editor" v-model="form.body" :state="states.body"/>
      <b-form-invalid-feedback :state="states.body">
        <span v-if="errors.body === 'duplicate_post'">This looks like a duplicate post.</span>
        <span v-else>Please enter a post for this new topic.</span>
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <b-button type="submit" variant="primary" class="mt-3">Submit</b-button>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';


export default {
  name: 'r-post-form',
  mixins: [form],
  props: [
    'forum',
    'topic',
    'post',
  ],
  methods: {
    async submitted() {
      let response = null;
      if (this.post && this.topic) {
        // edit topic
      } else if (this.topic) {
        // create post (reply)
        response = await api.posts.create({ ...this.form, topic: this.topic });
        api.alerts.success('Your new post has been created.');
      } else if (this.post) {
        // edit post
        response = await api.posts.edit(this.post.id, this.form);
        api.alerts.success('Your post has been updated.');
      } else {
        // create topic
        response = await api.topics.create({ ...this.form, forum: this.forum });
        api.alerts.success('Your new topic has been created.');
        this.$router.push({ name: 'topic', params: { id: response.id } });
      }
      this.reset();
      this.$emit('success', response);
    },
    async rejected() {
      api.alerts.error('Unable to save post or topic.');
    },
    async reset() {
      this.form = {};
      this.errors = {};
      if (this.post) {
        this.form.body = this.post.body;
      }
    },
    quote(author, body) {
      this.$refs.editor.insertQuote(author, body);
    },
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-audio {

  }
</style>


<template>
  <r-video v-bind="$attrs" :audio="true"/>
</template>


<script>
export default {
  name: 'r-audio',
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  /deep/ .modal-body {
    padding: 0px;
  }

  .r-clean-modal {
    min-height: 400px;
    background-color: black;
  }
</style>


<template>
  <b-modal ref="modal" :busy="true" v-bind="$attrs" hide-footer>
    <div class="r-clean-modal flex justify-center flex-column">
      <r-video :src="video.url" :poster="video.thumbnail"></r-video>
    </div>
  </b-modal>
</template>


<script>
import modal from '@/extensions/modal';

export default {
  name: 'r-crisis-modal',
  methods: {
    ...modal.methods,
    select(video) {
      this.video = video;
      this.show();
    },
  },
  data() {
    return {
      video: {},
    };
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .error {
    text-align: center;
  }
</style>

<template>
  <r-focus-layout class="error">
    <h4>There was an unexpected error!</h4>
    <p>
      Please try to reloading the page.
    </p>
  </r-focus-layout>
</template>

<script>
export default {
  name: 'error',
  components: {
  },
};
</script>

import store from '@/store';
import rest from './rest';


async function load() {
  const program = await rest.get('/program');
  store.set('program', program);
  return program;
}

async function reset() {
  await rest.delete('/program');
  return this.load();
}

async function get(course, level, segment) {
  return rest.get(`/program/${course}/${level}/${segment}`);
}

async function mark(course, level, segment) {
  return rest.put(`/program/${course}/${level}/${segment}`);
}


export default {
  load,
  reset,
  get,
  mark,
};

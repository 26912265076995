<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="cash"/>Payments</template>
    <r-admin-card title="Payment Information">
      <r-payment-form v-if="loaded" :onSubmit="submitted" :onSettle="settle" :onRefund="refund" :payment="payment" />
    </r-admin-card>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RPaymentForm from '@/components/forms/r-payment-form';

export default {
  name: 'a-payment',
  components: {
    RPaymentForm,
  },
  methods: {
    async load() {
      this.newPayment = this.$route.params.id === 'new';
      if (!this.newPayment) {
        this.payment = models.Payment.find(this.$route.params.id);
        if (!this.payment) {
          this.payment = await api.adminPayments.get(this.$route.params.id);
        }
      }
      if (models.AdminProduct.query().all().length === 0) {
        await api.adminProducts.load();
      }
      if (models.AdminCoupon.query().all().length === 0) {
        await api.adminCoupons.load();
      }
      this.loaded = true;
    },
    async submitted(form) {
      if (this.newPayment) {
        this.payment = await api.adminPayments.create(form);
        this.$router.push({ name: 'admin-payments' });
        api.alerts.success('Payment created.');
      } else {
        this.payment = await api.adminPayments.update(form.id, form);
        api.alerts.success('Payment updated.');
      }
    },
    async settle() {
      this.payment = await api.adminPayments.settle(this.payment.id);
    },
    async refund() {
      this.payment = await api.adminPayments.refund(this.payment.id);
    },
  },
  data() {
    return {
      payment: {},
      newPayment: false,
      loaded: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

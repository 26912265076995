import rest from '@/api/rest';
import Topic from '@/models/topic';


async function load(id, params) {
  const objects = await rest.get(`/forums/${id}`, params);
  Topic.create({ data: Object.values(objects.topics) });
  return objects;
}

async function create(fields) {
  return rest.post('/topics', fields);
}

export default {
  load, create,
};

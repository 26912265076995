import { render, staticRenderFns } from "./r-aspect-ratio-box.vue?vue&type=template&id=5614e579&scoped=true&"
import script from "./r-aspect-ratio-box.vue?vue&type=script&lang=js&"
export * from "./r-aspect-ratio-box.vue?vue&type=script&lang=js&"
import style0 from "./r-aspect-ratio-box.vue?vue&type=style&index=0&id=5614e579&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5614e579",
  null
  
)

export default component.exports
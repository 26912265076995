import { Model } from '@vuex-orm/core';

export default class User extends Model {
  static entity = 'admin_users'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      email: this.attr(''),
      name: this.attr(''),
      username: this.attr(''),
      display_name: this.attr(''),
      last_login: this.attr(''),
      new_signup: this.attr(null),
      staff: this.attr(null),
      billing: this.attr(null),
      payments: this.attr([]),
      student: this.attr(null),
      products: this.attr([]),
      current_products: this.attr([]),
      current_courses: this.attr([]),
      selected_products: this.attr([]),
      selected_connections: this.attr([]),
    };
  }
}

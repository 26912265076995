<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-program-layout {
    height: 100%;
    background: $color-gray-1;
    overflow-y: scroll;
    .content {
      width: $content-width;
    }
    .page {
      flex-grow: 1;
      margin: 25px auto 0px auto;
      padding: 40px;
      background: white;
      box-shadow: 0 10px 30px $color-gray-3;
    }
    .copyright {
      font-size: 14px;
      color: $color-gray-6;
      margin: 10px auto 10px auto;
    }
  }
</style>


<template>
  <div class="r-program-layout v-box align-center">
    <r-alerts/>
    <r-banner class="flex-limit"/>
    <r-navigation class="flex-limit"/>
    <div class="page content">
      <slot>
      </slot>
    </div>
    <div class="copyright content">
      Copyright © {{new Date().getFullYear()}} Candeo &amp; Elizabeth Ministry International. All Rights Reserved.
    </div>
  </div>
</template>


<script>
import RBanner from './r-banner';
import RNavigation from './r-navigation';


export default {
  name: 'r-program-layout',
  components: {
    RBanner,
    RNavigation,
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-basic-layout {
    height: 100%;
    overflow: auto;

    .content {
      margin: auto;
      max-width: 1200px;
    }
  }
</style>


<template>
  <div class="r-basic-layout">
    <r-alerts/>
    <div class="content">
      <slot>
      </slot>
    </div>
  </div>
</template>


<script>
export default {
  name: 'r-basic-layout',
};
</script>

import store from '@/store';
import rest from './rest';


async function login(username, password) {
  await rest.post('/login', { username, password });
  store.reset();
  store.set('authenticated', true);
}

async function logout() {
  await rest.post('/logout');
  store.reset();
  store.set('authenticated', false);
}

async function authenticated() {
  const response = await rest.get('/authenticated');
  store.set('authenticated', response.authenticated);
  return response.authenticated;
}

async function signup(username, email, password, referrer) {
  const opts = { username, email, password };
  if (referrer) {
    opts.referrer = referrer;
  }
  await rest.post('/signup', opts);
}

export default {
  login, logout, authenticated, signup,
};

import auth from './auth';
import connections from './connections';
import load from './load';
import password from './password';
import alerts from './alerts';
import articles from './articles';
import billing from './billing';
import forums from './forums';
import topics from './topics';
import frcs from './frcs';
import indicators from './indicators';
import journals from './journals';
import messages from './messages';
import payments from './payments';
import posts from './posts';
import photos from './photos';
import profile from './profile';
import products from './products';
import notes from './notes';
import adminProducts from './admin-products';
import adminReferrers from './admin-referrers';
import adminBans from './admin-bans';
import adminTrainings from './admin-trainings';
import adminProfiles from './admin-profiles';
import adminPayments from './admin-payments';
import adminForums from './admin-forums';
import adminReports from './admin-reports';
import courses from './courses';
import program from './program';
import statuses from './statuses';
import ApiError from './api-error';
import admin from './admin';
import adminCoupons from './admin-coupons';
import adminTopics from './admin-topics';


export default {
  auth,
  connections,
  load,
  alerts,
  articles,
  billing,
  forums,
  topics,
  frcs,
  indicators,
  journals,
  messages,
  password,
  payments,
  photos,
  posts,
  profile,
  adminProducts,
  adminReferrers,
  adminBans,
  adminTrainings,
  adminProfiles,
  adminPayments,
  adminForums,
  products,
  notes,
  courses,
  program,
  statuses,
  ApiError,
  admin,
  adminReports,
  adminCoupons,
  adminTopics,
};

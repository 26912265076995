<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .student-trainings {
    .box {
      background: red;
    }
  }
</style>


<template>
  <r-program-layout class="student-trainings">
    <div class="grid grid-cols-10 gap-4">

      <!-- section 0 -->
      <r-section-box class="col-start-1 col-span-2" :arrows="['down']">
        <r-section-button
          :to="{ name: 'training', params: {course: 'student', level: 0, segment: 1} }"
          :src="require('@/assets/images/reclaim/training/intro_thumb.png')"/>
      </r-section-box>


      <r-section-box class="col-start-7 col-span-4 h-box" theme="grey">

        <!-- crisis and setbacks -->
        <r-section-button :bar="false"
                          :to="{ name: 'crisis-and-setbacks'}"
                          :src="require('@/assets/images/reclaim/training/cat_sb_thumb.png')"/>

        <!-- continue or startover -->
        <div class="v-box justify-center pr-1">
          <b-button variant="primary" class="mb-2 ml-2" @click.prevent="leftOff">
            Where I Left Off
          </b-button>
          <b-button variant="light" class="mt-2 ml-2" @click.prevent="startOver">
            Start Over
          </b-button>
        </div>
      </r-section-box>

      <r-section-box class="col-span-10" :arrows="['down']">
        <div class="grid grid-cols-10 gap-4">

          <!-- section 1 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'student', level: 1, segment: 1} }" :arrows="['right']"
              :src="require('@/assets/images/reclaim/training/cat_bsc_thumb.png')"/>
          </div>

          <!-- section 2 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'student', level: 2, segment: 1} }" :arrows="['right']"
              :src="require('@/assets/images/reclaim/training/cat_frc_thumb.png')"/>
          </div>

          <!-- section 3 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'student', level: 3, segment: 1} }" :arrows="['right']"
              :src="require('@/assets/images/reclaim/training/cat_eto_thumb.png')"/>
          </div>

          <!-- section 4 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'student', level: 4, segment: 1} }" :arrows="['right']"
              :src="require('@/assets/images/reclaim/training/cat_hs_thumb.png')"/>
          </div>

          <!-- section 5 -->
          <div class="col-span-2">
            <r-section-button
              :to="{ name: 'training', params: {course: 'student', level: 5, segment: 1} }"
              :src="require('@/assets/images/reclaim/training/cat_pom_thumb.png')"/>
          </div>
        </div>
      </r-section-box>

      <r-section-box class="col-start-2 col-span-8" :arrows="['down']">
        <div class="grid grid-cols-4 gap-4">

          <!-- section 6 -->
          <r-section-button
            :to="{ name: 'training', params: {course: 'student', level: 6, segment: 1} }"
            :src="require('@/assets/images/reclaim/training/cat_sc_thumb.png')"/>

          <!-- section 7 -->
          <r-section-button
            :to="{ name: 'training', params: {course: 'student', level: 7, segment: 1} }"
            :src="require('@/assets/images/reclaim/training/cat_si_thumb.png')"/>

          <!-- section 8 -->
          <r-section-button
            :to="{ name: 'training', params: {course: 'student', level: 8, segment: 1} }"
            :src="require('@/assets/images/reclaim/training/cat_mr_thumb.png')"/>

          <!-- section 9 -->
          <r-section-button
            :to="{ name: 'training', params: {course: 'student', level: 9, segment: 1} }"
            :src="require('@/assets/images/reclaim/training/cat_pr_thumb.png')"/>
        </div>
      </r-section-box>

      <div class="col-start-4 col-span-4">

        <!-- section 10 -->
        <r-section-button theme="grey" :bar="false"
                          :to="{ name: 'training', params: {course: 'student', level: 10, segment: 1} }"
                          :src="require('@/assets/images/reclaim/training/cf_thumb.png')"/>
      </div>

      <r-confirm-modal ref="over" @confirm="clearProgress">
        Are you sure you want to clear all progress and start over?
      </r-confirm-modal>

    </div>
  </r-program-layout>
</template>


<script>
import api from '@/api';
import store from '@/store';
import RSectionButton from './r-section-button';
import RSectionBox from './r-section-box';


export default {
  name: 'student-trainings',
  components: {
    RSectionButton,
    RSectionBox,
  },
  methods: {
    startOver() {
      this.$refs.over.show();
    },
    clearProgress() {
      api.program.reset();
    },
    leftOff() {
      this.$router.push(this.next);
    },
  },
  computed: {
    program: store.getter('program'),
    next() {
      if (this.program.next) {
        return {
          name: 'training',
          params: {
            course: this.program.next.course,
            level: this.program.next.level_number,
            segment: this.program.next.segment,
          },
        };
      }
      return { name: 'training', params: { course: 'student', level: 0, segment: 1 } };
    },
  },
  async created() {
    api.program.load();
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout class="a-trainings">
    <template slot="title"><b-icon icon="camera-video"/>Trainings</template>
    <div class="h-3 flex-shrink-0"></div>

    <!-- search -->
    <b-input-group>
      <b-form-input type="text" v-model="term" @input="search" placeholder="Search for Training"></b-form-input>
      <b-input-group-append>
        <b-button @click="search" variant="primary"><b-icon icon="search"/></b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="h-3 flex-shrink-0"></div>
    <b-table-lite striped bordered :items="trainings">
      <template #cell(segment)="data">
        <router-link :to="{ name: 'admin-training', params: {id: data.value.id} }">
          {{ data.value.label }}
        </router-link>
      </template>
    </b-table-lite>
    <r-paginator @changed="load"/>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import { debounce } from 'lodash';

export default {
  name: 'a-trainings',
  methods: {
    async load(page) {
      api.adminTrainings.load({
        limit: this.limit,
        offset: this.limit * (page || 0),
        query: this.term,
      });
    },
    search: debounce(function () {
      if (this.term) {
        this.$router.replace({
          name: 'admin-trainings',
          query: { query: this.term },
        });
      } else {
        this.$router.replace({ name: 'admin-trainings' });
      }
      this.load(0);
    }, 600),
  },
  computed: {
    trainings() {
      return models.AdminTraining.query().orderBy('id', 'desc').all()
        .map((x) => ({
          segment: { label: `${x.segment}. ${x.segment_name}`, id: x.id },
          level: `${x.level_number}. ${x.level_name}`,
          active: x.active,
        }));
    },
  },
  data() {
    return {
      limit: 25,
      term: '',
    };
  },
  async created() {
    this.term = new URLSearchParams(window.location.search).get('query');
    await this.load();
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="tags-fill"/>Coupons</template>
    <template slot="buttons">
      <router-link :to="{ name: 'admin-coupon', params: {id: 'new'} }">
        <button class="admin-btn">Add Coupon</button>
      </router-link>
    </template>
    <div class="h-3 flex-shrink-0"></div>

    <!-- search -->
    <b-input-group>
      <b-form-input type="text" v-model="term" @input="search" placeholder="Search for Coupon"></b-form-input>
      <b-input-group-append>
        <b-button @click="search" variant="primary"><b-icon icon="search"/></b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="h-3 flex-shrink-0"></div>
    <b-table-lite striped bordered :items="coupons">
      <template #cell(code)="data">
        <router-link :to="{ name: 'admin-coupon', params: {id: data.value.id} }">
          {{ data.value.code }}
        </router-link>
      </template>
    </b-table-lite>
    <r-paginator @changed="load"/>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import { debounce } from 'lodash';

export default {
  name: 'a-coupons',
  methods: {
    async load(page) {
      api.adminCoupons.load({
        limit: this.limit,
        offset: this.limit * (page || 0),
        query: this.term,
      });
    },
    search: debounce(function () {
      if (this.term) {
        this.$router.replace({
          name: 'admin-coupons',
          query: { query: this.term },
        });
      } else {
        this.$router.replace({ name: 'admin-coupons' });
      }
      this.load(0);
    }, 600),
  },
  computed: {
    coupons() {
      return models.AdminCoupon.query().orderBy('id', 'desc').all()
        .map((c) => ({
          code: { code: c.code, id: c.id },
          discount: c.discount,
          expiration: c.expiration,
        }));
    },
  },
  data() {
    return {
      limit: 25,
      term: '',
    };
  },
  async created() {
    this.term = new URLSearchParams(window.location.search).get('query');
    await this.load();
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .a-create-user {
  }
</style>


<template>
  <r-admin-layout class="a-create-user">
    <!-- title -->
    <template slot="title"><b-icon icon="person-fill"/>Add User</template>

    <!-- form -->
    <r-admin-card title="User Information">
      <r-create-user-form/>
    </r-admin-card>

  </r-admin-layout>
</template>


<script>
import RCreateUserForm from '@/components/forms/r-create-user-form';


export default {
  name: 'a-create-user',
  components: {
    RCreateUserForm,
  },
};
</script>

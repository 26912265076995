<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-messages {
    .vertical-container {
      min-height: 100%;
    }
    .disabled {
      color: $color-gray-7 !important;
      text-decoration: none !important;
      cursor: default;
    }
    .filters {
      font-size: 14px;
    }
    .compose-toolbar {
      align-items: center;
    }
  }
</style>


<template>
  <r-program-layout class="r-messages">

    <div class="vertical-container v-box flex-grow">

      <!-- search -->
      <b-input-group>
        <b-form-input class="filter-students" type="text" v-model="term" @input="search"></b-form-input>
        <b-input-group-append>
          <b-button @click="search" variant="primary"><b-icon icon="search"/></b-button>
        </b-input-group-append>
      </b-input-group>


      <!-- toolbar -->
      <div class="compose-toolbar h-box flex-limit pt-4">
        <b-button @click="$refs.compose.show()" size="sm" variant="primary">Compose message</b-button>
        <div class="flex-spacer"></div>
        <div class="filters">
          <a href="" :class="{disabled: filter==='all'}" @click.prevent="setFilter('all')">
            All
          </a> |
          <a href="" :class="{disabled: filter==='waiting'}" @click.prevent="setFilter('waiting')">
            Waiting
          </a> |
          <a href="" :class="{disabled: filter==='unmessaged'}" @click.prevent="setFilter('unmessaged')">
            Unmessaged
          </a>
        </div>
      </div>

      <!-- connections -->
      <div class="flex-grow">
        <r-connection v-for="c in connections" :key="c.id" :connection="c"/>
      </div>

      <!-- pages -->
      <div class="h-box flex-limit pt-4">
        <div>
          <b-button-group class="mx-1">
            <b-button :disabled="page === 0" @click="prev">
              &lsaquo;
            </b-button>
          </b-button-group>
        </div>
        <div class="flex-spacer"></div>
        <b-button-group class="mx-1">
          <b-button :disabled="connections.length < limit" @click="next">
            &rsaquo;
          </b-button>
        </b-button-group>
      </div>
    </div>

    <!-- compose modal -->
    <r-clean-modal ref="compose" size="lg">
      <r-message-form @success="sent" :contacts="contacts"/>
    </r-clean-modal>
  </r-program-layout>
</template>


<script>
import { debounce } from 'lodash';
import api from '@/api';
import models from '@/models';
import RMessageForm from '@/components/forms/r-message-form';
import RConnection from './r-connection';


export default {
  name: 'messages',
  components: {
    RMessageForm,
    RConnection,
  },
  data() {
    return {
      term: this.$route.query.term || '',
      page: this.toNumber(this.$route.query.page, 0),
      limit: this.toNumber(this.$route.query.limit, 10),
      filter: this.$route.query.filter || 'all',
      order: this.$route.query.order || 'last_sent',
      contacts: [],
    };
  },
  computed: {
    connections: () => models.Connection.query().orderBy(
      (c) => (c.last_sent ? c.last_sent.created_at : c.id),
      'desc',
    ).all(),
  },
  methods: {
    toNumber(val, def) {
      try {
        return parseInt(val, 10) || def;
      } catch {
        return def;
      }
    },
    sent() {
      this.$refs.compose.hide();
      this.search();
    },
    prev() {
      this.page = Math.max(this.page - 1, 0);
      this.search();
    },
    next() {
      this.page += 1;
      this.search();
    },
    setFilter(v) {
      if (this.filter === v) {
        return;
      }
      this.filter = v;
      this.search();
    },
    search: debounce(function (update) {
      this.offset = this.page * this.limit;
      api.connections.load({
        query: this.term,
        limit: this.limit,
        offset: this.offset,
        filter: this.filter,
      });
      if (!update) {
        this.$router.push({
          path: '/messages',
          query: {
            term: this.term,
            page: this.page,
            limit: this.limit,
            filter: this.filter,
            order: this.order,
          },
        });
      }
    }, 600),
  },
  async created() {
    const cont = Object.values(await api.connections.index({ slim: true }));
    this.contacts = cont.sort((a, b) => a.display_name.localeCompare(b.display_name));
    this.search(true);
  },
};
</script>

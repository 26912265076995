import Journal from '@/models/journal';
import restify from '@/extensions/restify';

const api = restify(Journal, '/journals');

export default {
  load: api.load,
  get: api.get,
  create: api.create,
  update: api.update,
  remove: api.remove,
};

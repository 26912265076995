<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-article {
    .heading {
      padding-bottom: 20px;
    }
    .title {
      font-size: 22px;
    }
    .author {

    }
    .video, .audio {
      padding-bottom: 20px;
    }
  }
</style>


<template>
  <r-program-layout class="r-article" v-if="article">
    <div class="heading">
      <div class="title">{{article.title}}</div>
      <div class="author" v-show="article.author">by {{article.author}}</div>
    </div>
    <div class="video" v-if="article.video">
      <r-video :src="article.video" :poster="thumbnail"></r-video>
    </div>

    <div class="audio" v-if="article.audio">
      <r-audio :src="article.audio" :poster="thumbnail"></r-audio>
    </div>
    <div v-if="article.body" v-html="article.body"></div>
  </r-program-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import articleImage from '@/assets/images/reclaim/ReclaimPlusArticle.jpg';
import podcastImage from '@/assets/images/reclaim/ReclaimPlusPodcast.jpg';


export default {
  name: 'r-article',
  data() {
    return {
      article: {},
    };
  },
  methods: {
    async load() {
      this.article = models.Article.find(this.$route.params.id);
      if (!this.article) {
        this.article = await api.articles.get(this.$route.params.id);
      }
    },
  },
  computed: {
    thumbnail() {
      if (this.article.thumbnail) {
        return this.article.thumbnail;
      } if (this.article.video || this.article.audio) {
        return podcastImage;
      }
      return articleImage;
    },
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

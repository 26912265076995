<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-forgot-form {
  }
</style>


<template>
  <b-form @submit.prevent="submit">
    <!-- email -->
    <b-form-group>
      <p class="instruction">
        Enter your account's email address and you will receive an email
        with instructions on how to reset your password.
      </p>
      <b-form-input v-model="form.email" type="text" placeholder="Email" :state="states.email"/>
      <b-form-invalid-feedback>
        Please enter a valid email address.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <b-button type="submit" variant="primary" class="mr-2">Send email</b-button>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';

export default {
  name: 'r-forgot-form',
  mixins: [form],
  methods: {
    async submitted() {
      await api.password.forgot(this.form.email);
      api.alerts.success('A password reset email will be sent if the username exists.');
      this.$router.push({ name: 'signin' }).catch(() => {});
    },
    async errored() {
      api.alerts.error('There was an error requesting a password reset.');
    },
  },
};
</script>

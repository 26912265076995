<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-forum-table {
    .category {
      color: white;
      background: $color-blue;
      padding: 5px;
      font-size: 14px;
      font-weight: normal;
    }
    .extra {
      width: 100%;
      padding: 5px;
      background: $color-gray-1;
      font-size: 14px;
      border: 1px solid $color-gray-3;
    }
    .forum-table {
      width: 100%;
      padding: 5px;
      background: $color-gray-1;
      font-size: 14px;
      th, td {
        padding: 4px 8px;
        border: 1px solid $color-gray-3;
      }
    }
  }
</style>


<template>
  <div class="r-forum-table">
    <div class="category">
      <slot name="title"></slot>
    </div>
    <table class="table-auto forum-table p-5">
      <thead>
        <slot name="head"></slot>
      </thead>
      <tbody>
        <slot name="body"></slot>
      </tbody>
    </table>
    <div class="extra" v-if="$slots.contents">
      <slot name="contents">
      </slot>
    </div>
  </div>
</template>


<script>
export default {
  name: 'r-forum-table',
};
</script>

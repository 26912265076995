<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-tab-card {
    .tab {
      font-weight: normal;
      padding: 10px 20px;
      border-radius: 3px 3px 0px 0px;
      &.active {
        background: $color-white;
        border: 1px solid $color-gray-3;
        border-bottom: none;
      }
      &.inactive {
        color: $color-blue;
        cursor: pointer;
        border: 1px solid transparent;
        border-bottom: none;
        &:hover {
          opacity: 65%;
          background: $color-gray-3;
        }
      }
    }
    .page {
      background: $color-white;
      padding: 20px;
      border: 1px solid $color-gray-3;
    }
  }
</style>

<template>
  <r-shade-card class="r-tab-card">
    <div class="tabs h-box">
      <div class="tab" v-for="(tab, i) in tabs" :key="i" :class="styles(i)" @click="active = i">
        {{tab}}
      </div>
    </div>
    <div class="page">
      <slot :name="active"></slot>
    </div>
  </r-shade-card>
</template>

<script>
export default {
  name: 'r-tab-card',
  props: ['tabs', 'open'],
  data() {
    return {
      active: this.open || 0,
    };
  },
  methods: {
    styles(i) {
      return { active: i === this.active, inactive: i !== this.active };
    },
  },
};
</script>

import store from '@/store';
import rest from './rest';

async function update(fields) {
  const profile = await rest.post('/billing', fields);
  store.set('profile', profile);
}

async function subscribe(fields) {
  const profile = await rest.post('/billing/subscribe', fields);
  store.set('profile', profile);
}

async function cancel() {
  const profile = await rest.put('/billing/cancel');
  store.set('profile', profile);
}


export default {
  update, subscribe, cancel,
};

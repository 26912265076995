<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-frc-form {

  }
</style>


<template>
  <b-form class="r-frc-form" @submit.prevent="submit">
    <!-- title -->
    <b-form-group label="Title">
      <b-form-input v-model="form.title" :state="states.title" type="text"
                    placeholder="Type your title here..."/>
      <b-form-invalid-feedback>
        Please enter a frc entry title.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- triggers -->
    <b-form-group label="Triggers">
      <b-form-textarea rows="5" max-rows="5" v-model="form.triggers" :state="states.triggers"
                       placeholder="Type triggers here..."/>
      <b-form-invalid-feedback>
        Please enter triggers.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- face it -->
    <b-form-group label="Face It">
      <b-form-textarea rows="5" max-rows="5" v-model="form.face_it" :state="states.face_it"
                       placeholder="Type face it here..."/>
      <b-form-invalid-feedback>
        Please enter face it.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- replace it -->
    <b-form-group label="Replace It">
      <b-form-textarea rows="5" max-rows="5" v-model="form.replace_it" :state="states.replace_it"
                       placeholder="Type replace it here..."/>
      <b-form-invalid-feedback>
        Please enter replace it.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- connect it -->
    <b-form-group label="Connect">
      <b-form-textarea rows="5" max-rows="5" v-model="form.connect" :state="states.connect"
                       placeholder="Type your connect here..."/>
      <b-form-invalid-feedback>
        Please enter connect.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';


export default {
  name: 'r-frc-form',
  mixins: [form],
  props: ['value'],
  methods: {
    async submitted() {
      if (this.value) {
        this.$emit('input', await api.frcs.update(this.value.id, this.form));
        api.alerts.success('Your frc has been updated.');
      } else {
        const frc = await api.frcs.create(this.form, false);
        this.$emit('input', frc);
        this.$emit('create', frc);
        api.alerts.success('Your frc has been created.');
      }
    },
    async reset() {
      if (this.value) {
        this.form = {};
        ['title', 'triggers', 'face_it', 'replace_it', 'connect'].forEach((field) => {
          if (this.value[field]) this.form[field] = this.value[field];
        });
      } else {
        this.form = {};
      }
      this.errors = {};
    },
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

import rest from '@/api/rest';
import fence from '@/extensions/fence';


function restify(model, root) {
  async function index(params) {
    return rest.get(root, params);
  }

  async function get(id) {
    const object = await rest.get(`${root}/${id}`);
    model.insert({ data: [object] });
    return model.find(object.id);
  }

  async function create(fields, insert) {
    const object = await rest.post(root, fields);
    if (insert !== false) {
      model.insert({ data: [object] });
    }
    return model.find(object.id);
  }

  async function ensure(fields) {
    const object = await rest.put(root, fields);
    model.insert({ data: [object] });
    return model.find(object.id);
  }

  async function update(id, fields) {
    const object = await rest.put(`${root}/${id}`, fields);
    model.update({ where: id, data: [object] });
    return model.find(object.id);
  }
  async function remove(id) {
    await rest.delete(`${root}/${id}`);
    model.delete(id);
  }

  const fencedIndex = fence(index);
  async function load(params, key) {
    const data = await fencedIndex(params);
    if (key === undefined) {
      model.create({ data: Object.values(data) });
    } else {
      model.create({ data: Object.values(data[key]) });
    }
    return data;
  }

  return {
    load, index, get, create, ensure, update, remove,
  };
}


export default restify;

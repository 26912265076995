import { Model } from '@vuex-orm/core';

export default class AdminBan extends Model {
  static entity = 'admin-bans'

  static fields() {
    return {
      id: this.attr(null),
      user: this.attr(null),
      username: this.attr(''),
      ban_start: this.attr(''),
      ban_end: this.attr(''),
      reason: this.attr(''),
    };
  }
}

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .training {
    .title {
      font-size: 22px;
      color: $color-blue;
      font-weight: normal;
      text-transform: uppercase;
    }
    .page {
      min-height: 100%;
    }
    .training-body {
      font-size: 110%;
    }
  }
</style>


<template>
  <r-program-layout class="training">
    <div class="page v-box" v-if="training">
      <!-- title -->
      <div class="title">{{training.segment_name}}</div>
      <hr class="mt-2">

      <!-- video -->
      <div class="video mt-3" v-if="training.video">
        <r-video :src="training.video" :poster="training.thumbnail"></r-video>
      </div>

      <!-- audio -->
      <div class="audio mt-3" v-if="training.audio">
        <r-audio :src="training.audio" :poster="training.thumbnail"></r-audio>
      </div>

      <!-- text -->
      <div class="training-body prosemirror-rendered" v-if="training.body" v-html="body"></div>

      <!-- jump buttons -->
      <div class="flex-spacer"></div>
      <div class="h-box items-center flex-limit">
        <div class="flex-limit mr-2">Jump to:</div>
        <b-form-select class="mr-2" size="sm"
                       @input="jump($event, 1)"
                       :value="level"
                       :options="levels"/>
        <b-form-select class="mr-2" size="sm"
                       @input="jump(level, $event)"
                       :value="segment"
                       :options="segments"/>
        <router-link v-if="next" :to="next">
          <b-button size="sm" variant="primary">Next</b-button>
        </router-link>
      </div>
    </div>
  </r-program-layout>
</template>


<script>
import { sortBy } from 'lodash';
import api from '@/api';
import store from '@/store';

export default {
  name: 'training',
  data() {
    return {
      training: null,
    };
  },
  watch: {
    async index() {
      this.training = await api.program.get(this.course, this.level, this.segment);
    },
    async training() {
      api.program.mark(this.course, this.level, this.segment);
    },
  },
  computed: {
    program: store.getter('program'),
    levels() {
      try {
        return Object.values(this.program[this.course].levels).map((level) => ({
          value: level.number,
          text: `${level.number} - ${level.name}`,
        }));
      } catch {
        return [];
      }
    },
    segments() {
      try {
        return sortBy(Object.values(this.program[this.course].levels[this.level].trainings).map((t, i) => ({
          value: t.number,
          text: `${i} - ${t.name}`,
          order: i,
        })), 'value');
      } catch {
        return [];
      }
    },
    next() {
      // make sure we have loaded segments
      if (!this.segments || !this.segments.length) {
        return null;
      }

      // try increasing segment
      {
        const { level } = this;
        const segment = parseInt(this.segment, 10);
        const indexed = {};
        for (const training of this.segments) {
          indexed[training.value] = training;
        }
        const next = this.segments[indexed[segment].order + 1];
        if (next) {
          return {
            name: 'training',
            params: { course: this.course, level, segment: next.value },
          };
        }
      }
      // if segment failed try increasing level
      try {
        const level = `${parseInt(this.level, 10) + 1}`;
        const segment = 1;
        if (this.program[this.course].levels[level].trainings[segment]) {
          return { name: 'training', params: { course: this.course, level, segment } };
        }
      } catch {
        // pass
      }
      // if course failed try incrementing
      try {
        const courses = ['support', 'spouse', 'parent', 'friends', 'clergy', 'clinician'];
        const index = courses.indexOf(this.course);
        const course = index >= 0 ? courses[index + 1] : undefined;
        const level = 0;
        const segment = 1;
        if (this.program[course].levels[level].trainings[segment]) {
          return { name: 'training', params: { course, level, segment } };
        }
      } catch {
        // pass
      }
      // return null
      return null;
    },
    course() {
      return this.$route.params.course;
    },
    level() {
      return this.$route.params.level;
    },
    segment() {
      return this.$route.params.segment;
    },
    index() {
      return { course: this.course, level: this.level, segment: this.segment };
    },
    body() {
      return this.training.body.replaceAll('  ', ' &nbsp;').replaceAll('\n', '<br>');
    },
  },
  methods: {
    jump(level, segment) {
      if (level !== this.level || segment !== this.segment) {
        this.$router.push({ name: 'training', params: { course: this.course, level, segment } });
      }
    },
  },
  async created() {
    this.training = await api.program.get(this.course, this.level, this.segment);
    if (Object.keys(store.get('program')).length === 0) {
      api.program.load();
    }
  },
};
</script>

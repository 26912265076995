import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import pathify, { make, get, sync } from 'vuex-pathify';
import models from '@/models';


Vue.use(Vuex);

// build state
function initial() {
  return {
    loaded: false,
    loading: true,
    authenticated: false,
    alerts: {},
    profile: {},
    products: {},
    program: {},
  };
}

// register models
const database = new VuexORM.Database();
const modelList = [
  ...Object.values(models).filter((v) => v.entity),
  ...Object.values(models.admin).filter((v) => v.entity)];
modelList.forEach((model) => database.register(model));

// create store
const state = initial();
const store = new Vuex.Store({
  state,
  mutations: {
    reset(s) {
      Object.assign(s, initial());
      store.dispatch('entities/deleteAll');
    },
    ...make.mutations(state),
  },
  plugins: [pathify.plugin, VuexORM.install(database)],
});

function reset() {
  store.commit('reset');
}
// inject pathify getter and sync onto store to reduce imports
store.sync = sync;
store.getter = get;
store.reset = reset;

// create store
export default store;

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-today {
  }
</style>


<template>
  <div class="r-today grid gap-10 grid-cols-2">
    <div>
      <div class="form-title">Daily Tracking</div>
      <r-indicator-form :date="today"/>
    </div>
    <div>
      <div class="form-title">Daily Journal</div>
      <r-journal-form/>
    </div>
  </div>
</template>


<script>
import { DateTime } from 'luxon';
import RIndicatorForm from '@/components/forms/r-indicator-form';
import RJournalForm from '@/components/forms/r-journal-form';

export default {
  name: 'r-today',
  components: {
    RIndicatorForm,
    RJournalForm,
  },
  computed: {
    today() {
      const now = DateTime.now();
      const today = new Date(now.year, now.month - 1, now.day).toISOString();
      return today;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .articles {
    .page {
      min-height: 100%;
    }
    .preview {
      .thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .title {
        text-align: center;
      }
    }
  }
</style>


<template>
  <r-program-layout class="articles">
    <div class="page v-box">

      <!-- articles -->
      <div class="grid grid-cols-4 gap-4">
        <div class="preview" v-for="article in articles" :key="article.id">
          <router-link :to="{ name: 'article', params: {id: article.id} }">
            <r-aspect-ratio-box :width="1280" :height="720">
              <img class="thumbnail" :src="thumbnail(article)">
            </r-aspect-ratio-box>
            <div class="title">{{article.title}}</div>
          </router-link>
        </div>
      </div>

      <!-- spacer -->
      <div class="flex-spacer"></div>

      <!-- paginator -->
      <r-paginator :key="$route.fullPath" @changed="load" :limit="limit" :count="articles.length"/>

    </div>
  </r-program-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import articleImage from '@/assets/images/reclaim/ReclaimPlusArticle.jpg';
import podcastImage from '@/assets/images/reclaim/ReclaimPlusPodcast.jpg';


export default {
  name: 'articles',
  data() {
    return {
      limit: 12,
    };
  },
  computed: {
    articles() {
      return models.Article.query().orderBy('created_at', 'desc').all();
    },
  },
  methods: {
    thumbnail(article) {
      if (article.thumbnail) {
        return article.thumbnail;
      } if (article.video || article.audio) {
        return podcastImage;
      }
      return articleImage;
    },
    async load(page) {
      api.articles.load({ limit: this.limit, offset: this.limit * (page || 0) });
    },
  },
  async created() {
    await this.load();
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

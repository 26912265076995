<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  ul {
    list-style: disc outside none;
    padding-left: 25px;
  }
  .box {
    padding: 15px 15px 10px;
    margin: 20px 0;
    background-color: #f1f1f1;
    border: 2px solid #ddd;
  }
</style>


<template>
  <r-program-layout>
    <div>
      <h4>Welcome to RECLAiM Support</h4>
      <p></p>
      <p>You’re here because you either:</p>
      <ul>
        <li>Need techincal assistance with the RECLAiM program</li>
        <li>Have a question for our customer support staff</li>
        <li>Have a billing question</li>
        <li>Want to make a cancellation request*</li>
        <li>Or maybe you just accidentally clicked on the “Contact Us” tab</li>
      </ul>
      <p></p>
      <p>Our commitment to our users is to provide timely responses to all inquiries, generally
        <strong>within 24-48 hours</strong>.</p>
      <p>Reclaim Support is not the place to inquire about how to use individual features of the
        program (unless, of course, the particular feature is not working properly). Your Reclaim
        Coach can best answer these inquiries, or you might consider seeking help from more
        experienced Reclaim users by posting your question on the Reclaim forums. There will
        be many who will be happy to help you.</p>
      <div class="box">
        <p>Email <a href="mailto:support@reclaimsexualhealth.com">
          <strong>support@reclaimsexualhealth.com</strong></a>
          for technical suppport and customer service issues (please include your username/email
          address associated with your account).</p>
      </div>
      <p><strong>*If you are writing Reclaim Support to cancel your account:</strong></p>
      <p>Please recall that you might have a membership that calls for a six-month commitment
        before you can cancel. Please refer to the terms and conditions of your particular
        subscription to determine the cancellation requirements. All students are required
        to give Reclaim notice in writing to cancel their accounts. It is not appropriate
        to contact your coach and have them attempt to pass the message along to support.
        To stop your billing, request must be made on or before the actual date of billing
        for your payment.</p>
    </div>
  </r-program-layout>
</template>


<script>
export default {
  name: 'support',
  components: {
  },
};
</script>

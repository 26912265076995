<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-journal-form {

  }
</style>


<template>
  <b-form class="r-journal-form" @submit.prevent="submit">
    <!-- title -->
    <b-form-group label="Title">
      <b-form-input v-model="form.title" :state="states.title" type="text"
                    placeholder="Type your title here..."/>
      <b-form-invalid-feedback>
        Please enter a journal entry title.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- entry -->
    <b-form-group label="Entry">
      <b-form-textarea rows="10" max-rows="10" v-model="form.entry" :state="states.entry"
                       placeholder="Type your journal entry here..."/>
      <b-form-invalid-feedback>
        Please enter a journal entry.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';


export default {
  name: 'r-journal-form',
  mixins: [form],
  props: ['value'],
  methods: {
    async submitted() {
      if (this.value) {
        this.$emit('input', await api.journals.update(this.value.id, this.form));
        api.alerts.success('Your journal has been updated.');
      } else {
        const journal = await api.journals.create(this.form, false);
        this.$emit('input', journal);
        this.$emit('create', journal);
        api.alerts.success('Your journal has been created.');
      }
    },
    async reset() {
      if (this.value) {
        this.form = {};
        ['title', 'entry'].forEach((field) => {
          if (this.value[field]) this.form[field] = this.value[field];
        });
      } else {
        this.form = {};
      }
      this.errors = {};
    },
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-payments {}
</style>


<template>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="">
      <tr>
        <th>Date</th>
        <th>Expiration Date</th>
        <th>Amount</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="payment in payments" :key="payment.id">
        <td>{{payment.created_at | date}}</td>
        <td>{{payment.expire_date | date}}</td>
        <td>{{payment.amount | currency}}</td>
      </tr>
    </tbody>
  </table>
</template>


<script>
import api from '@/api';
import models from '@/models';

export default {
  name: 'r-payments',
  computed: {
    payments: () => models.Payment.query().orderBy('created_at', 'desc').get(),
  },
  created() {
    api.payments.load();
  },
};
</script>

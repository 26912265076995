import { Model } from '@vuex-orm/core';

export default class Status extends Model {
  static entity = 'statuses'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(''),
      profile: this.attr(''),
      author: this.attr(''),
      avatar: this.attr(''),
      body: this.attr(''),
      private: this.attr(),
    };
  }
}

import { debounce } from 'lodash';


export default {
  watch: {
    confirm: debounce(function () { this.validate(); }, 600),
  },
  computed: {
    confirm() {
      return this.form.confirm;
    },
  },
  methods: {
    async validate() {
      if (this.form.confirm !== this.form.password) {
        this.$set(this.errors, 'confirm', 'unmatched');
      } else {
        this.$delete(this.errors, 'confirm');
      }
    },
  },
};

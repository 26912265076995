<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-cvv-modal {

  }
</style>


<template>
  <r-clean-modal ref="modal" size="lg">
    <template slot="title">Finding Your CVV Number</template>
    <p>The CVV number is a three or four digit number found on the front or
      back of your credit card. Visa and Mastercard print the three digit number
      on the back of the card.</p>
    <p class="flex justify-center"><img src="@/assets/images/reclaim/cvv-visa.png"></p>
    <p>American Express prints a four digit card on the front of the card.</p>
    <p class="flex justify-center"><img src="@/assets/images/reclaim/cvv-amex.png"></p>
  </r-clean-modal>
</template>


<script>
import modal from '@/extensions/modal';

export default {
  name: 'r-cvv-modal',
  methods: {
    ...modal.methods,
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-aspect-ratio-box {
    height: 0;
    overflow: hidden;
    padding-top: 0;
    position: relative;
    .aspect-ratio-box-inside {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
</style>


<template>
  <div class="r-aspect-ratio-box" :style="{'padding-top': ratio}">
    <div class="aspect-ratio-box-inside">
      <slot></slot>
    </div>
  </div>
</template>


<script>
export default {
  name: 'r-aspect-ratio-box',
  props: ['width', 'height'],
  computed: {
    ratio() { return `${(this.height / this.width) * 100}%`; },
  },
};
</script>

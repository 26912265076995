var render = function render(){var _vm=this,_c=_vm._self._c;return _c('r-admin-layout',{staticClass:"a-create-user"},[_c('template',{slot:"title"},[_c('b-icon',{attrs:{"icon":"person-fill"}}),_vm._v("Edit User")],1),_c('r-admin-card',{attrs:{"title":"User Information"}},[(_vm.user)?_c('r-edit-user-form',{attrs:{"user":_vm.user}}):_vm._e()],1),_c('r-admin-card',{attrs:{"title":"User Permission Details"}},[(_vm.user && _vm.user.student)?_c('p',{staticStyle:{"color":"green"}},[_vm._v("User subscription is active.")]):_c('p',{staticStyle:{"color":"red"}},[_vm._v("User subscription is not active.")]),_c('hr'),_c('p',[_vm._v("Accessible Products/Subscriptions:")]),(_vm.user && _vm.user.current_products && _vm.user.current_products.length)?_c('ul',_vm._l((_vm.user.current_products),function(p){return _c('li',{key:p.id},[_vm._v(" "+_vm._s(p.name)+" - "),(p.expired)?_c('span',{staticStyle:{"color":"red"}},[_vm._v(" Expired"+_vm._s(_vm._f("datetime")(p.expiration))+" ")]):(p.expiration)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(" Expires "+_vm._s(_vm._f("datetime")(p.expiration))+" ")]):_c('span',{staticStyle:{"color":"green"}},[_vm._v(" Does not expire ")])])}),0):_c('p',{staticStyle:{"color":"blue"}},[_vm._v("None.")]),_c('hr'),_c('p',[_vm._v("Accessible Content:")]),(_vm.user && _vm.user.current_courses && _vm.user.current_courses.length)?_c('ul',_vm._l((_vm.user.current_courses),function(c){return _c('li',{key:c.id},[_vm._v(_vm._s(c.name))])}),0):_c('p',{staticStyle:{"color":"blue"}},[_vm._v("None.")])]),_c('r-admin-card',{attrs:{"title":"Recent Payment Details"}},[_c('div',[_vm._v("Current billing info: "),_c('span',{staticClass:"billing"},[_vm._v(_vm._s(_vm.billing))])]),_c('br'),_c('b-table-lite',{attrs:{"striped":"","bordered":"","items":_vm.payments,"fields":[
      'transactionId',
      'creditCard',
      'product',
      'amount',
      'status',
      'created',
      'begins',
      'expires']},scopedSlots:_vm._u([{key:"cell(transactionId)",fn:function(data){return [_c('router-link',{attrs:{"to":{ name: 'admin-payment', params: {id: data.item.id} }}},[_vm._v(" "+_vm._s(data.item.transactionId)+" ")])]}}])})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
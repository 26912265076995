<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .confirmation  {
    font-size: 16px;
    padding-bottom: 10px;
  }
  /deep/ .modal {
    .modal-body {
      padding-top: 0px;
    }
    .modal-header {
      padding-bottom: 0px;
    }
    .modal-footer {
      padding-top: 0px;
    }
  }
</style>


<template>
  <b-modal ref="modal" :busy="true" v-bind="$attrs">
    <div class="confirmation"><slot>Are you sure you wish to do this?</slot></div>
    <div class="text-right">
      <div>
        <b-button variant="primary" class="mr-2" @click.prevent="hide">
          Cancel
        </b-button>
        <b-button variant="danger" @click.prevent="confirm">
          Confirm
        </b-button>
      </div>
    </div>
    <template #modal-footer><div class="footer"></div></template>
  </b-modal>
</template>


<script>
import modal from '@/extensions/modal';

export default {
  name: 'r-confirm-modal',
  methods: {
    ...modal.methods,
    confirm() {
      this.$emit('confirm');
      this.hide();
    },
  },
};
</script>

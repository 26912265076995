<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-admin-table {
    margin-top: 20px;
    td, th {
      padding: 12px;
      // border: 1px solid $color-gray-4;
      border: 1px solid #dee2e6;
    }
    thead {
      //border-bottom: 2px solid $color-gray-8;
      border-bottom: 2px solid #dee2e6;
    }
    tbody {
      tr:nth-child(odd) {
        background: rgba(0, 0, 0, 0.05);
      }
      tr:hover {
        // background: $color-gray-2;
        background-color: rgba(0, 0, 0, 0.075) !important;
      }
    }
  }
</style>


<template>
  <table class="r-admin-table divide-gray-200">
    <thead class="">
      <slot name="head">
      </slot>
    </thead>
    <tbody>
      <slot name="body">
      </slot>
    </tbody>
  </table>
</template>


<script>
export default {
  name: 'r-admin-table',
};
</script>

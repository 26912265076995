import api from '@/api';
import { debounce } from 'lodash';


export default {
  data() {
    return {
      form: {},
      errors: {},
    };
  },
  computed: {
    // input field states for bootstrap inputs (true is green, false is red, null is normal)
    states() {
      const state = {};
      Object.keys(this.errors || {}).forEach((k) => {
        state[k] = this.errors[k] ? false : null;
      });
      return state;
    },
  },
  methods: {
    async reset() {
      this.form = {};
      this.errors = {};
    },
    submit: debounce(async function submit() {
      this.errors = {};
      await this.validate();
      if (Object.keys(this.errors).length > 0) return;
      try {
        await this.submitted(this.form);
        this.reset();
      } catch (error) {
        if (error instanceof api.ApiError) {
          this.errors = error.data || {};
          await this.rejected(this.errors);
        } else {
          await this.errored(error);
          throw error;
        }
      }
    }, 500),
    async validate(form) { // eslint-disable-line no-unused-vars
    },
    async submitted(form) { // eslint-disable-line no-unused-vars
    },
    async rejected(error) { // eslint-disable-line no-unused-vars
    },
    async errored(error) { // eslint-disable-line no-unused-vars
    },
  },
  created() {
    this.reset();
  },
};

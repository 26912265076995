import { Model } from '@vuex-orm/core';

export default class Journal extends Model {
  static entity = 'journals'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      title: this.attr(''),
      entry: this.attr(''),
    };
  }
}

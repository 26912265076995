import { Model } from '@vuex-orm/core';

export default class Message extends Model {
  static entity = 'messages'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      subject: this.attr(''),
      body: this.attr(''),
      read: this.attr(null),
      cost: this.attr(''),
      sender: this.attr(null),
    };
  }
}

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-program-layout>
    <div>
      <p class="text-center">
        <strong class="text-danger">Warning!</strong> Your avatar may be visible to other students and to your coach.
        Avatars are used in the student forum and in the coach message board. Please don’t upload an avatar you
        wouldn’t want others to see.
      </p>
      <r-profile-form/>
    </div>
  </r-program-layout>
</template>


<script>
import RProfileForm from '@/components/forms/r-profile-form';


export default {
  name: 'profile',
  components: {
    RProfileForm,
  },
};
</script>

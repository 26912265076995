<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .forums {
    .welcome, .warning {
      font-size: 14px;
    }
    .warning {
      padding: 5px;
      border: 1px solid $color-gray-3;
      background: $color-gray-2;
    }
    .category {
      color: white;
      background: $color-blue;
      padding: 5px;
      font-size: 14px;
      font-weight: normal;
    }
    .forum-name, .post-date {
      color: $color-blue;
      font-weight: bold;
    }
  }
</style>


<template>
  <r-forum-layout class="forums">

    <!-- recent -->
    <r-forum-recent></r-forum-recent>

    <!-- forums -->
    <div v-for="category in categories" :key="category.id">
      <r-forum-table>
        <template slot="title">{{category.name}}</template>
        <template slot="head">
          <tr>
            <th class="w-4/6">Forum</th>
            <th class="w-4">Topics</th>
            <th class="w-4">Posts</th>
            <th class="">Last post</th>
          </tr>
        </template>
        <template slot="body">
          <tr v-for="forum in category.forums" :key="forum.id">
            <td>
              <div class="forum-name">
                <router-link :to="{ name: 'forum', params: { id: forum.id }}">
                  {{forum.name}}
                </router-link>
              </div>
              <div>{{forum.description}}</div>
            </td>
            <td class="text-center">
              {{forum.topic_count}}
            </td>
            <td class="text-center">
              {{forum.post_count}}
            </td>
            <td>
              <div class="post-date">
                <router-link :to="{name: 'topic', hash: `#${forum.last_post.id}`,
                                   params: { id: forum.last_post.topic },
                                   query: { page: Math.floor(forum.last_post.topic_post_count / 10) }}">
                  {{forum.last_post.created | date}}
                </router-link>
              </div>
              <div>by {{forum.last_post.author}}</div>
            </td>
          </tr>
        </template>
      </r-forum-table>
    </div>

  </r-forum-layout>
</template>


<script>
import { sortBy } from 'lodash';
import api from '@/api';
import models from '@/models';
import RForumLayout from './r-forum-layout';
import RForumTable from './r-forum-table';
import RForumRecent from './r-forum-recent';


export default {
  name: 'forums',
  components: {
    RForumLayout,
    RForumTable,
    RForumRecent,
  },
  computed: {
    categories() {
      const categories = {};
      this.forums.forEach((forum) => {
        if (!(forum.category.name in categories)) {
          categories[forum.category.name] = {
            id: forum.category.id,
            name: forum.category.name,
            position: forum.category.position,
            forums: [],
          };
        }
        categories[forum.category.name].forums.push(forum);
      });
      return sortBy(Object.values(categories), ['position', 'name']);
    },
    forums() {
      return models.Forum.query().orderBy('position').all();
    },
  },
  methods: {
  },
  created() {
    api.forums.load();
  },
};
</script>

import AdminTraining from '@/models/admin-training';
import restify from '@/extensions/restify';

const api = restify(AdminTraining, '/admin/trainings');

export default {
  load: api.load,
  get: api.get,
  update: api.update,
};

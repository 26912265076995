<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-create-user-form {
  }
</style>


<template>
  <b-form @submit.prevent="submit">
    <!-- username -->
    <b-form-group label="Username">
      <b-form-input v-model="form.username" :state="states.username" type="text"/>
      <b-form-invalid-feedback v-if="errors.username === 'expected_unique'">
        The username you have selected is not availiable.
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-else>
        Please enter a valid username.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- display name -->
    <b-form-group label="Display Name">
      <b-form-input v-model="form.display_name" :state="states.display_name" type="text"/>
      <b-form-invalid-feedback v-show="errors.display_name">
        Please enter a valid display name.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- email -->
    <b-form-group label="Email">
      <b-form-input v-model="form.email" type="text" :state="states.email"/>
      <b-form-invalid-feedback v-if="errors.email === 'expected_unique'">
        The email address you have entered is not availiable.
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-else>
        Please enter a valid email address.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- password -->
    <b-form-group label="Password">
      <b-form-input v-model="form.password" :state="states.password" type="password"/>
      <b-form-invalid-feedback>
        Please enter a password with at least 8 characters including numbers,
        lowercase letters and uppercase letters.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <b-form-group>
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button variant="warning" class="mr-2 text-white" @click.prevent="reset">
        Clear
      </b-button>
    </b-form-group>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';


export default {
  name: 'r-create-user-form',
  mixins: [form],
  methods: {
    async submitted() {
      await api.admin.users.create(this.form);
      this.$router.push({ name: 'admin-users' });
      api.alerts.success('Successfully create user.');
    },
    async rejected() {
      api.alerts.error('Unable create user.');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-demo {
    margin-bottom: 40px;
    .title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: bold;
      color: $color-gray-8;
    }
    .description {
      margin-bottom: 20px;
      font-size: 16px;
      color: $color-gray-8;
      font-weight: light;
    }
    .content {
      padding: 50px;
      position: relative;
      border: 1px solid $color-gray-3;
      border-radius: 7px;
      background: $color-gray-1;

      .bg-toggle {
        margin: 0;
        width: 25px;
        border: 2px solid $color-gray-5;
        position: absolute;
        top: 10px;
        border-radius: 5px;
        right: 10px;
        height: 25px;
        background: white;
        cursor: pointer;
        &:hover {
          border: 2px solid  $color-blue;
        }
      }
    }
  }
</style>

<template>
  <div class="r-demo ">
    <div class="title">
      # <slot name="title"></slot>
    </div>
    <div class="description">
      <slot name="description"></slot>
    </div>
    <div class="content c-box" :style="{background}">
      <div class="bg-toggle" :style="{background: next}" @click="rotate">
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
const backgrounds = {
  light: '#f8f8f8',
  mid: '#dddddd',
  dark: '#333333',
};
const options = Object.keys(backgrounds);

export default {
  name: 'r-demo',
  props: {
    bg: {
      default: options[0],
    },
  },
  data() {
    return {
      index: backgrounds[this.bg] ? options.indexOf(this.bg) : 0,
      options,
      backgrounds,
    };
  },
  methods: {
    rotate() {
      this.index = (this.index + 1) % options.length;
    },
  },
  computed: {
    next() { return backgrounds[options[(this.index + 1) % options.length]]; },
    background() { return backgrounds[options[this.index]]; },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-notfound {
    text-align: center;
  }
</style>

<template>
  <r-focus-layout class="r-notfound">
    Not Found
  </r-focus-layout>
</template>

<script>
export default {
  name: 'notfound',
  components: {
  },
};
</script>

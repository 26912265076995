import Product from '@/models/product';
import rest from './rest';

async function load() {
  const products = await rest.get('/products');
  Product.create({ data: Object.values(products) });
}

export default {
  load,
};

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-billing-fields {
    .cvv {
      padding: 0px 10px;
      font-size: 13px;
    }
  }
</style>


<template>
  <div class="r-billing-fields">
    <!-- first name -->
    <b-form-group label="First name">
      <b-form-input v-model="form.first" type="text" :state="states.first"/>
      <b-form-invalid-feedback>
        Please enter your first name.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- last name -->
    <b-form-group label="Last name">
      <b-form-input v-model="form.last" type="text" :state="states.last"/>
      <b-form-invalid-feedback>
        Please enter your last name.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- card number -->
    <b-form-group label="Card number">
      <b-form-input v-model="form.number" type="text" :state="states.number"/>
      <b-form-invalid-feedback>
        Please enter a valid credit card number.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- month -->
    <b-form-group label="Expiration">
      <div class="grid grid-cols-2 gap-2">
        <div>
          <b-form-select v-model="form.month" :options="months" :state="states.month"/>
          <b-form-invalid-feedback>
            Please select a valid expiration month.
          </b-form-invalid-feedback>
        </div>
        <div>
          <b-form-select v-model="form.year" :options="years" :state="states.year"/>
          <b-form-invalid-feedback>
            Please select a valid expiration year.
          </b-form-invalid-feedback>
        </div>
      </div>
    </b-form-group>

    <!-- cvv number -->
    <b-form-group label="CVV number">
      <div class="grid grid-cols-4">
        <div class="col-span-1">
          <b-form-input v-model="form.cvv" type="text" :state="states.cvv"/>
          <b-form-invalid-feedback>
            Please select a valid cvv number.
          </b-form-invalid-feedback>
        </div>
        <div class="col-span-3 cvv">
          The CVV number is a three or four digit number found on the front or back of your credit card.
        </div>
      </div>
      <b-form-invalid-feedback>
        Please enter a valid credit cvv number.
      </b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>


<script>
import { range } from 'lodash';
import store from '@/store';


export default {
  name: 'r-billing-fields',
  props: ['form', 'errors', 'states'],
  computed: {
    months() {
      return range(1, 13);
    },
    years() {
      const year = new Date().getFullYear();
      return range(year, year + 51);
    },
    profile: store.getter('profile'),
  },
};
</script>

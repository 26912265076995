<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-status-form {

  }
</style>


<template>
  <b-form class="r-status-form" @submit.prevent="submit">
    <label>Success</label>
    <b-form-group>
      <b-form-textarea rows="3" max-rows="6" v-model="form.body" :state="states.body"
                       placeholder="Type your success here..."/>
      <b-form-invalid-feedback>
        Please enter a status update.
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';


export default {
  name: 'r-status-form',
  mixins: [form],
  methods: {
    async submitted() {
      await api.statuses.create(this.form);
      api.alerts.success('Your status has been updated.');
    },
    async reset() {
      this.form = {
        private: true,
      };
      this.errors = {};
    },
  },
};
</script>

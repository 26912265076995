<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-focus-layout>
    <r-logo-card>
      <template #title>Update password</template>
      <template #content><r-password-form/></template>
      <template #footer>
        <router-link to="signin">Sigin in</router-link>
        <span>|</span>
        <router-link to="forgot">Forgot password</router-link>
        <span>|</span>
        <a href="" @click.prevent="$refs.support.show()">Support</a>
      </template>
    </r-logo-card>
  </r-focus-layout>
</template>


<script>
import RPasswordForm from '@/components/forms/r-reset-password-form';

export default {
  name: 'reset',
  components: {
    RPasswordForm,
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-editor-button {
    color: black;
    font-size: 20px;
    padding: 3px 5px;
    cursor: pointer;
    &.disabled {
      color: $color-gray-5;
      pointer-events: none;
    }
    &.active {
      color: $color-blue-bright;
    }
    &:hover {
      color: $color-blue-bright;
    }
  }
</style>

<template>
  <div @click="$emit('click')" class='r-editor-button c-box flex-limit'
       :class="{ 'active': active, 'disabled': disabled}"
       :style="{ width: width + 'px' }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'r-editor-button',
  props: {
    disabled: { default: false },
    active: { default: false },
    width: { default: 30 },
  },
  methods: {
  },
  computed: {
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-model-list {
    overflow-y: auto;
    .item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>


<template>
  <div class="r-model-list v-box">
    <r-shade-card class="r-model-list flex-grow">
      <div class="item" v-for="item in items" :key="item.id">
        <a href="" @click.prevent="$emit('select', item)">{{item[title] || 'No title'}}</a>
      </div>
    </r-shade-card>
    <r-paginator ref="paginator" @changed="load" :limit="limit" :count="items.length" :manageUrl="false"/>
  </div>
</template>


<script>
export default {
  name: 'r-model-list',
  props: ['model', 'load', 'title', 'limit'],
  created() {
    this.reset();
  },
  computed: {
    items() { return this.model.query().orderBy('created_at', 'desc').all(); },
  },
  methods: {
    reset() {
      if (this.$refs.paginator) {
        this.$refs.paginator.first();
      }
      this.load(0);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-ipsum {
  }
</style>

<template>
  <div class="r-ipsum">
    <p>
      {{ipsum}}
    </p>
  </div>
</template>

<script>
const words = `unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam
  eaque ipsa quae ab illo inventore veritatis et quasi archi tecto beatae vitae dicta sunt explicabo nemo
  enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit sed quia consequuntur magni met
  consectetur adipisci velit sed quia non numquam eius modi tempora incidunt ut labore et statem`.split(/\s+/);

export default {
  name: 'r-ipsum',
  props: {
    length: {
      default: 50,
    },
    capitalize: {
      default: true,
    },
  },
  computed: {
    ipsum() {
      const script = [];
      for (let i = 0; i < this.length; i += 1) {
        script.push(words[Math.floor(Math.random() * (words.length - 1))]);
      }
      if (this.capitalize) {
        script[0] = script[0].charAt(0).toUpperCase() + script[0].slice(1);
      }
      return script.join(' ');
    },
  },
};
</script>

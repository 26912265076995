<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="building"/>Organizations</template>
    <template slot="buttons">
      <router-link :to="{ name: 'admin-referrer', params: {id: 'new'} }">
        <button class="admin-btn">Add Organization</button>
      </router-link>
    </template>
    <div class="h-3 flex-shrink-0"></div>

    <div class="h-3 flex-shrink-0"></div>
    <b-table-lite striped bordered :items="referrers">
      <template #cell(name)="data">
        <router-link :to="{ name: 'admin-referrer', params: {id: data.value.id} }">
          {{ data.value.name }}
        </router-link>
      </template>
    </b-table-lite>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';

export default {
  name: 'a-referrers',
  methods: {
    async load() {
      await api.adminReferrers.load();
    },
  },
  computed: {
    referrers() {
      return models.AdminReferrer.query().orderBy('id', 'asc').all()
        .map((r) => ({
          name: { name: r.name, id: r.id },
          code: r.code,
          coaches: r.coaches.map((c) => c.username).join(', '),
        }));
    },
  },
  data() {
    return {
      limit: 25,
      term: '',
    };
  },
  async created() {
    await this.load();
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-status-form {
  }
</style>


<template>
  <div>
    <div class="h-box align-items-center">
      <b-icon icon="dash-circle-fill" variant="danger" class="mr-2"></b-icon>
      <r-indicator-range class="flex-grow" @input="submit" v-model="form.setback"/>
    </div>
    <div class="h-box align-items-center">
      <b-icon icon="check-circle-fill" class="mr-2" variant="success"></b-icon>
      <r-indicator-range class="flex-grow" @input="submit" v-model="form.routine"/>
    </div>
    <div class="h-box align-items-center">
      <b-icon icon="exclamation-circle-fill" class="mr-2" variant="warning"></b-icon>
      <r-indicator-range class="flex-grow" @input="submit" v-model="form.trigger"/>
    </div>
  </div>
</template>


<script>
import api from '@/api';

export default {
  name: 'r-status-form',
  props: ['date'],
  data() {
    return {
      indicator: null,
      form: {
        setback: 0,
        routine: 0,
        trigger: 0,
      },
    };
  },
  methods: {
    async submit() {
      try {
        await api.indicators.ensure({ date: this.date, ...this.form });
        api.alerts.success('Calendar item saved.');
      } catch (error) {
        await api.alerts.error('Unable to save calendar item.');
        throw error;
      }
    },
    async reset() {
      if (this.indicator) {
        this.form = {
          setback: this.indicator.setback,
          routine: this.indicator.routine,
          trigger: this.indicator.trigger,
        };
      } else {
        this.form = {};
      }
      this.errors = {};
    },
  },
  async created() {
    try {
      if (this.date) {
        this.indicator = await api.indicators.get(this.date);
      }
    } catch (error) {
      if (error instanceof api.ApiError) return;
      throw error;
    }
    this.reset();
  },
};
</script>

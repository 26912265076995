<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  $border-radius: 10px;
  .r-image-button {
    border: 1px solid $color-gray-4;
    padding:5px;
    border-radius: $border-radius;
    background: $color-gray-3;
    box-shadow: 0px 0px 5px 2px $color-gray-4;
    cursor: pointer;
    .bordered {
      border-radius: $border-radius;
    }
    img {
      object-fit: cover;
      object-position: 100% 0;
      border: 1px solid $color-gray-4;
      border-radius: $border-radius $border-radius 0px 0px;
      width: 150px;
      height: 100px;
    }
    .label {
      text-align: center;
      color: $color-gray-9;
      font-weight: 400;
      padding: 3px;
      border-radius: 0px 0px $border-radius $border-radius;
    }
  }
</style>


<template>
  <div class="r-image-button">
    <div class="bordered">
      <img :src="require('@/assets/images/' + src)">
      <div class="label">{{label}}</div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'r-image-button',
  props: ['src', 'label'],
};
</script>

import Article from '@/models/article';
import restify from '@/extensions/restify';
import rest from './rest';

const api = restify(Article, '/articles');
const adminApi = restify(Article, '/admin/articles');

async function update(id, fields) {
  const payload = { ...fields };
  payload.thumbnail = null;
  Object.keys(payload).forEach((k) => payload[k] == null && delete payload[k]);
  let article = await adminApi.update(id, payload);
  if (fields.thumbnail) {
    article = await rest.upload(
      `/admin/articles/${id}/thumbnail`,
      { thumbnail: fields.thumbnail },
    );
  }
  return article;
}

async function create(fields) {
  const payload = { ...fields };
  payload.thumbnail = null;
  Object.keys(payload).forEach((k) => payload[k] == null && delete payload[k]);
  let article = await adminApi.create(payload);
  if (fields.thumbnail) {
    article = await rest.upload(
      `/admin/articles/${article.id}/thumbnail`,
      { thumbnail: fields.thumbnail },
    );
  }
  return article;
}

export default {
  load: api.load,
  get: api.get,
  update,
  create,
};

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .a-create-user {
    .r-admin-card {
      margin-bottom: 30px;
    }
    .r-admin-card:last-of-type {
      margin-bottom: 0px;
    }
    .billing {
      weight: bold;
      color: blue;
    }
    hr {
      margin: 10px 0px;
    }
    ul {
      list-style-type: disc !important;
      li {
        margin-left: 30px;
      }
    }
  }
</style>


<template>
  <r-admin-layout class="a-create-user">
    <!-- title -->
    <template slot="title"><b-icon icon="person-fill"/>Edit User</template>

    <!-- form -->
    <r-admin-card title="User Information">
      <r-edit-user-form v-if="user" :user="user"/>
    </r-admin-card>

    <!-- permission details -->
    <r-admin-card title="User Permission Details">
      <p v-if="user && user.student" style="color:green;">User subscription is active.</p>
      <p v-else style="color:red;">User subscription is not active.</p>

      <!-- subscriptions -->
      <hr>
      <p>Accessible Products/Subscriptions:</p>
      <ul v-if="user && user.current_products && user.current_products.length">
        <li v-for="p in user.current_products" :key="p.id">
          {{p.name}} -
          <span v-if="p.expired" style="color:red;">
            Expired{{ p.expiration | datetime }}
          </span>
          <span v-else-if="p.expiration" style="color:green;">
            Expires {{ p.expiration | datetime }}
          </span>
          <span v-else style="color:green;">
            Does not expire
          </span>
        </li>
      </ul>
      <p v-else style="color:blue;">None.</p>

      <!-- courses -->
      <hr>
      <p>Accessible Content:</p>
      <ul v-if="user && user.current_courses && user.current_courses.length">
        <li v-for="c in user.current_courses" :key="c.id">{{c.name}}</li>
      </ul>
      <p v-else style="color:blue;">None.</p>
    </r-admin-card>

    <r-admin-card title="Recent Payment Details">
      <div>Current billing info: <span class="billing">{{billing}}</span></div>
      <br>
      <b-table-lite striped bordered :items="payments" :fields="[
        'transactionId',
        'creditCard',
        'product',
        'amount',
        'status',
        'created',
        'begins',
        'expires']">
        <template #cell(transactionId)="data">
          <router-link :to="{ name: 'admin-payment', params: {id: data.item.id} }">
            {{ data.item.transactionId }}
          </router-link>
        </template>
      </b-table-lite>
    </r-admin-card>

  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import REditUserForm from '@/components/forms/r-edit-user-form';


export default {
  name: 'a-create-user',
  components: {
    REditUserForm,
  },
  methods: {
    async load() {
      await api.admin.users.get(this.$route.params.id);
    },
    success() {
    },
  },
  computed: {
    user() {
      return models.admin.User.find(this.$route.params.id);
    },
    billing() {
      if (!this.user || !this.user.billing || !this.user.billing.four) {
        return 'None';
      }
      return `${this.user.billing.four} ${this.user.billing.month}/${this.user.billing.year}`;
    },
    payments() {
      if (!this.user) {
        return [];
      }
      return this.user.payments.map((p) => ({
        id: p.id,
        transactionId: p.transaction_id || 'None',
        creditCard: p.card || 'None',
        product: p.product_name,
        amount: p.amount,
        status: p.status,
        created: p.created_at.substring(0, 10),
        begins: p.begin_date.substring(0, 10),
        expires: (p.expire_date && p.expire_date.substring(0, 10)) || 'None',
      }));
    },
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

import store from '@/store';
import Vue from 'vue';


const ALERT_DURATION = 4000;

function create(level, message) {
  const timestamp = new Date().getTime();
  const alerts = store.get('alerts');
  Vue.set(alerts, message, { message, level, timestamp });
  store.set('alerts', alerts);
  setTimeout(() => { expire(message, timestamp); }, ALERT_DURATION);
}

function dismiss(message) {
  const alerts = store.get('alerts');
  Vue.delete(alerts, message);
  store.set('alerts', alerts);
}

// remove message only if timestamp matches (so an identical more recent alert doesn't get dismissed)
function expire(message, timestamp) {
  const alerts = store.get('alerts');
  const alert = alerts[message];
  if (alert !== undefined && alert.timestamp === timestamp) {
    dismiss(message);
  }
}

function error(message) {
  create('danger', message);
}

function warning(message) {
  create('warning', message);
}

function info(message) {
  create('info', message);
}

function success(message) {
  create('success', message);
}


export default {
  error, warning, info, success, create, dismiss,
};

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .conversation {
    .vertical-container {
      min-height: 100%;
    }
    .disabled {
      color: $color-gray-7 !important;
      text-decoration: none !important;
      cursor: default;
    }
    .reply {
      flex-grow: 1;
    }
    .details {
      flex-grow: 0;
      padding-left: 20px;
      font-size: 14px;
      width: 260px;
      overflow: auto;
    }
    .compose {
      align-items: stretch;
    }
    .name {
      font-size: 18px;
      padding-bottom: 10px;
    }
    .r-connection-details {
      line-height: 24px;
    }
  }
</style>


<template>
  <r-program-layout class="conversation">

    <div class="vertical-container v-box flex-grow">

      <!-- search -->
      <div v-if="profile.staff || profile.admin" class="h-box flex-limit">
        <b-button @click="$router.back()" variant="secondary">
          <b-icon icon="arrow-left"/>
        </b-button>
        <div class="flex-spacer"></div>
        <!-- <b-button @click="$refs.reply.$el.scrollIntoView()" variant="secondary">
          Reply <b-icon icon="arrow-down"/>
        </b-button> -->
      </div>

      <!-- compose message -->
      <div class="h-box compose">
        <r-shade-card ref="reply" class="reply">
          <r-message-form :recipient="recipient" @success="sent" />
        </r-shade-card>
        <div class="v-box details" v-if="(profile.staff || profile.admin) && connection" >
          <r-border-card>
            <div class="name">{{connection.display_name}}</div>
            <r-connection-details :connection="connection"/>
          </r-border-card>
        </div>
      </div>


      <!-- messages -->
      <div class="flex-grow">
        <r-message v-for="m in messages" :key="m.id" :message="m" :inbox="profile.id"/>
      </div>

      <!-- pages -->
      <div class="h-box flex-limit pt-4">
        <div>
          <b-button-group class="mx-1">
            <b-button :disabled="page === 0" @click="prev">
              &lsaquo;
            </b-button>
          </b-button-group>
        </div>
        <div class="flex-spacer"></div>
        <b-button-group class="mx-1">
          <b-button :disabled="messages.length < limit" @click="next">
            &rsaquo;
          </b-button>
        </b-button-group>
      </div>

    </div>
  </r-program-layout>
</template>


<script>
import { get } from 'vuex-pathify';
import { debounce } from 'lodash';
import api from '@/api';
import models from '@/models';
import RMessageForm from '@/components/forms/r-message-form';
import RMessage from './r-message';
import RConnectionDetails from '../messages/r-connection-details';

export default {
  name: 'conversation',
  components: {
    RMessage,
    RMessageForm,
    RConnectionDetails,
  },
  data() {
    return {
      term: '',
      page: 0,
      limit: 5,
      contacts: [],
    };
  },
  computed: {
    profile: get('profile'),
    messages: () => models.Message.query().orderBy('created_at', 'desc').all(),
    recipient() { return this.$route.params.id; },
    connection() { return models.Connection.find(this.recipient); },
  },
  methods: {
    prev() {
      this.page = Math.max(this.page - 1, 0);
      this.search();
    },
    next() {
      this.page += 1;
      this.search();
    },
    search: debounce(function () {
      this.offset = this.page * this.limit;
      api.messages.load({
        query: this.term,
        limit: this.limit,
        offset: this.offset,
        participant: this.$route.params.id,
      });
    }, 600),
    sent() {
      this.page = 0;
      this.search();
      api.connections.get(this.$route.params.id);
    },
  },
  async created() {
    api.connections.get(this.$route.params.id);
    this.search();
  },
  destroyed() {
    models.Message.deleteAll();
  },
};
</script>

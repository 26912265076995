import AdminCoupon from '@/models/admin-coupon';
import restify from '@/extensions/restify';

const api = restify(AdminCoupon, '/admin/coupons');

export default {
  load: api.load,
  get: api.get,
  update: api.update,
  create: api.create,
  remove: api.remove,
};

import Vue from 'vue';

import RFocusLayout from '@/components/layouts/r-focus-layout';
import RAdminLayout from '@/components/layouts/r-admin-layout';
import RProgramLayout from '@/components/layouts/program-layout/r-program-layout';
import RBasicLayout from '@/components/layouts/r-basic-layout';

import RStatusCard from '@/components/program/r-status-card';
import RModelList from '@/components/program/r-model-list';

import RCard from '@/components/ui/cards/r-card';
import RLogoCard from '@/components/ui/cards/r-logo-card';
import RBorderCard from '@/components/ui/cards/r-border-card';
import RShadeCard from '@/components/ui/cards/r-shade-card';
import RTabCard from '@/components/ui/cards/r-tab-card';
import RAdminCard from '@/components/ui/cards/r-admin-card';
import RAdminTable from '@/components/ui/r-admin-table';

import RCleanModal from '@/components/ui/modals/r-clean-modal';
import RConfirmModal from '@/components/ui/modals/r-confirm-modal';

import RAspectRatioBox from '@/components/ui/r-aspect-ratio-box';
import RPaginator from '@/components/ui/r-paginator';
import RIdenticon from '@/components/ui/r-identicon';
import RImageButton from '@/components/ui/r-image-button';
import RVideo from '@/components/ui/r-video';
import RAudio from '@/components/ui/r-audio';
import RAdminSearch from '@/components/ui/inputs/r-admin-search';
import RAdminButton from '@/components/ui/r-admin-button';

import RIndicatorRange from '@/components/ui/inputs/r-indicator-range';
import REditorInput from '@/components/ui/inputs/editor/r-editor-input';

import RLoading from '@/components/other/r-loading';
import RAlerts from '@/components/other/r-alerts';
import RIpsum from '@/components/other/r-ipsum';


function register() {
  [
    // layouts
    RFocusLayout,
    RAdminLayout,
    RProgramLayout,
    RBasicLayout,

    // program
    RStatusCard,
    RModelList,

    // cards
    RCard,
    RLogoCard,
    RBorderCard,
    RShadeCard,
    RTabCard,
    RAdminCard,

    // modals
    RCleanModal,
    RConfirmModal,

    // ui
    RAspectRatioBox,
    RPaginator,
    RIdenticon,
    RImageButton,
    RVideo,
    RAudio,
    RAdminTable,
    RAdminButton,

    // inputs
    RIndicatorRange,
    REditorInput,
    RAdminSearch,

    // other
    RLoading,
    RAlerts,
    RIpsum,

  ].map((component) => Vue.component(component.name, component));
}

export default { register };

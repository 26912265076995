<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-program-layout>

    <!-- update subscription -->
    <div class="grid grid-cols-4">
      <div><label>Subscription</label></div>
      <div class="col-span-3">
        <!-- subscription status -->
        <p v-if="!profile.active">
          Your subscription is currently <span class="text-danger">paused</span>.
        </p>
        <p v-else>
          Your subscription is currently <span class="text-success">active </span>
          <template v-if="profile.billing.four">and will be renewed on </template>
          <template v-else>through </template>
          <span class="text-success">{{profile.expiration | date}}</span>.
        </p>
        <b-button variant="primary" @click.prevent="$refs.subscription.show()">
          Update Subcription
        </b-button>
        <!-- update subscription -->
        <r-clean-modal ref="subscription" size="lg">
          <template slot="title">Update Subscription</template>
          <r-subscription-form @success="$refs.subscription.hide()"/>
        </r-clean-modal>
      </div>
    </div>
    <hr class="mt-4 mb-4">

    <!-- update payment method -->
    <div class="grid grid-cols-4">
      <div><label>Payment Method</label></div>
      <div class="col-span-3">
        <!-- card information -->
        <p v-if="profile.billing.four">
          The card you currently have on file ends in
          <span class="text-success">{{profile.billing.four}}</span>
          and expires on
          <span class="text-success">{{profile.billing.month}}/{{profile.billing.year}}</span>.
        </p>
        <p v-else>We currently do not have an active credit card for you on file.</p>
        <!-- update pyament info -->
        <b-button variant="primary" @click.prevent="$refs.payment.show()">
          Update Card
        </b-button>
        <r-clean-modal ref="payment" size="lg">
          <template slot="title">Update Card</template>
          <r-billing-form @success="$refs.payment.hide()"/>
        </r-clean-modal>
      </div>
    </div>
    <hr class="mt-4 mb-4">

    <!-- cancel subscription -->
    <div class="grid grid-cols-4">
      <div><label>Cancel Subscription</label></div>
      <div class="col-span-3">
        <p>Click below to remove your billing information and stop future charges.
          Your subscription will be not be automatically renewed unless you add your billing information again.</p>
        <b-button variant="danger" @click.prevent="$refs.cancel.show()">
          Cancel Subscription
        </b-button>
        <r-confirm-modal ref="cancel" @confirm="cancel"/>
      </div>
    </div>
    <hr class="mt-4 mb-4">

    <!-- recent payments -->
    <div class="grid grid-cols-4">
      <div><label>Recent Payments</label></div>
      <div class="col-span-3">
        <r-payments/>
      </div>
    </div>
  </r-program-layout>
</template>


<script>
import api from '@/api';
import store from '@/store';
import RSubscriptionForm from '@/components/forms/r-subscription-form';
import RBillingForm from '@/components/forms/r-billing-form';
import RPayments from './r-payments';


export default {
  name: 'account',
  components: {
    RSubscriptionForm,
    RBillingForm,
    RPayments,
  },
  computed: {
    profile: store.getter('profile'),
  },
  methods: {
    async cancel() {
      try {
        await api.billing.cancel();
        await api.alerts.success('Your subscription has been cancelled.');
      } catch (error) {
        if (error instanceof api.ApiError) {
          await api.alerts.error('There was a problem cancelling your subscription, please try again.');
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="slash-circle-fill"/>Forum Bans</template>
    <r-admin-card title="Ban Information">
      <r-ban-form v-if="loaded" :onSubmit="submitted" :onRemove="removed" :ban="ban" />
    </r-admin-card>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RBanForm from '@/components/forms/r-ban-form';

export default {
  name: 'a-ban',
  components: {
    RBanForm,
  },
  methods: {
    async load() {
      this.newBan = this.$route.params.id === 'new';
      if (!this.newBan) {
        this.ban = models.AdminBan.find(this.$route.params.id);
        if (!this.ban) {
          this.ban = await api.adminBans.get(this.$route.params.id);
        }
      }
      this.loaded = true;
    },
    async submitted(form) {
      if (this.newBan) {
        this.ban = await api.adminBans.create(form);
        this.$router.push({ name: 'admin-bans' });
        api.alerts.success('Ban created.');
      } else {
        this.ban = await api.adminBans.update(form.id, form);
        api.alerts.success('Ban updated.');
      }
    },
    async removed(form) {
      await api.adminBans.remove(form);
      this.$router.push({ name: 'admin-bans' });
    },
  },
  data() {
    return {
      ban: {},
      newBan: false,
      loaded: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

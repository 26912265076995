<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-identicon {
    & > img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
</style>


<template>
  <span class="r-identicon">
    <img :src="src || require('@/assets/images/reclaim/mystery.png')"
         :style="{height: size, width: size}">
  </span>
</template>


<script>
export default {
  name: 'r-identicon',
  props: {
    src: {
      type: String,
    },
    size: {
      type: String,
      default: '32px',
    },
  },
};
</script>

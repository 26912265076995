import rest from './rest';


async function get(student) {
  return rest.get(`/notes/${student}`);
}

async function set(student, body) {
  return rest.put(`/notes/${student}`, { body });
}


export default {
  get,
  set,
};

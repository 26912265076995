import rest from './rest';
import ApiError from './api-error';


async function forgot(email) {
  await rest.post('/password/forgot', { email });
}

async function validate(uid, token) {
  try {
    await rest.put('/password/check-token', { uid, token });
    return true;
  } catch (error) {
    if (error instanceof ApiError) return false;
    throw error;
  }
}

async function update(uid, token, password) {
  await rest.put('/password/update', { uid, token, password });
}


export default {
  forgot, validate, update,
};

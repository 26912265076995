import { Model } from '@vuex-orm/core';

export default class AdminProfile extends Model {
  static entity = 'adminProfiles'

  static fields() {
    return {
      id: this.attr(null),
      user: this.attr(null),
      username: this.attr(''),
    };
  }
}

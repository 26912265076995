<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-home {

  }
</style>

<template>
  <r-program-layout class="r-home">
    <r-border-card>
      <r-creed-form class="inner-box"/>
    </r-border-card>
    <r-border-card>
      <r-status-form class="inner-box"/>
    </r-border-card>
    <r-status-card :status="status" v-for="status in statuses" :key="status.id"/>
  </r-program-layout>
</template>

<script>
import api from '@/api';
import store from '@/store';
import models from '@/models';
import RCreedForm from '@/components/forms/r-creed-form';
import RStatusForm from '@/components/forms/r-status-form';


export default {
  name: 'home',
  components: {
    RCreedForm,
    RStatusForm,
  },
  computed: {
    profile: store.getter('profile'),
    statuses: () => models.Status.query().orderBy('created_at', 'desc').get(),
  },
  created() {
    api.statuses.load();
  },
};
</script>

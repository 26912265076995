<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-video {
    width: 100%;
    audio {
      width: 100%;
    }
  }
</style>


<template>
  <div class="r-video">
    <audio controls v-if="audio && !poster">
      <source :src="src">
    </audio>
    <video
      v-else
      ref="video"
      class="img-responsive video-js vjs-theme-sea"
      controls
      preload="auto"
      :poster="poster">
      <source :src="src">
    </video>
  </div>
</template>


<script>
import videojs from 'video.js';

export default {
  name: 'r-video',
  props: {
    src: null,
    poster: null,
    autoplay: {
      default: false,
    },
    audio: {
      default: false,
    },
  },
  data() {
    return {
      videojs: null,
    };
  },
  watch: {
    src() {
      if (this.videojs) {
        this.videojs.src(this.src);
      }
    },
  },
  methods: {
    load() {
      if (this.$refs.video) {
        this.videojs = videojs(this.$refs.video, {
          fluid: true,
        });
      }
    },
  },
  mounted() {
    this.load();
  },
};
</script>

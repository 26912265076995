<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-section-box {
      position: relative;
      border: 1px solid $color-gray-5;
      padding: 10px;
      border-radius: 20px;
      box-shadow: 0px 0px 5px 2px $color-gray-4;
      &.blue {
        background: linear-gradient(180deg, rgba(193,205,218,1) 0%, rgba(166,180,191,1) 50%, rgba(205,218,229,1) 100%);
      }
      &.grey {
        background: linear-gradient(180deg, hsl(211, 0%, 81%) 0%, hsl(206, 0%, 70%) 50%, hsl(208, 0%, 85%) 100%);
      }
      .arrow-right {
        z-index: 99;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(-25%, -50%);
      }
      .arrow-down {
        z-index: 99;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%, -25%);
      }
  }
</style>


<template>
  <div class="r-section-box" :class="theme">
    <slot></slot>
    <img v-show="arrows.includes('right')" class="arrow-right" src="@/assets/images/reclaim/training/arrow_right.png">
    <img v-show="arrows.includes('down')" class="arrow-down" src="@/assets/images/reclaim/training/arrow_down.png">
  </div>
</template>


<script>
export default {
  name: 'r-section-box',
  props: {
    src: null,
    theme: {
      default: 'blue',
    },
    arrows: {
      default: () => [],
    },
  },
};
</script>

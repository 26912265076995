import { render, staticRenderFns } from "./r-payments.vue?vue&type=template&id=f98472aa&scoped=true&"
import script from "./r-payments.vue?vue&type=script&lang=js&"
export * from "./r-payments.vue?vue&type=script&lang=js&"
import style0 from "./r-payments.vue?vue&type=style&index=0&id=f98472aa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f98472aa",
  null
  
)

export default component.exports
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  /deep/ input[type=range].danger {
    &::-webkit-slider-runnable-track {
      background: $color-red-bright;
    }
    &:focus::-webkit-slider-runnable-track {
      background: $color-red-bright;
    }
    &::-moz-range-track {
      background: $color-red-bright;
    }
    &::-ms-track {
      background: $color-red-bright;
    }
  }
  /deep/ input[type=range].warning {
    &::-webkit-slider-runnable-track {
      background: $color-yellow-bright;
    }
    &:focus::-webkit-slider-runnable-track {
      background: $color-yellow-bright;
    }
    &::-moz-range-track {
      background: $color-yellow-bright;
    }
    &::-ms-track {
      background: $color-yellow-bright;
    }
  }
  /deep/ input[type=range].success {
    &::-webkit-slider-runnable-track {
      background: $color-green-bright;
    }
    &:focus::-webkit-slider-runnable-track {
      background: $color-green-bright;
    }
    &::-moz-range-track {
      background: $color-green-bright;
    }
    &::-ms-track {
      background: $color-green-bright;
    }
  }
</style>


<template>
  <div>
    <b-form-input
      :value="value"
      @input="$emit('input', $event)"
      :class="classes"
      type="range"
      min="0"
      max="3">
    </b-form-input>
  </div>
</template>


<script>
export default {
  /* eslint-disable eqeqeq */
  name: 'r-indicator-range',
  props: ['value'],
  computed: {
    classes() {
      if (this.value == 1) {
        return { success: true };
      } if (this.value == 2) {
        return { warning: true };
      } if (this.value == 3) {
        return { danger: true };
      }
      return {};
    },
  },
};
</script>

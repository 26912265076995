import Vue from 'vue';
import {
  BootstrapVue, IconsPlugin, ButtonPlugin, FormPlugin,
} from 'bootstrap-vue';

function register() {
  Vue.use(BootstrapVue);
  Vue.use(IconsPlugin);
  Vue.use(ButtonPlugin);
  Vue.use(FormPlugin);
}

export default { register };

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="cash"/>Payments</template>
    <template slot="buttons">
      <router-link :to="{ name: 'admin-payment', params: {id: 'new'} }">
        <button class="admin-btn">Add Payment</button>
      </router-link>
    </template>
    <div class="h-3 flex-shrink-0"></div>

    <!-- search -->
    <b-input-group>
      <b-form-input type="text" v-model="term" @input="search" placeholder="Search for Payment"></b-form-input>
      <b-input-group-append>
        <b-button @click="search" variant="primary"><b-icon icon="search"/></b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="h-3 flex-shrink-0"></div>
    <b-table-lite striped bordered :items="payments">
      <template #cell(transactionID)="data">
        <router-link :to="{ name: 'admin-payment', params: {id: data.value.id} }">
          {{ data.value.label }}
        </router-link>
      </template>
    </b-table-lite>
    <r-paginator @changed="load"/>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import { debounce } from 'lodash';

export default {
  name: 'a-payments',
  methods: {
    async load(page) {
      api.adminPayments.load({
        limit: this.limit,
        offset: this.limit * (page || 0),
        query: this.term,
      });
    },
    search: debounce(function () {
      if (this.term) {
        this.$router.replace({
          name: 'admin-payments',
          query: { query: this.term },
        });
      } else {
        this.$router.replace({ name: 'admin-payments' });
      }
      this.load(0);
    }, 600),
  },
  computed: {
    payments() {
      return models.Payment.query().orderBy('created_at', 'desc').all()
        .map((p) => ({
          transactionID: { label: p.transaction_id || 'None', id: p.id },
          card: p.card || 'None',
          username: p.username,
          product: p.product_name,
          amount: p.amount,
          status: p.status,
          created: p.created_at.substring(0, 10),
          begins: p.begin_date.substring(0, 10),
          expires: (p.expire_date && p.expire_date.substring(0, 10)) || 'None',
        }));
    },
  },
  data() {
    return {
      limit: 25,
      term: '',
    };
  },
  async created() {
    if (models.AdminProduct.query().all().length === 0) {
      await api.adminProducts.load();
    }
    if (models.AdminCoupon.query().all().length === 0) {
      await api.adminCoupons.load();
    }
    this.term = new URLSearchParams(window.location.search).get('query');
    await this.load();
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .topic {
    .author {
      font-weight: bold;
    }
    .author-cell {
      width: 200px;
    }
    .post-body {
      margin-bottom: 30px;
      word-break: break-word;
    }
  }
  .r-forum-table .forum-table td.border-bottom-none {
    border-bottom: none;
  }
  .r-forum-table .forum-table td.border-top-none {
    border-top: none;
  }
  .shaded {
    background: $color-gray-2;
  }
  /deep/ {
    .r-identicon {
      margin: 0px !important;
    }
  }
</style>


<template>
  <r-forum-layout class="topic" :searchbar="!recent">
    <!-- breadcrumb -->
    <r-forum-breadcrumb v-show="!search && !recent" :forum="forum" :topic="topic"/>

    <!-- paginator -->
    <r-forum-paginator @input="updateRoute" :value="page" :pages="pages"/>

    <!-- no results -->
    <div v-show="search && count === 0" class="c-box m-10">
      No posts were found matching your query.
    </div>

    <!-- no recent -->
    <div v-show="recent && count === 0" class="c-box m-10">
      No recent posts.
    </div>

    <!-- posts -->
    <r-forum-table :id="post.id" v-model="page" :pages="pages" v-for="post in posts" :key="post.id" class="pb-3">
      <template slot="title">
        <!-- search -->
        <router-link class="light" v-if="search"
                     :to="{name: 'topic', hash: `#${post.id}`,
                           params: { id: post.topic_id },
                           query: { page: Math.floor(post.position / 10) }}">
          {{post.created | datetime}}
        </router-link>
        <!-- recent -->
        <router-link class="light" v-else-if="recent"
                     :to="{name: 'topic', hash: `#${post.id}`,
                           params: { id: post.topic_id },
                           query: { page: Math.floor(post.position / 10) }}">
          {{post.created | datetime}}
        </router-link>
        <!-- topic -->
        <router-link class="light" v-else
                     :to="{path: `${$route.path}#${post.id}`, query: $route.query}">
          {{post.created | datetime}}
        </router-link>
      </template>
      <template slot="body">
        <tr>
          <td class="align-top border-bottom-none shaded author-cell">
            <div class="author">{{post.author}}</div>
            <r-identicon class="flex-limit justify-self-bottom pl-5 pr-5" :src="post.avatar" size="64px"/>
            <div>Posts: {{post.author_post_count}}</div>
          </td>
          <td class="align-top border-bottom-none">
            <div class="post-body prosemirror-rendered" v-html="post.body_html"></div>
          </td>
        </tr>
        <tr>
          <td class="align-top border-top-none shaded">
          </td>
          <td class="align-top border-top-none">
            <div class="text-right" v-if="!recent && !search">
              <a @click.prevent="quote(post)" href="#">Quote</a> |
              <span v-if='post.author_id == profile.id || profile.admin'>
                <a @click.prevent="remove(post.id)" href="#">
                  Delete
                </a> |
                <router-link :to="{name: 'edit-post', params: {id: post.id}, query: {page}}">
                  Edit
                </router-link> |
              </span>
              <a @click.prevent="report(post.id)" href="#">
                Report
              </a>
            </div>
            <div class="text-right" v-else>
              <router-link :to="{name: 'topic', hash: `#${post.id}`,
                                 params: { id: post.topic_id },
                                 query: { page: Math.floor(post.position / 10) }}">
                View Post
              </router-link>
            </div>
          </td>
        </tr>
      </template>
    </r-forum-table>

    <!-- paginator -->
    <r-forum-paginator @input="updateRoute" :value="page" :pages="pages"/>

    <!-- reply -->
    <r-forum-table class="r-forum-reply" v-show="!search && !recent">
      <template slot="title">
        New reply
      </template>
      <template slot="contents">
        <r-post-form ref="reply" :topic="$route.params.id" @success="load"/>
      </template>
    </r-forum-table>

    <!-- confirm delete modal -->
    <r-confirm-modal ref="remove" @confirm="confirmRemove">
      Are you sure you want to delete this post?
    </r-confirm-modal>

    <!-- confirm report modal -->
    <r-confirm-modal ref="report" @confirm="confirmReport">
      Are you sure you want to report this post as inappropriate?
    </r-confirm-modal>
  </r-forum-layout>
</template>


<script>
import api from '@/api';
import store from '@/store';
import models from '@/models';
import RPostForm from '@/components/forms/r-post-form';
import RForumTable from './r-forum-table';
import RForumLayout from './r-forum-layout';
import RForumPaginator from './r-forum-paginator';
import RForumBreadcrumb from './r-forum-breadcrumb';


export default {
  name: 'post',
  components: {
    RForumLayout,
    RForumTable,
    RForumPaginator,
    RForumBreadcrumb,
    RPostForm,
  },
  data() {
    return {
      topic: '',
      forum: {},
      pages: undefined,
      limit: 10,
      count: null,
      selected: null,
    };
  },
  computed: {
    profile: store.getter('profile'),
    search() {
      return this.$route.name === 'search';
    },
    recent() {
      return this.$route.name === 'recent';
    },
    posts() {
      if (this.recent || this.search) {
        return models.Post.query().orderBy('created', 'desc').all();
      }
      return models.Post.query().orderBy('created').all();
    },
    page() {
      return this.$route.query.page || 0;
    },
  },
  methods: {
    quote(post) {
      this.$refs.reply.quote(post.author, post.body_html);
      this.$refs.reply.$el.scrollIntoView();
    },
    remove(post) {
      this.selected = post;
      this.$refs.remove.show();
    },
    report(post) {
      this.selected = post;
      this.$refs.report.show();
    },
    async confirmReport() {
      await api.posts.report(this.selected);
    },
    async confirmRemove() {
      await api.posts.remove(this.selected);
      if (this.posts.length === 1) {
        this.$router.push({ name: 'forum', params: { id: this.topic.forum.id } });
      } else {
        this.load();
      }
    },
    async load(created) {
      if (this.search) {
        const results = await api.posts.search(this.$route.params.id, {
          offset: this.page * this.limit,
          limit: this.limit,
          query: this.$route.query.query || '',
        });
        this.count = results.post_count;
        this.pages = Math.ceil(results.post_count / this.limit);
        this.topic = undefined;
      } else if (this.recent) {
        const results = await api.posts.recent({
          offset: this.page * this.limit,
          limit: this.limit,
        });
        this.count = results.post_count;
        this.pages = Math.ceil(results.post_count / this.limit);
        this.topic = undefined;
      } else {
        const results = await api.posts.load(this.$route.params.id, {
          offset: this.page * this.limit,
          limit: this.limit,
        });
        this.count = results.post_count;
        this.pages = Math.ceil(results.post_count / this.limit);
        this.topic = results;
        this.forum = this.topic.forum;
      }
      const post = document.getElementById(this.$route.hash.replace('#', ''));
      if (post) {
        this.$nextTick(() => {
          post.scrollIntoView();
        });
      }
      if (created && created.id) {
        const desiredPage = Math.floor(created.position / 10);
        if (this.page !== desiredPage) {
          this.$router.replace({
            name: 'topic',
            hash: `#${created.id}`,
            params: { id: created.topic_id },
            query: { page: Math.floor(created.position / 10) },
          });
        } else {
          const postElement = document.getElementById(created.id);
          this.$nextTick(() => {
            postElement.scrollIntoView();
          });
        }
      }
    },
    async updateRoute(page) {
      if (this.search) {
        this.$router.replace(
          { name: 'search', query: { query: this.$route.query.query, page } },
        );
      } else if (this.recent) {
        this.$router.replace({ name: 'recent', query: { page } });
      } else {
        this.$router.replace({ name: 'topic', query: { page } });
      }
    },
  },
  watch: {
    async $route() {
      this.load();
    },
  },
  async created() {
    this.load();
  },
  beforeRouteLeave(to, from, next) {
    models.Post.deleteAll();
    next();
  },
};
</script>

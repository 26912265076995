<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-signin-form {
  }
</style>


<template>
  <b-form @submit.prevent="submit">
    <!-- username -->
    <b-form-group>
      <b-form-input v-model="form.username" :state="states.password"
                    type="text" placeholder="Username" />
    </b-form-group>

    <!-- password -->
    <b-form-group>
      <b-form-input v-model="form.password" :state="states.password"
                    type="password" placeholder="Password"/>
      <b-form-invalid-feedback>
        The username or password you entered is incorrect.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <b-button type="submit" variant="primary" class="mr-2">Sign in</b-button>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';

export default {
  name: 'r-signin-form',
  mixins: [form],
  methods: {
    async submitted() {
      await api.auth.login(this.form.username, this.form.password);
      this.$router.push({ path: this.$route.query.next || '/' }).catch(() => {});
    },
    async rejected() {
      this.form.password = '';
    },
  },
};
</script>

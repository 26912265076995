<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-forum-layout {
    .welcome, .warning {
      font-size: 14px;
    }
    .warning {
      padding: 5px;
      border: 1px solid $color-gray-3;
      background: $color-gray-2;
    }
    .forum {
      margin-top: 25px;
    }
    .search {
      margin-bottom: 15px;
      .category {
        color: white;
        background: $color-blue;
        padding: 5px;
        font-size: 14px;
        font-weight: normal;
      }
      .shade-box {
        padding: 10px 8px;
        border: 1px solid $color-gray-3;
        background: $color-gray-1;
      }
    }
  }
</style>


<template>
  <r-program-layout class="r-forum-layout">
    <p class="welcome">
      This forum may contain mature content. If you are under 18 years of age, you should access this forum with
      parent or guardian supervision. Students are free to share ideas centered on philosophies taught in the Reclaim
      program, including outside resources (i.e. links to programs, books, inspirational You Tube videos, etc). If
      you click on these links, please be prepared to do so at your own risk. There could be possible sideline
      triggers.
    </p>
    <p class="warning">
      If you feel that there are any posts violating the forum rules, especially causing potential
      harm to any students, PLEASE e-mail
      <a href="mailto:candeoforumnotifications@candeocan.com">candeoforumnotifications@candeocan.com</a>
      immediately.
    </p>

    <div class="forum">
      <!-- search -->
      <div class="search" v-show="searchbar">
        <r-forum-table>
          <template slot="title">Search</template>
          <template slot="body">
            <tr>
              <td class="p-2">
                <b-form @submit.prevent="search">
                  <b-input-group size="sm">
                    <b-form-input type="text" v-model="query"></b-form-input>
                    <b-input-group-append>
                      <b-button type="submit" class="search-input" variant="secondary">
                        <b-icon icon="search"/>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form>
              </td>
            </tr>
          </template>
        </r-forum-table>
      </div>

      <!-- table -->
      <slot></slot>
    </div>
  </r-program-layout>
</template>


<script>
import RForumTable from './r-forum-table';


export default {
  name: 'r-forum-layout',
  components: {
    RForumTable,
  },
  props: {
    searchbar: {
      default: true,
    },
  },
  data() {
    return {
      query: '',
    };
  },
  methods: {
    search() {
      this.$router.push({ name: 'search', query: { query: this.query } }).catch(() => {});
    },
  },
};
</script>

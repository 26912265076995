import { Model } from '@vuex-orm/core';

export default class Payment extends Model {
  static entity = 'payments'

  static fields() {
    return {
      id: this.attr(null),
      profile: this.attr(''),
      amount: this.attr(''),
      status: this.attr(''),
      begin_date: this.attr(''),
      expire_date: this.attr(''),
      created_at: this.attr(''),
      username: this.attr(''),
      product_name: this.attr(''),
      transaction_id: this.attr(''),
      card: this.attr(''),
      last_billing_attempt: this.attr(''),
    };
  }
}

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .a-reports {
  }
</style>


<template>
  <r-admin-layout class="a-reports">
    <template slot="title"><b-icon icon="file-earmark-bar-graph"/>Reports</template>
    <r-admin-card title="Create Reports">
      <b-form @submit.prevent="submit">
        <div class="text-lg font-medium">Data Fields</div>
        <hr class="my-3">
        <b-form-group label="User Fields">
          <b-form-select v-model="form['user-fields']" :options="userOptions" multiple
                         :select-size="userOptions.length">
          </b-form-select>
        </b-form-group>
        <b-form-group label="Product Fields">
          <b-form-select v-model="form['product-fields']" :options="productOptions" multiple
                         :select-size="productOptions.length">
          </b-form-select>
        </b-form-group>
        <b-form-group label="Payment Fields">
          <b-form-select v-model="form['payment-fields']" :options="paymentOptions" multiple
                         :select-size="paymentOptions.length">
          </b-form-select>
        </b-form-group>

        <b-form-group>
          <b-form-checkbox size="lg" v-model="form['ignore-zero']">
            Ignore Payments of Zero Dollars
          </b-form-checkbox>
        </b-form-group>

        <div class="text-lg font-medium mt-9">Data Filters</div>
        <hr class="my-3">

        <div class="flex">
          <b-form-group label="Start Date Filter" class="w-1/2">
            <b-form-datepicker v-model="form['start-date']" type="text" />
          </b-form-group>
          <div class="w-6"></div>
          <b-form-group label="End Date Filter" class="w-1/2">
            <b-form-datepicker v-model="form['end-date']" type="text" />
          </b-form-group>
        </div>

        <b-form-group label="Payment Filter">
          <b-form-select v-model="form['payment-filter']" :options="paymentFilterOptions" multiple
                         :select-size="paymentFilterOptions.length">
          </b-form-select>
        </b-form-group>

        <b-form-group label="Organization Filter">
          <b-form-select v-model="form['organization-filter']" :options="organizationFilterOptions" multiple
                         :select-size="organizationFilterOptions.length">
          </b-form-select>
        </b-form-group>

        <!-- submit -->
        <div class="h-box items-center">
          <b-button type="submit" variant="primary" class="mr-2">Export Report</b-button>
          <div class="w-3"></div>
          <b-progress v-if="report.state === 'PROGRESS'" class="flex-grow"
                      :value="report.progress" :max="100" show-progress animated></b-progress>
          <div v-if="report.state === 'SUCCESS'">
            <a :href="report.url" target="_blank">{{ report.filename }}</a>
          </div>
          <div v-if="report.state === 'FAILED'">
            Report failed.
          </div>
        </div>

      </b-form>
    </r-admin-card>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';

export default {
  name: 'a-reports',
  data() {
    const startDate = new Date();
    startDate.setMonth(0);
    startDate.setDate(1);
    const endDate = new Date();
    return {
      userOptions: [
        { value: 'profile__user__username', text: 'Username' },
        { value: 'profile__user__date_joined', text: 'Signup Date' },
        { value: 'profile__user__last_login', text: 'Last Login' },
        { value: 'profile__display_name', text: 'Display Name' },
        { value: 'profile__user__email', text: 'Email' },
        { value: 'profile__referrer__name', text: 'Organization' },
      ],
      productOptions: [
        { value: 'product__name', text: 'Name' },
        { value: 'product__cost', text: 'Cost' },
        { value: 'product__cycle', text: 'Cycle' },
        { value: 'product__courses', text: 'Courses' },
        { value: 'product__recurring', text: 'Recurring' },
        { value: 'product__enabled', text: 'Enabled' },
      ],
      paymentOptions: [
        { value: 'amount', text: 'Amount' },
        { value: 'status', text: 'Status' },
        { value: 'created_at', text: 'Creation Date' },
        { value: 'coupon', text: 'Coupon' },
        { value: 'reference', text: 'Reference' },
        { value: 'begin_date', text: 'Begin Date' },
        { value: 'expire_date', text: 'Expire Date' },
      ],
      paymentFilterOptions: [
        { value: 'Settled', text: 'Settled' },
        { value: 'Failed', text: 'Failed' },
        { value: 'Open', text: 'Open' },
        { value: 'Refunded', text: 'Refunded' },
      ],
      form: {
        'user-fields': ['profile__user__username', 'profile__user__date_joined', 'profile__user__last_login'],
        'product-fields': ['product__name'],
        'payment-fields': ['amount', 'status', 'created_at'],
        'ignore-zero': true,
        'start-date': startDate,
        'end-date': endDate,
        'payment-filter': ['Settled'],
        'organization-filter': [],
      },
    };
  },
  computed: {
    report() {
      return models.AdminReport.query().orderBy('created_at', 'desc').first() || {};
    },
    organizationFilterOptions() {
      return models.AdminReferrer.query().orderBy('id', 'asc').all()
        .map((r) => ({
          value: r.id,
          text: r.name,
        }));
    },
  },
  methods: {
    async poll() {
      await api.adminReports.get(this.report.id);
      if (this.report.state === 'PROGRESS') {
        setTimeout(() => this.poll(), 2000);
      } else if (this.report.state === 'SUCCESS') {
        api.alerts.success('Report is ready to download.');
      } else if (this.report.state === 'FAILED') {
        api.alerts.error('Unable to create report.');
      }
    },
    async submit() {
      await api.adminReports.create(this.form);
      this.poll();
    },
    async load() {
      await api.adminReferrers.load();
      this.form['organization-filter'] = this.organizationFilterOptions.map((r) => r.value);
    },
  },
  async created() {
    await this.load();
  },
};
</script>

import Frc from '@/models/frc';
import restify from '@/extensions/restify';

const api = restify(Frc, '/frcs');

export default {
  load: api.load,
  get: api.get,
  create: api.create,
  update: api.update,
  remove: api.remove,
};

import Vue from 'vue';
import vSelect from 'vue-select';
import App from './app';
import router from './router';
import store from './store';
import rest from './api/rest';
import components from './components';
import bootstrap from './extensions/bootstrap';
import '@/assets/styles/main.scss';
import api from './api';
import models from './models';
import filters from './filters';


// debug api
if (process.env.NODE_ENV === 'development') {
  window.api = api;
  window.store = store;
  window.models = models;
}

// register plugins and components
bootstrap.register();
components.register();
filters.register();
Vue.config.productionTip = false;

// register vue select component
Vue.component('v-select', vSelect);

// register rest error handlers
rest.on(401, () => {
  store.set('authenticated', false);
  router.push({ name: 'signin' });
});
// rest.on('undefined', (e) => {
//   console.log(`uncaught network error ${e}`);
// });

// create root vue app
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

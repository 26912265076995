<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-support-modal {

  }
</style>


<template>
  <r-clean-modal ref="modal" size="lg">
    <template slot="title">Support</template>
    <p>
      Our commitment to our users is to provide timely responses to all inquiries, generally within 24-48 hours.
    <p>
    <p>
      Reclaim Support is not the place to inquire about how to use individual features of the program
      (unless, of course, the particular feature is not working properly). Your Reclaim Coach can best
      answer these inquiries, or you might consider seeking help from more experienced Reclaim users by
      posting your question on the Reclaim forums. There will be many who will be happy to help you.
    </p>
    <p>
      <strong>
        Email <a href="mailto:support@reclaimsexualhealth.com">support@reclaimsexualhealth.com</a>
        for technical suppport and customer service issues (please include your username/email address
        associated with your account).
      </strong>
    </p>
    <p>
      If you are writing Reclaim Support to cancel your account:
    </p>
    <p>
      Please recall that you might have a membership that calls for a six-month commitment before you can cancel.
      Please refer to the terms and conditions of your particular subscription to determine the cancellation
      requirements. All students are required to give Reclaim notice in writing to cancel their accounts. It is not
      appropriate to contact your coach and have them attempt to pass the message along to support, nor to leave a
      phone message on Reclaim’s answering service. To stop your billing, request must be made on or before the
      actual date of billing for your payment.
    </p>
  </r-clean-modal>
</template>


<script>
import modal from '@/extensions/modal';

export default {
  name: 'r-support-modal',
  methods: {
    ...modal.methods,
  },
};
</script>

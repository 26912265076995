<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-subscription-form {}
</style>


<template>
  <div>
    <b-form @submit.prevent="submit" class="r-subscription-form">

      <!-- subscription -->
      <b-form-group label="Select subscription">
        <b-form-select v-model="form.product" :options="products"></b-form-select>
        <b-form-invalid-feedback>
          Please select a subscription.
        </b-form-invalid-feedback>
      </b-form-group>

      <!-- billing information -->
      <r-billing-fields :form="form" :errors="errors" :states="states"/>
      <b-form-group>
        <b-form-text v-show="errors['_']" style="font-size: 14px" text-variant="danger">
          There was a problem charging your credit card.  Please confirm your card information
          and try again.
        </b-form-text>
      </b-form-group>

      <!-- submit -->
      <b-button type="submit" variant="primary" class="mr-2">Subscribe and Pay</b-button>
    </b-form>
  </div>
</template>


<script>
import { capitalize } from 'lodash';
import api from '@/api';
import models from '@/models';
import form from '@/mixins/form';
import RBillingFields from './r-billing-fields';


export default {
  name: 'r-subscription-form',
  mixins: [form],
  components: {
    RBillingFields,
  },
  computed: {
    products() {
      return models.Product.all().map((p) => ({
        value: p.id,
        text: `$${p.cost} ${capitalize(p.cycle)} ${p.name}`,
      }));
    },
  },
  methods: {
    async reset() {
      this.form = {
        product: this.products[0].value,
      };
      this.errors = {};
    },
    async submitted() {
      await api.billing.subscribe(this.form);
      this.$emit('success');
      api.alerts.success('Your billing information has been updated.');
      api.payments.load();
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="building"/>Organizations</template>
    <r-admin-card title="Organization Information">
      <r-referrer-form v-if="loaded" :onSubmit="submitted" :onRemove="removed" :data="referrer" />
    </r-admin-card>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RReferrerForm from '@/components/forms/r-referrer-form';

export default {
  name: 'a-referrer',
  components: {
    RReferrerForm,
  },
  methods: {
    async load() {
      this.newReferrer = this.$route.params.id === 'new';
      if (!this.newReferrer) {
        this.referrer = models.AdminReferrer.find(this.$route.params.id);
        if (!this.referrer) {
          this.referrer = await api.adminReferrers.get(this.$route.params.id);
        }
      }
      await api.adminProfiles.coaches();
      this.loaded = true;
    },
    async submitted(form) {
      if (this.newReferrer) {
        this.referrer = await api.adminReferrers.create(form);
        this.$router.push({ name: 'admin-referrers' });
        api.alerts.success('Organization created.');
      } else {
        this.referrer = await api.adminReferrers.update(form.id, form);
        api.alerts.success('Organization updated.');
      }
    },
    async removed(form) {
      await api.adminReferrers.remove(form);
      this.$router.push({ name: 'admin-referrers' });
    },
  },
  data() {
    return {
      referrer: {},
      newReferrer: false,
      loaded: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

import { render, staticRenderFns } from "./a-articles.vue?vue&type=template&id=57ac5e89&scoped=true&"
import script from "./a-articles.vue?vue&type=script&lang=js&"
export * from "./a-articles.vue?vue&type=script&lang=js&"
import style0 from "./a-articles.vue?vue&type=style&index=0&id=57ac5e89&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ac5e89",
  null
  
)

export default component.exports
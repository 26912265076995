<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .setback {
    .divider {
      margin-top: 4px;
      margin-bottom: 4px;
      border-top: 2px solid #28668b;
    }
    .arrow-right {
      z-index: 99;
      position: absolute;
      top: 50%;
      left: 98.2%;
      transform: translate(-25%, -50%);
    }

    .blue_button {
      display: block;
      background: url('~@/assets/images/reclaim/training/button_blank_blue.png');
      width: 153px;
      height: 37px;
      padding-top: 19px;
      text-align: center;
      color: #ebe8e8;
      font-size: 14px;
      margin-top:10px;
      margin-left:80px;
      height:55px;
    }

    .sb_action {
      height:345px;
      overflow:auto;
      border:solid 1px #b3b4b5;
    }

   .sb_action_title {
     color:#2c90bd;
     font-size:17px;
   }
  }
</style>


<template>
  <r-program-layout class="setback">
    <div class="flex">
      <div class="flex-grow mr-3">
        <div class="text-xl font-medium">SETBACK ACTION PLANNING</div>
        <hr class="divider">
        <div class="font-medium mb-2">1. Don't beat yourself up! This is a valuable learning opportunity!</div>
        <div class="font-medium mb-2">2. Immediately assess "why" you believe you had a setback</div>
        <div class="font-medium mb-2">3. Choose one or more "Causes for Setback"—watch the training then
          develop an "Action Plan" to start moving forward</div>
      </div>
      <div class="flex flex-column">
        <r-section-button
          class="flex-grow-0"
          :to="{ name: 'training', params: { course: 'setback', level: 0, segment: 1 }, }"
          :bar="false"
          :src="require('@/assets/images/reclaim/crisis_and_setbacks/sb_training_thumb.png')"/>
        <div class="flex-grow"></div>
      </div>
    </div>
    <div class="p-4 flex rounded border" style="background-color: #f0f0f0;">
      <div class="border-r-2 border-gray-400 pr-12 relative">
        <div class="grid grid-cols-3 gap-2">
          <div v-for="training in trainings" :key="training.id" @click.prevent="select(training.id)">
            <r-section-button
              class="flex-grow-0"
              :to="''"
              :bar="false"
              :src="require(`@/assets/images/reclaim/crisis_and_setbacks/` + training.thumbnail)"/>
          </div>
        </div>
        <img class="arrow-right" src="@/assets/images/reclaim/training/arrow_right.png">
      </div>
      <div class="flex-grow"></div>
      <div class="flex-grow"></div>
      <div class="border bg-white w-5/12 p-4 sb_action">
        <div class="sb_action_title font-semibold mb-2">{{ this.currentTraining.title }}</div>
        <div v-for="line in this.currentTraining.lines" :key="line" class="font-semibold mb-2">
          {{ line }}
        </div>
        <router-link :to="this.currentTo">
          <div class="blue_button font-semibold">View Training</div>
        </router-link>
      </div>
      <div class="flex-grow"></div>
    </div>

    <div class="mt-2 font-medium">4. Follow through with your Action Plan</div>

  </r-program-layout>
</template>


<script>
import RSectionButton from '../trainings/r-section-button';

export default {
  name: 'Setbacks',
  components: {
    RSectionButton,
  },
  methods: {
    select(x) {
      if (x < 8) {
        this.current = x;
      }
    },
  },
  data() {
    return {
      trainings: [
        {
          id: 1,
          thumbnail: 'sb_detox_thumb.png',
          title: 'Detox',
          lines: [
            'Create Boundaries',
            'Create New Healthy Pleasure Outlets',
            'Engage in a Consistent Daily Routine',
            'Use a Support System',
          ],
        },
        {
          id: 2,
          thumbnail: 'sb_brj_thumb.png',
          title: 'Blame, Rationalize and Justify',
          lines: [
            'Awareness',
            'FRC Practice',
            'Use a Support System',
          ],
        },
        {
          id: 3,
          thumbnail: 'sb_apathy_thumb.png',
          title: 'Apathy',
          lines: [
            'Be Aware',
            'Review Your Motives',
            'Be positive',
            'Take One Day at a Time',
            'Use Healthy Pleasure Outlets',
            'Use a Support System',
            'Be Consistent',
            'Seek Professional Help',
          ],
        },
        {
          id: 4,
          thumbnail: 'sb_complacency_thumb.png',
          title: 'Complacency',
          lines: [
            'Awareness',
            'Keep Daily Activities Dynamic',
            'Log and Review Successes',
            'Apply FRC to other areas',
            'Use a Support System',
            'Be Consistent',
          ],
        },
        {
          id: 5,
          thumbnail: 'sb_mh_thumb.png',
          title: 'Mental Health Issues',
          lines: [
            'Contact a Professional',
            'Continue in the Program',
            'Use a Support System',
          ],
        },
        {
          id: 6,
          thumbnail: 'sb_anp_thumb.png',
          title: 'Avoidance Cycle and Perfectionism',
          lines: [
            'Face Your Unwanted Thoughts and Urges',
            'Become More Aware',
            'Celebrate Your Successes',
            'Be Consistent',
            'Use a Support System',
          ],
        },
        {
          id: 8,
          thumbnail: 'sb_blank_thumb.png',
        },
        {
          id: 7,
          thumbnail: 'sb_et_thumb.png',
          title: 'Emotional Triggers',
          lines: [
            'Be aware of your feelings and emotions',
            'Be aware of your self-talk',
            'Have pre-selected Healthy Outlets ready-to-go',
            'Use a Support System',
            'Set Reminders',
            'Be Consistent',
          ],
        },
        {
          id: 9,
          thumbnail: 'sb_blank_thumb.png',
        },
      ],
      current: 1,
    };
  },
  computed: {
    currentTraining() {
      return this.trainings.filter((t) => t.id === this.current)[0];
    },
    currentTo() {
      return {
        name: 'training',
        params: { course: 'setback', level: this.currentTraining.id, segment: 1 },
      };
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-admin-layout {
    min-height: 100%;
    .title {
      align-items: center;
      font-size: 24px;
      font-weight: normal;
      padding-bottom: 10px;
      .b-icon {
        margin-right: 5px;
      }
    }
    .top {
      background: $color-gray-2;
      border-bottom: 1px solid $color-gray-4;
      .nav {
        font-size: 16px;
      }
    }
    .side {
      width: 180px;
      background: $color-gray-2;
      border-right: 1px solid $color-gray-4;
    }
    .page {
      padding: 15px;
      overflow: auto;
    }
    .nav {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      min-width:0;
      min-height:0;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      cursor: pointer;
      color: $color-gray-8 !important;
      padding: 15px 20px;
      .b-icon {
        margin-right: 10px;
      }
      &:hover {
        background: $color-gray-4;
      }
      font-size: 14px;
    }
    a.nav {
      color: $color-gray-8 !important;
      &:link, &:visited, &:active {
        color: $color-gray-8 !important;
        text-decoration: none;
      }
    }
  }
</style>


<template>
  <div class="r-admin-layout v-box">
    <r-alerts/>
    <div class="top h-box flex-limit align-items-center">
      <router-link class="nav" :to="{ name: 'home'}">
        Reclaim
      </router-link>
      <div class="flex-spacer"></div>
      <router-link class="nav" :to="{ name: 'home'}">
        View Site
      </router-link>
      <div class="nav" @click.prevent="logout">
        Sign Out
      </div>
    </div>
    <div class="h-box">
      <div class="side v-box flex-limit">
        <router-link class="nav" :to="{ name: 'admin-users'}">
          <b-icon icon="person-fill"/>Users
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-referrers'}">
          <b-icon icon="building"/>Organizations
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-articles'}">
          <b-icon icon="file-earmark-richtext"/>Articles
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-trainings'}">
          <b-icon icon="camera-video"/>Trainings
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-topics'}">
          <b-icon icon="chat-right-text"/>Forum Topics
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-bans'}">
          <b-icon icon="slash-circle-fill"/>Forum Bans
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-products'}">
          <b-icon icon="newspaper"/>Products
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-coupons'}">
          <b-icon icon="tags-fill"/>Coupons
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-payments'}">
          <b-icon icon="cash"/>Payments
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-billing'}">
          <b-icon icon="credit-card"/>Billing
        </router-link>
        <router-link class="nav" :to="{ name: 'admin-reports'}">
          <b-icon icon="file-earmark-bar-graph"/>Reports
        </router-link>
      </div>
      <div class="page v-box">
        <div class="h-box title flex-limit">
          <slot name="title"></slot>
          <div class="flex-spacer"></div>
          <slot name="buttons"></slot>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>


<script>
import api from '@/api';


export default {
  name: 'r-admin-layout',
  methods: {
    async logout() {
      try {
        await api.auth.logout();
        api.alerts.success('You have been signed out.');
        this.$router.push({ name: 'signin' }).catch(() => {});
      } catch {
        api.alerts.error('There was a problem signing out, please try again.');
      }
    },
  },
};
</script>

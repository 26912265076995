<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-banner {
    padding: 10px;
    background: white;
    .content {
      margin: 0 auto;
      max-width: $content-width;
    }
    .logo {
        height: 32px;
    }
    .support {
      padding-left: 50px;
      img {
        object-fit: cover;
        height: 70px;
        width: 95px;
      }
      .phrase {
        padding-left: 30px;
      }
    }
    .menu {
      padding: 5px;
      font-size: 13px;
    }
    .r-identicon {
      padding-right: 10px;
      & > img {
        padding-right: 20px;
      }
    }
  }
</style>

<template>
  <div class="r-banner">
    <div class="content h-box items-center">
      <img class="logo" src="@/assets/images/logo_dark.png" alt="Reclaim">
      <div class="support h-box items-center" v-if="success || new_signup">
        <img :src="require('@/assets/images/reclaim/success.jpg')" alt="Support">
        <div class="phrase v-box">
          <div>SUCCESS</div>
          <div>{{success ? success.body : "Your latest Success will be posted here."}}</div>
        </div>
      </div>
      <div class="flex-spacer"></div>
      <div class="menu v-box flex-limit text-right">
        <div class="h-box justify-end items-center">
          <r-identicon :src="avatar" size="18px"/>
          <span>{{name}}</span>
        </div>
        <router-link :to="{ name: 'support'}">Customer Support</router-link>
        <router-link v-if="!new_signup" :to="{ name: 'account'}">Manage Account</router-link>
        <router-link v-if="!new_signup" :to="{ name: 'profile'}">Edit Profile</router-link>
        <router-link v-if="new_signup" :to="{ name: 'subscribe'}">Subscribe</router-link>
        <a href="" @click.prevent="logout">Log out</a>
      </div>
    </div>
  </div>
</template>


<script>
import api from '@/api';
import models from '@/models';
import { get } from 'vuex-pathify';

export default {
  name: 'r-banner',
  data() {
    return {
      phrase: 'My support phrase.',
    };
  },
  computed: {
    name: get('profile.display_name'),
    avatar: get('profile.avatar'),
    new_signup: get('profile.new_signup'),
    success() {
      return models.Status.query().orderBy('created_at', 'desc').first();
    },
  },
  methods: {
    async logout() {
      try {
        await api.auth.logout();
        api.alerts.success('You have been signed out.');
        this.$router.push({ name: 'signin' }).catch(() => {});
      } catch {
        api.alerts.error('There was a problem signing out, please try again.');
      }
    },
  },
};
</script>

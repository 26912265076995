import { render, staticRenderFns } from "./crisis-and-setbacks.vue?vue&type=template&id=7ddaf154&scoped=true&"
import script from "./crisis-and-setbacks.vue?vue&type=script&lang=js&"
export * from "./crisis-and-setbacks.vue?vue&type=script&lang=js&"
import style0 from "./crisis-and-setbacks.vue?vue&type=style&index=0&id=7ddaf154&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ddaf154",
  null
  
)

export default component.exports
import AdminBan from '@/models/admin-ban';
import restify from '@/extensions/restify';

const api = restify(AdminBan, '/admin/bans');

export default {
  load: api.load,
  get: api.get,
  update: api.update,
  create: api.create,
  remove: api.remove,
};

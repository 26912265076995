var render = function render(){var _vm=this,_c=_vm._self._c;return _c('r-forum-layout',{staticClass:"topic",attrs:{"searchbar":!_vm.recent}},[_c('r-forum-breadcrumb',{directives:[{name:"show",rawName:"v-show",value:(!_vm.search && !_vm.recent),expression:"!search && !recent"}],attrs:{"forum":_vm.forum,"topic":_vm.topic}}),_c('r-forum-paginator',{attrs:{"value":_vm.page,"pages":_vm.pages},on:{"input":_vm.updateRoute}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.search && _vm.count === 0),expression:"search && count === 0"}],staticClass:"c-box m-10"},[_vm._v(" No posts were found matching your query. ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.recent && _vm.count === 0),expression:"recent && count === 0"}],staticClass:"c-box m-10"},[_vm._v(" No recent posts. ")]),_vm._l((_vm.posts),function(post){return _c('r-forum-table',{key:post.id,staticClass:"pb-3",attrs:{"id":post.id,"pages":_vm.pages},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}},[_c('template',{slot:"title"},[(_vm.search)?_c('router-link',{staticClass:"light",attrs:{"to":{name: 'topic', hash: `#${post.id}`,
                         params: { id: post.topic_id },
                         query: { page: Math.floor(post.position / 10) }}}},[_vm._v(" "+_vm._s(_vm._f("datetime")(post.created))+" ")]):(_vm.recent)?_c('router-link',{staticClass:"light",attrs:{"to":{name: 'topic', hash: `#${post.id}`,
                         params: { id: post.topic_id },
                         query: { page: Math.floor(post.position / 10) }}}},[_vm._v(" "+_vm._s(_vm._f("datetime")(post.created))+" ")]):_c('router-link',{staticClass:"light",attrs:{"to":{path: `${_vm.$route.path}#${post.id}`, query: _vm.$route.query}}},[_vm._v(" "+_vm._s(_vm._f("datetime")(post.created))+" ")])],1),_c('template',{slot:"body"},[_c('tr',[_c('td',{staticClass:"align-top border-bottom-none shaded author-cell"},[_c('div',{staticClass:"author"},[_vm._v(_vm._s(post.author))]),_c('r-identicon',{staticClass:"flex-limit justify-self-bottom pl-5 pr-5",attrs:{"src":post.avatar,"size":"64px"}}),_c('div',[_vm._v("Posts: "+_vm._s(post.author_post_count))])],1),_c('td',{staticClass:"align-top border-bottom-none"},[_c('div',{staticClass:"post-body prosemirror-rendered",domProps:{"innerHTML":_vm._s(post.body_html)}})])]),_c('tr',[_c('td',{staticClass:"align-top border-top-none shaded"}),_c('td',{staticClass:"align-top border-top-none"},[(!_vm.recent && !_vm.search)?_c('div',{staticClass:"text-right"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.quote(post)}}},[_vm._v("Quote")]),_vm._v(" | "),(post.author_id == _vm.profile.id || _vm.profile.admin)?_c('span',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remove(post.id)}}},[_vm._v(" Delete ")]),_vm._v(" | "),_c('router-link',{attrs:{"to":{name: 'edit-post', params: {id: post.id}, query: {page: _vm.page}}}},[_vm._v(" Edit ")]),_vm._v(" | ")],1):_vm._e(),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.report(post.id)}}},[_vm._v(" Report ")])]):_c('div',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{name: 'topic', hash: `#${post.id}`,
                               params: { id: post.topic_id },
                               query: { page: Math.floor(post.position / 10) }}}},[_vm._v(" View Post ")])],1)])])])],2)}),_c('r-forum-paginator',{attrs:{"value":_vm.page,"pages":_vm.pages},on:{"input":_vm.updateRoute}}),_c('r-forum-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.search && !_vm.recent),expression:"!search && !recent"}],staticClass:"r-forum-reply"},[_c('template',{slot:"title"},[_vm._v(" New reply ")]),_c('template',{slot:"contents"},[_c('r-post-form',{ref:"reply",attrs:{"topic":_vm.$route.params.id},on:{"success":_vm.load}})],1)],2),_c('r-confirm-modal',{ref:"remove",on:{"confirm":_vm.confirmRemove}},[_vm._v(" Are you sure you want to delete this post? ")]),_c('r-confirm-modal',{ref:"report",on:{"confirm":_vm.confirmReport}},[_vm._v(" Are you sure you want to report this post as inappropriate? ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
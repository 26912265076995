import models from '@/models';
import restify from '@/extensions/restify';

const api = restify(models.admin.User, '/admin/users');

export default {
  get: api.get,
  update: api.update,
  load: (params) => api.load(params, 'objects'),
  create: api.create,
};

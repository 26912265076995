<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <b-form @submit.prevent="submit">

    <!-- name -->
    <b-form-group label="Name">
      <b-form-input v-model="form.name" :state="states.name" type="text" />
      <b-form-invalid-feedback>
        Please enter a name.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- forum -->
    <b-form-group label="Forum">
      <b-form-select v-model="form.forum" :options="forumOptions" :state="states.forum"></b-form-select>
      <b-form-invalid-feedback>
        Please select a forum.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- last post -->
    <b-form-group label="Last Post">
      <b-form-input :value="form.last_post && form.last_post.summary" type="text" disabled />
    </b-form-group>

    <!-- submit -->
    <div class="h-box">
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button type="submit" variant="warning" class="mr-2 text-white" @click.prevent="reset()">
        Reset
      </b-button>
    </div>

  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';
import models from '@/models';

export default {
  name: 'r-topic-form',
  mixins: [form],
  props: ['topic', 'onSubmit'],
  methods: {
    async submitted() {
      await this.onSubmit(this.form);
    },
    async rejected() {
      api.alerts.error('Unable to save topic.');
    },
    async reset() {
      const { ...fields } = this.topic;
      this.form = fields;
      this.errors = {};
    },
  },
  data() {
    return {
      forumOptions: models.Forum.query().all().map((f) => ({ value: f.id, text: f.name })),
    };
  },
  created() {
    this.reset();
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

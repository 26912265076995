<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-loading {
    text-align: center;
    color: $color-blue;
    .subtitle {
      padding-top: 15px;
    }
  }
</style>


<template>
  <r-focus-layout class="r-loading" :center="true">
    <div class="c-box">
      <dot-loader color="#347fa8"/>
      <div class="subtitle">Loading</div>
    </div>
  </r-focus-layout>
</template>


<script>
import { DotLoader } from '@saeris/vue-spinners';

export default {
  name: 'r-loading',
  components: {
    DotLoader,
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-message-form {
    .error {
      border: 1px solid #d33c34;
      border-radius: 4px;
    }
  }
</style>


<template>
  <b-form class="r-message-form" @submit.prevent="submit">

    <!-- name -->
    <b-form-group label="Product Name">
      <b-form-input v-model="form.name" :state="states.name" type="text" />
      <b-form-invalid-feedback>
        Please enter a name.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- cost -->
    <b-form-group label="Cost">
      <b-input-group prepend="$">
        <b-form-input v-model="form.cost" :state="states.cost" type="text" />
      </b-input-group>
      <b-form-invalid-feedback :state="states.cost">
        Please enter a cost.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Cycle">
      <b-form-select v-model="form.cycle" :options="cycleOptions" :state="states.cycle"></b-form-select>
      <b-form-invalid-feedback :state="states.cycle">
        Please select a cycle.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Courses">
      <multiselect v-model="form.courses" :custom-label="courseLabel" :options="courseOptions"
                   :multiple="true"></multiselect>
    </b-form-group>

    <b-form-group label="Recurring">
      <b-form-checkbox size="lg" v-model="form.recurring" :state="states.recurring" switch></b-form-checkbox>
    </b-form-group>

    <b-form-group label="Enabled">
      <b-form-checkbox size="lg" v-model="form.enabled" :state="states.enabled" switch></b-form-checkbox>
    </b-form-group>

    <!-- submit -->
    <div class="h-box">
      <b-button type="submit" variant="primary" class="mr-2">Save</b-button>
      <b-button type="submit" variant="warning" class="mr-2 text-white" @click.prevent="reset()">
        Reset
      </b-button>
    </div>

  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';
import models from '@/models';
import Multiselect from 'vue-multiselect';


export default {
  name: 'r-product-form',
  mixins: [form],
  props: ['product', 'onSubmit'],
  components: { Multiselect },
  methods: {
    async submitted() {
      await this.onSubmit(this.form);
    },
    async rejected() {
      api.alerts.error('Unable to send message.');
    },
    async reset() {
      const { thumbnail, ...fields } = this.product;
      this.form = fields;
      this.thumbnail = null;
      this.errors = {};
    },
    courseLabel(id) {
      return this.courses.get(id);
    },
  },
  data() {
    return {
      courses: new Map(models.Course.query().all().map((c) => [c.id, c.name])),
      courseOptions: models.Course.query().all().map((c) => c.id),
      cycleOptions: [
        { value: 'LIFETIME', text: 'Lifetime' },
        { value: 'ANNUAL', text: 'Annual' },
        { value: 'SEMIANNUAL', text: 'Semiannual' },
        { value: 'MONTHLY', text: 'Monthly' },
      ],
    };
  },
  created() {
    this.reset();
  },
  watch: {
    value() { this.reset(); },
  },
};
</script>

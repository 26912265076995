<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
  .r-editor-input {
    background: white;
    border: 1px solid silver;
    /deep/ .ProseMirror {
      height: 400px;
    }

    .button-spacer {
      height: 1px;
      width: 20px;
    }
    .r-editor-menu {
      padding: 4px 8px;
      border-bottom: 1px solid silver;
    }

    &.is-invalid, &.is-invalid .r-editor-menu  {
      border-color: #d33c34 !important;
    }
  }
</style>

<template>
  <div class='r-editor-input' :class="{'is-invalid': state === false}">
    <div class="r-editor-menu h-box" v-if="editor">
      <r-editor-button @click="clearMarks()">
        <b-icon icon="type"/>
      </r-editor-button>
      <r-editor-button @click="toggleBold()" :disabled="!canToggleBold()" :active="isActive('bold')">
        <b-icon icon="type-bold"/>
      </r-editor-button>
      <r-editor-button @click="toggleItalic()" :disabled="!canToggleItalic()" :active="isActive('italic')" :width="24">
        <b-icon style="margin-right: 1px;" icon="type-italic"/>
      </r-editor-button>
      <r-editor-button @click="toggleStrike()" :disabled="!canToggleStrike()" :active="isActive('strike')">
        <b-icon icon="type-strikethrough"/>
      </r-editor-button>
      <r-editor-button @click="toggleUnderline()" :disabled="!canToggleUnderline()" :active="isActive('underline')">
        <b-icon style="margin-bottom: 3px;" icon="type-underline"/>
      </r-editor-button>
      <!-- <r-editor-button @click="toggleCode()" :disabled="!canToggleCode()" :active="isActive('code')">
        <b-icon icon="code"/>
      </r-editor-button> -->
      <div class="button-spacer"/>
      <r-editor-button @click="clearNodes()">
        <b-icon icon="text-indent-right"/>
      </r-editor-button>
      <!-- <r-editor-button @click="paragraph()" :active="isActive('paragraph')">
        <b-icon icon="paragraph"/>
      </r-editor-button>
      <r-editor-button @click="toggleHeading(1)" :active="isActive('heading', { level: 1 })">
        <b-icon icon="type-h1"/>
      </r-editor-button>
      <r-editor-button @click="toggleHeading(2)" :active="isActive('heading', { level: 2 })">
        <b-icon icon="type-h2"/>
      </r-editor-button>
      <r-editor-button @click="toggleHeading(3)" :active="isActive('heading', { level: 3 })">
        <b-icon icon="type-h3"/>
      </r-editor-button> -->
      <!-- <r-editor-button @click="toggleHeading(4)" :active="isActive('heading', { level: 4 })">
        <b-icon icon="type-h4"/>
      </r-editor-button>
      <r-editor-button @click="toggleHeading(5)" :active="isActive('heading', { level: 5 })">
        <b-icon icon="type-h5"/>
      </r-editor-button>
      <r-editor-button @click="toggleHeading(6)" :active="isActive('heading', { level: 6 })">
        <b-icon icon="type-h6"/>
      </r-editor-button> -->
      <r-editor-button @click="blockquote()" :active="isActive('blockquote')">
        <b-icon icon="blockquote-left"/>
      </r-editor-button>
      <r-editor-button @click="bulletList()" :active="isActive('bulletList')">
        <b-icon icon="list-ul"/>
      </r-editor-button>
      <r-editor-button @click="orderedList()" :active="isActive('orderedList')">
        <b-icon icon="list-ol"/>
      </r-editor-button>
      <!-- <r-editor-button @click="codeBlock()" :active="isActive('codeBlock')">
        <b-icon icon="code-square"/>
      </r-editor-button> -->
      <div class="button-spacer"/>
      <r-editor-button @click="toggleLink" :active="isActive('link')">
        <b-icon icon="link-45deg"/>
      </r-editor-button>
      <!-- <r-editor-button @click="unsetLink" :disabled="!isActive('link')">
        <b-icon icon="link-45deg"/>
      </r-editor-button> -->
      <r-editor-button @click="horizontalRule()">
        <b-icon icon="dash"/>
      </r-editor-button>
      <!-- <r-editor-button @click="lineBreak()">
        <b-icon icon="arrow-bar-down"/>
      </r-editor-button> -->
      <!-- <r-editor-button @click="undo()" :disabled="!canUndo()">
        <b-icon icon="arrow-90deg-left"/>
      </r-editor-button>
      <r-editor-button @click="redo()" :disabled="!canRedo()">
        <b-icon icon="arrow-90deg-right"/>
      </r-editor-button> -->
    </div>
    <editor-content :editor="editor"/>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import REditorButton from './r-editor-button';

export default {
  name: 'r-editor-input',
  components: {
    EditorContent,
    REditorButton,
  },
  props: ['value', 'state'],
  data() {
    return {
      editor: null,
    };
  },
  methods: {
    isActive(...args) {
      return this.editor.isActive(...args);
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    canToggleBold() {
      return this.editor.can().chain().focus().toggleBold().run();
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run();
    },
    canToggleItalic() {
      return this.editor.can().chain().focus().toggleItalic().run();
    },
    toggleStrike() {
      this.editor.chain().focus().toggleStrike().run();
    },
    canToggleStrike() {
      return this.editor.can().chain().focus().toggleStrike().run();
    },
    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run();
    },
    canToggleUnderline() {
      return this.editor.can().chain().focus().toggleUnderline().run();
    },
    toggleCode() {
      this.editor.chain().focus().toggleCode().run();
    },
    canToggleCode() {
      return this.editor.can().chain().focus().toggleCode().run();
    },
    clearMarks() {
      this.editor.chain().focus().unsetAllMarks().run();
    },
    clearNodes() {
      this.editor.chain().focus().clearNodes().run();
    },
    paragraph() {
      this.editor.chain().focus().setParagraph().run();
    },
    toggleHeading(level) {
      this.editor.chain().focus().toggleHeading({ level }).run();
    },
    bulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },
    orderedList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },
    codeBlock() {
      this.editor.chain().focus().toggleCodeBlock().run();
    },
    blockquote() {
      this.editor.chain().focus().toggleBlockquote().run();
    },
    horizontalRule() {
      this.editor.chain().focus().setHorizontalRule().run();
    },
    lineBreak() {
      this.editor.chain().focus().setHardBreak().run();
    },
    toggleLink() {
      if (this.isActive('link')) {
        this.unsetLink();
      } else {
        this.setLink();
      }
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      let url = window.prompt('URL', previousUrl);

      // ensure https
      url = url.replace(/^(?:https?:\/\/)?(.*)/, 'https://$1');

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    },
    unsetLink() {
      this.editor.chain().focus().unsetLink().run();
    },
    undo() {
      this.editor.chain().focus().undo().run();
    },
    canUndo() {
      return this.editor.can().chain().focus().undo().run();
    },
    redo() {
      return this.editor.chain().focus().redo().run();
    },
    canRedo() {
      return this.editor.can().chain().focus().redo().run();
    },
    insertQuote(author, body) {
      const quote = `<blockquote><p><i>${author}</i></p>${body}</blockquote>`;
      this.editor.commands.insertContent(quote);
    },
  },
  computed: {

  },
  watch: {
    value(value) {
      if (this.editor.getHTML() === value) {
        return;
      }
      // if (this.value === '' || this.value === undefined || this.value == null) {
      //   this.editor.commands.setContent('', false);
      // }
      this.editor.commands.setContent(value, false);
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Link.configure({
          openOnClick: false,
        }),
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

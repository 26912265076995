import Connection from '@/models/connection';
import restify from '@/extensions/restify';

const api = restify(Connection, '/connections');

export default {
  get: api.get,
  load: api.load,
  index: api.index,
};

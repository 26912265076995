import { Model } from '@vuex-orm/core';

export default class AdminProduct extends Model {
  static entity = 'admin-products'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      cost: this.attr(''),
      cycle: this.attr(''),
      recurring: this.attr(''),
      enabled: this.attr(null),
      courses: this.attr([]),
    };
  }
}

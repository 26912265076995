<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-shade-card {
    &.outer-box {
      border: 1px solid $color-gray-3;
      margin-top: 30px;
      background: $color-gray-2;
      border-radius: 5px;
      padding: 20px;
    }
  }
</style>

<template>
  <div class="r-shade-card outer-box">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'r-shade-card',
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .activities {
  }
</style>


<template>
  <r-program-layout class="activities">

    <!-- setbacks routine triggers -->
    <r-shade-card>
      <div class="grid grid-cols-3 gap-4">
        <r-setbacks-form/>
        <r-routine-form/>
        <r-triggers-form/>
      </div>
    </r-shade-card>

    <!-- daily practive, calendar, practice, journal -->
    <r-tab-card :tabs="[now, 'Calendar', 'Practice', 'Journal']">
      <template v-slot:0><r-today/></template>
      <template v-slot:1><r-calendar/></template>
      <template v-slot:2><r-practice/></template>
      <template v-slot:3><r-journal/></template>
    </r-tab-card>

  </r-program-layout>
</template>


<script>
import { DateTime } from 'luxon';
// import api from '@/api';
// import models from '@/models';
import RSetbacksForm from '@/components/forms/r-setbacks-form';
import RRoutineForm from '@/components/forms/r-routine-form';
import RTriggersForm from '@/components/forms/r-triggers-form';
import RCalendar from './r-calendar';
import RJournal from './r-journal';
import RPractice from './r-practice';
import RToday from './r-today';


export default {
  name: 'activities',
  components: {
    RSetbacksForm,
    RRoutineForm,
    RTriggersForm,
    RCalendar,
    RJournal,
    RPractice,
    RToday,
  },
  computed: {
    now() {
      return `Today, ${DateTime.now().toLocaleString({ month: 'long', day: '2-digit' })}`;
    },
  },
};
</script>

import Vue from 'vue';
import { DateTime } from 'luxon';
import { capitalize } from 'lodash';


function currency(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
}

function date(value) {
  if (!value) return '';
  return DateTime.fromISO(value).toLocaleString(DateTime.DATE_FULL);
}

function datetime(value) {
  if (!value) return '';
  return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_FULL);
}

function relative(value) {
  if (!value) return '';
  return DateTime.fromISO(value).toRelative({ style: 'short' });
}

function percentage(value) {
  return `${parseFloat(value).toFixed(0)}%`;
}


function truncate(text, length = 30, strip = true, clamp = '...') {
  if (typeof text !== 'string' || !text) return '';
  if (text.length <= length) return text;
  let truncated = text.slice(0, length);
  if (strip) {
    truncated = truncated.replace(/[.\s]+$/, '');
  }
  return truncated + clamp;
}

export default {
  register() {
    // Vue.filter('human', (value) => human(value));
    Vue.filter('date', date);
    Vue.filter('datetime', datetime);
    Vue.filter('percentage', percentage);
    Vue.filter('relative', relative);
    Vue.filter('truncate', truncate);
    Vue.filter('capitalize', (value) => capitalize(value));
    Vue.filter('currency', currency);
  },
};

import { Model } from '@vuex-orm/core';

export default class Connection extends Model {
  static entity = 'connections'

  static fields() {
    return {
      id: this.attr(null),
      display_name: this.attr(null),
      avatar: this.attr(null),
      progress: this.attr(null),
      login: this.attr(null),
      signup: this.attr(null),
      messaged: this.attr(null),
      subject: this.attr(''),
      body: this.attr(''),
      outgoing: this.attr(null),
      unread: this.attr(null),
      unreplied: this.attr(null),
      last_sent: this.attr(null),
      last_received: this.attr(null),
      waiting: this.attr(null),
    };
  }

  // waiting since date
  get waitingSince() {
    if (this.unreplied && this.last_sent) {
      return this.last_sent.created_at;
    }
    return null;
  }

  // never received a message
  get unreached() {
    return !this.last_received;
  }
}

import Connection from '@/models/connection';
import Forum from '@/models/forum';
import Topic from '@/models/topic';
import Post from '@/models/post';
import Frc from '@/models/frc';
import Indicator from '@/models/indicator';
import Journal from '@/models/journal';
import User from '@/models/user';
import Message from '@/models/message';
import Payment from '@/models/payment';
import Photo from '@/models/photo';
import Article from '@/models/article';
import Product from '@/models/product';
import AdminProduct from '@/models/admin-product';
import AdminReferrer from '@/models/admin-referrer';
import AdminBan from '@/models/admin-ban';
import AdminTraining from '@/models/admin-training';
import AdminProfile from '@/models/admin-profile';
import Course from '@/models/course';
import AdminReport from '@/models/admin-report';
import Status from '@/models/status';
import admin from '@/models/admin';
import AdminCoupon from '@/models/admin-coupon';

export default {
  Connection,
  Message,
  Forum,
  Topic,
  Post,
  Frc,
  Indicator,
  Journal,
  User,
  Payment,
  Photo,
  Article,
  Product,
  AdminProduct,
  AdminReferrer,
  AdminBan,
  AdminTraining,
  AdminProfile,
  AdminReport,
  Course,
  Status,
  admin, // collection of admin models
  AdminCoupon,
};

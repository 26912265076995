import AdminProfile from '@/models/admin-profile';
import restify from '@/extensions/restify';

const api = restify(AdminProfile, '/admin/profiles');
const coaches = restify(AdminProfile, '/admin/profiles/coaches');

export default {
  load: api.load,
  coaches: coaches.load,
};

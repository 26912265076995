<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  $padding: 30px;
  .r-logo-card {
    background: $color-white;
    .logo {
      img {
        margin: auto;
      }
      background: $color-blue-dark;
    }
    .title {
      font-size: 35px;
      text-align: center;
      padding: $padding;
      padding-bottom: 0px;
      font-weight: 400;
    }
    .content {
      padding: $padding;
      padding-bottom: 0px;
    }
    .footer {
      font-size: 14px;
      padding: $padding;
      text-align: center;
      .separator {
        margin: 0px $padding;
      }
    }
    @screen md {
      .footer {
        font-size: 16px;
      }
    }
  }
</style>

<template>
  <r-card class="r-logo-card" :padded="false">
    <div class="header">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="Reclaim">
      </div>
    </div>
    <div class="title">
      <slot name="title"></slot>
    </div>
    <div class="content">
      <slot name="content"></slot>
    </div>
    <div class="footer h-box justify-around">
      <slot name="footer"></slot>
    </div>
  </r-card>
</template>

<script>
export default {
  name: 'r-logo-card',
};
</script>

import Message from '@/models/message';
import restify from '@/extensions/restify';

const api = restify(Message, '/messages');

export default {
  load: api.load,
  create: api.create,
  update: api.update,
};

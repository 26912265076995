import { Model } from '@vuex-orm/core';

export default class Post extends Model {
  static entity = 'posts'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      created: this.attr(null),
      author: this.attr(''),
      author_id: this.attr(null),
      topic_id: this.attr(null),
      body: this.attr(''),
      body_html: this.attr(''),
      markup: this.attr(''),
      avatar: this.attr(''),
      topic_post_count: this.attr(0),
      author_post_count: this.attr(0),
      position: this.attr(0),
    };
  }
}

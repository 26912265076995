import store from '@/store';
import rest from './rest';

async function load() {
  const profile = await rest.get('/profile');
  store.set('profile', profile);
}

async function update(fields) {
  let profile = await rest.put('/profile', fields);
  store.set('profile', profile);
  // avatar upload might after profile succeeds and this might be confusing
  if (fields.avatar) {
    profile = await rest.upload('/profile/avatar', { avatar: fields.avatar });
    store.set('profile', profile);
  }
}

export default {
  load, update,
};

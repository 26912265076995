<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-message {
    border: 0 !important;
    background: $color-gray-3 !important;
    /deep/ .inner-box {
      margin-right: 0px !important;
      border-radius: 0px 5px 5px 0px !important;
    }
    &.outgoing {
      /deep/ .inner-box {
        background: $color-gray-2 !important;
      }
      background: $color-gray-5 !important;
    }
    &.unread {
      background: $color-yellow !important;
    }
    position: relative;
    .person {
      width: 125px;
    }
    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .message {
      border-left: 1px solid $color-gray-3;
    }
    .subject {
      font-weight: normal;
      font-size: 16px;
    }
    .body {
      padding-top: 5px;
      font-size: 14px;
      color: $color-gray-8;
    }
    .unread {
      color: $color-yellow-bright;
      position: absolute;
      top: 30px;
      left: 30px;
    }
  }
</style>

<template>
  <r-border-card class="r-message" :class="{outgoing, unread: message.unread}">
    <b-icon class="unread" icon="star-fill" v-show="message.unread"/>
    <div class="h-box">
      <r-identicon class="flex-limit justify-self-bottom pr-3"
                   :src="message.sender.avatar" size="48px"/>
      <div class="flex-limit person">
        <div class="name">{{message.sender.display_name}}</div>
        <div class="messaged">{{message.created_at | relative}}</div>
      </div>
      <div class="flex-grow message mr-3 pr-3 ml-3 pl-3">
        <div class="subject">
          {{message.subject}}
        </div>
        <div class="body">
          {{message.body}}
        </div>
      </div>
    </div>
  </r-border-card>
</template>

<script>
export default {
  name: 'r-message',
  props: ['message', 'inbox'],
  computed: {
    outgoing() {
      return this.message.sender.id === this.inbox;
    },
  },
  methods: {
  },
};
</script>

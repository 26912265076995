<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-calendar {
    .day {
      .title {
        height: 25px;
      }
      &.date {
        min-height: 120px;
        background: $color-gray-1;
        text-align: right;
      }
      &.heading {
        text-align: center;
        color: white;
        background: $color-gray-7;
        padding: 3px;
      }
      margin: 3px;
      padding: 10px;
    }
  }
</style>


<template>
  <div class="r-calendar">
    <div class="h-box font-medium align-items-center">
      <a href="" @click.prevent="prev" class="flex-limit" style="min-width: 100px;">
        Previous
      </a>
      <div class="flex-grow text-center text-lg">{{title}}</div>
      <a href="" @click.prevent="next" class="flex-limit text-right" style="min-width: 100px;">
        Next
      </a>
    </div>
    <div class="grid grid-cols-7">
      <div class="day heading">Sunday</div>
      <div class="day heading">Monday</div>
      <div class="day heading">Tuesday</div>
      <div class="day heading">Wednesday</div>
      <div class="day heading">Thursday</div>
      <div class="day heading">Friday</div>
      <div class="day heading">Saturday</div>
      <div class="day date" v-for="({date, day}, i) in calendar" :key="date + i">
        <div class="title" v-if="date">{{ day }}</div>
        <r-indicator-form :date="date" v-if="date"/>
      </div>
    </div>
  </div>
</template>


<script>
import { DateTime } from 'luxon';
import RIndicatorForm from '@/components/forms/r-indicator-form';

export default {
  name: 'r-calendar',
  components: {
    RIndicatorForm,
  },
  data() {
    return {
      month: DateTime.now().month,
      year: DateTime.now().year,
    };
  },
  computed: {
    current() {
      return DateTime.local(this.year, this.month, 1);
    },
    title() {
      return this.current.toLocaleString({ month: 'long', year: 'numeric' });
    },
    calendar() {
      const cal = [];
      // Sunday is day "7" and we don't want 7 spaces, Monday is day 1 and we need 1 space etc.
      for (let i = 0; i < this.current.weekday && this.current.weekday !== 7; i += 1) {
        cal.push({ date: '', day: null });
      }
      for (let day = 1; day < this.current.daysInMonth + 1; day += 1) {
        cal.push({
          date: new Date(this.year, this.month - 1, day).toISOString(),
          day,
        });
      }
      return cal;
    },
  },
  methods: {
    next() {
      this.month += 1;
      if (this.month > 12) {
        this.month = 1;
        this.year += 1;
      }
    },
    prev() {
      this.month -= 1;
      if (this.month < 1) {
        this.month = 12;
        this.year -= 1;
      }
    },
  },
};
</script>

import axios from 'axios';
import settings from '@/settings';
import ApiError from './api-error';


class Rest {
  constructor() {
    this.base = settings.api || '';
    this.handlers = {};
    this.axios = axios.create(this.defaults);
  }

  async request(method, url, data, params, headers) {
    try {
      const response = await this.axios({
        url: this.base + url,
        method,
        data: data || {},
        params: params || {},
        withCredentials: true,
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFToken',
        ...headers,
      });
      return response.data || {};
    } catch (error) {
      const status = error.response ? error.response.status : undefined;
      const err = new ApiError(`Error: ${error}`);
      err.api = true;
      err.inner = error;
      err.status = status;
      err.data = error.response ? error.response.data || {} : {};

      if (this.handlers[err.status || 'undefined']) {
        this.handlers[err.status](err);
      }
      throw err;
    }
  }

  // get request
  async get(url, params) {
    return this.request('get', url, null, params);
  }

  // put request
  async put(url, data, params) {
    return this.request('put', url, data, params);
  }

  // post request
  async post(url, data, params) {
    return this.request('post', url, data, params);
  }

  // delete request
  async delete(url, params) {
    return this.request('delete', url, null, params);
  }

  async on(status, handler) {
    this.handlers[status] = handler;
  }

  // upload a file using post request
  async upload(url, files) {
    const data = new FormData();
    for (const [id, file] of Object.entries(files)) {
      data.append(id, file);
    }
    return this.request('post', url, data, {}, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}


export default new Rest();

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-message-form {
    .error {
      border: 1px solid #d33c34;
      border-radius: 4px;
    }
  }
</style>


<template>
  <b-form class="r-message-form" @submit.prevent="submit">
    <label>
      Compose a message<span v-if="!recipient"> to:</span><span v-else>:</span>
    </label>

    <!-- recipient -->
    <b-form-group v-if="!recipient">
      <v-select :class="{error: errors.recipients}" v-model="form.recipients"
                :options="contacts" label="display_name" :reduce="c => c.id"/>
      <b-form-invalid-feedback :state="states.recipients">
        Please select a recipient.
      </b-form-invalid-feedback>
    </b-form-group>


    <!-- subject -->
    <b-form-group>
      <b-form-input v-model="form.subject" :state="states.subject" type="text"
                    placeholder="Subject"/>
      <b-form-invalid-feedback>
        Please enter a subject.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- message -->
    <b-form-group>
      <b-form-textarea rows="6" max-rows="6" v-model="form.body" :state="states.body"
                       placeholder="Message"/>
      <b-form-invalid-feedback>
        Please enter a message.
      </b-form-invalid-feedback>
    </b-form-group>

    <!-- submit -->
    <div class="h-box">
      <b-button type="submit" variant="primary" class="mr-2">Send</b-button>
      <div class="flex-spacer"></div>
      <b-button v-if="!recipient" type="submit" variant="danger" class="mr-2"
                @click.prevent="$refs.all.show()">
        Send to all
      </b-button>
    </div>

    <!-- confirm modal -->
    <r-confirm-modal ref="all" @confirm="all"/>
  </b-form>
</template>


<script>
import api from '@/api';
import form from '@/mixins/form';


export default {
  name: 'r-message-form',
  mixins: [form],
  props: ['contacts', 'recipient'],
  methods: {
    async submitted() {
      if (this.recipient) {
        this.form.recipients = this.recipient;
      }
      const msg = await api.messages.create({ ...this.form, recipients: [this.form.recipients] });
      api.alerts.success('Your message has been created.');
      this.$emit('success', msg);
    },
    async rejected() {
      api.alerts.error('Unable to send message.');
    },
    async all() {
      this.form.all = true;
      this.submit();
    },
  },
};
</script>

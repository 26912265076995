import rest from '@/api/rest';


async function index() {
  const object = await rest.get('/admin/billing');
  return object.items;
}

async function rebill(payment, fake) {
  return rest.post('/admin/billing/rebill', { payment, fake: (fake || false) });
}

export default {
  index,
  rebill,
};

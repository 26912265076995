import { Model } from '@vuex-orm/core';

export default class AdminReport extends Model {
  static entity = 'admin-reports'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      state: this.attr(''),
      progress: this.attr(null),
      filename: this.attr(''),
      url: this.attr(''),
    };
  }
}

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-paginator {
    .pages {
      color: black;
      background: $color-white;
      padding: 10px 0px;
      font-size: 14px;
      font-weight: normal;
    }
    .disabled {
      color: $color-gray-4 !important;
      text-decoration: none !important;
      cursor: default;
      pointer-events: none;
    }
    .buttons {
      // border-color: $color-gray-4;
      // border-width: 1px;
      .disabled {
        color: $color-gray-5 !important;
        pointer-events: none;
      }
      /deep/ .btn {
        color: $color-gray-8;
      }
    }

  }
</style>


<template>
  <div class="r-paginator">
    <!-- v-if="page !== undefined && (this.pages !== undefined && this.pages > 1) -->

    <!-- buttons -->
    <div v-if="variant == 'buttons'" class="pages h-box">
      <b-button-group class="buttons" size="sm">
        <b-button variant="light" v-if="pages" :class="{disabled: start}" @click.prevent="first">
          <b-icon icon="chevron-double-left"/>
        </b-button>
        <b-button variant="light" :class="{disabled: start}" @click.prevent="prev">
          <b-icon icon="chevron-left"/>
        </b-button>
      </b-button-group>
      <div class="flex-spacer"></div>
      <b-button-group class="buttons" size="sm">
        <b-button variant="light" :class="{disabled: end}" @click.prevent="next">
          <b-icon icon="chevron-right"/>
        </b-button>
        <b-button variant="light" v-if="pages" :class="{disabled: end}" @click.prevent="last">
          <b-icon icon="chevron-double-right"/>
        </b-button>
      </b-button-group>


    </div>

    <!-- simple -->
    <div v-else class="pages h-box">
      <div class="pr-2" v-if="pages">
        <a href="" :class="{disabled: start}" @click.prevent="first">First</a>
      </div>
      <div class="pr-2">
        <a href="" :class="{disabled: start}" @click.prevent="prev">Prev</a>
      </div>
      <div class="flex-spacer"></div>
      <div class="pl-2">
        <a href="" :class="{disabled: end}" @click.prevent="next">Next</a>
      </div>
      <div class="pl-2" v-if="pages">
        <a href="" :class="{disabled: end}" @click.prevent="last">Last</a>
      </div>
    </div>
  </div>
</template>


<script>
import router from '@/router';


export default {
  name: 'r-paginator',
  props: {
    total: null,
    limit: null,
    count: null,
    variant: {
      default: 'simple',
    },
    manageUrl: {
      default: true,
    },
  },
  data() {
    return {
      page: parseInt(router.currentRoute.query.page, 10) || 0,
    };
  },
  computed: {
    offset() {
      return this.limit * this.page;
    },
    start() {
      return this.page === 0;
    },
    end() {
      if (this.total !== undefined) {
        return this.page === this.pages - 1;
      }
      // if you have a perfect, count == limit then there will be 1 blank page before next disables
      // but its the best we can do without a total
      if (this.count !== undefined) {
        return this.count < this.limit;
      }
      return false;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  watch: {
    page() {
      if (this.manageUrl) {
        this.$router.replace({
          ...router.currentRoute,
          query: { ...router.currentRoute.query, page: this.page },
        });
      }
      this.$emit('changed', this.page);
    },
  },
  methods: {
    first() {
      this.page = 0;
    },
    last() {
      if (this.pages !== null && this.pages !== undefined) {
        this.page = this.pages - 1;
      }
    },
    prev() {
      this.page = Math.max(this.page - 1, 0);
    },
    next() {
      if (!this.pages) {
        this.page += 1;
      } else {
        this.page = Math.min(this.page + 1, this.pages - 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="chat-right-text"/>Forum Topics</template>
    <r-admin-card title="Topic Information">
      <r-topic-form v-if="loaded" :onSubmit="submitted" :onRemove="removed" :topic="topic" />
    </r-admin-card>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import RTopicForm from '@/components/forms/r-topic-form';

export default {
  name: 'a-topic',
  components: {
    RTopicForm,
  },
  methods: {
    async load() {
      this.topic = models.Topic.find(this.$route.params.id);
      if (!this.topic) {
        this.topic = await api.adminTopics.get(this.$route.params.id);
      }
      if (models.Forum.query().all().length === 0) {
        await api.adminForums.load();
      }
      this.loaded = true;
    },
    async submitted(form) {
      this.topic = await api.adminTopics.update(form.id, form);
      api.alerts.success('Topic updated.');
    },
    async removed(form) {
      await api.adminTopics.remove(form);
      this.$router.push({ name: 'admin-topics' });
    },
  },
  data() {
    return {
      topic: {},
      loaded: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await vm.load();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    await this.load();
    next();
  },
};
</script>

import AdminProduct from '@/models/admin-product';
import restify from '@/extensions/restify';

const api = restify(AdminProduct, '/admin/products');

export default {
  load: api.load,
  get: api.get,
  update: api.update,
  create: api.create,
};

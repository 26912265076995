<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-alerts {
    position: fixed;
    top: 0;
    width: 100%;
    .alert {
      margin: 10px auto;
      @screen md {
        max-width: $content-width;
      }
    }
  }
</style>


<template>
  <div class="r-alerts">
    <b-alert class="alert" v-for="(alert, key) in alerts" :key="key"
             show dismissible @dismissed="dismiss(alert.message)" :variant="alert.level" >
      {{alert.message}}
    </b-alert>
  </div>
</template>


<script>
import api from '@/api';
import { get } from 'vuex-pathify';

export default {
  name: 'r-alerts',
  computed: {
    alerts: get('alerts'),
  },
  methods: {
    dismiss(message) {
      api.alerts.dismiss(message);
    },
  },
};
</script>

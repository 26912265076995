import { Model } from '@vuex-orm/core';

export default class Article extends Model {
  static entity = 'articles'

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.attr(null),
      program: this.attr(null),
      title: this.attr(''),
      description: this.attr(''),
      author: this.attr(''),
      body: this.attr(''),
      video: this.attr(''),
      audio: this.attr(''),
      thumbnail: this.attr(''),
      featured: this.attr(false),
    };
  }
}

import { render, staticRenderFns } from "./notfound.vue?vue&type=template&id=439c1208&scoped=true&"
import script from "./notfound.vue?vue&type=script&lang=js&"
export * from "./notfound.vue?vue&type=script&lang=js&"
import style0 from "./notfound.vue?vue&type=style&index=0&id=439c1208&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "439c1208",
  null
  
)

export default component.exports
<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';

  .r-connection {
    margin-top: 15px !important;
    /deep/ .inner-box {
      margin: 10px !important;
      padding: 10px !important;
    }
    position: relative;
    .person {
      width: 125px;
    }
    .waiting, .unreached {
      color: $color-red-bright;
    }
    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .details, .messaged {
      font-size: 13px;
      // font-family: monospace;;
    }
    .details {
      text-align: right;
    }
    .message {
      border-left: 1px solid $color-gray-3;
      border-right: 1px solid $color-gray-3;
    }
    .subject {
      font-weight: normal;
      font-size: 16px;
    }
    .body {
      padding-top: 5px;
      font-size: 14px;
      color: $color-gray-8;
    }
    &.unread {
      background: $color-blue-dull !important;
    }
  }
</style>

<template>
  <r-border-card class="r-connection" :class="{unread: connection.unreplied}">

    <!-- <b-icon class="unread" icon="star-fill" v-show="connection.unreplied"/> -->
    <div class="h-box">
      <r-identicon class="flex-limit justify-self-bottom pr-3"
                   :src="connection.avatar" size="64px"/>
      <div class="flex-limit person">
        <div class="name">{{connection.display_name}}</div>
        <div class="messaged">{{connection.messaged | relative}}</div>
      </div>
      <div class="flex-grow message mr-3 pr-3 ml-3 pl-3">
        <div class="subject">
          <router-link :to="{ name: 'conversation', params: { id: connection.id }}">
            {{(connection.subject || 'Start a conversation...') | truncate(60)}}
          </router-link>
        </div>
        <div class="body">
          {{connection.body | truncate(60)}}
        </div>
      </div>
      <div class="flex-limit details">
        <r-connection-details :connection="connection"/>
      </div>
    </div>
  </r-border-card>
</template>

<script>
import RConnectionDetails from './r-connection-details';

export default {
  name: 'r-connection',
  props: ['connection'],
  components: { RConnectionDetails },
  computed: {
  },
  methods: {
  },
};
</script>

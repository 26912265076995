<style scoped lang="scss">
  @import '@/assets/styles/variables.scss';
</style>


<template>
  <r-admin-layout>
    <template slot="title"><b-icon icon="slash-circle-fill"/>Forum Bans</template>
    <template slot="buttons">
      <router-link :to="{ name: 'admin-ban', params: {id: 'new'} }">
        <button class="admin-btn">Add Ban</button>
      </router-link>
    </template>
    <div class="h-3 flex-shrink-0"></div>

    <!-- search -->
    <b-input-group>
      <b-form-input type="text" v-model="term" @input="search" placeholder="Search for Ban"></b-form-input>
      <b-input-group-append>
        <b-button @click="search" variant="primary"><b-icon icon="search"/></b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="h-3 flex-shrink-0"></div>
    <b-table-lite striped bordered :items="bans">
      <template #cell(username)="data">
        <router-link :to="{ name: 'admin-ban', params: {id: data.value.id} }">
          {{ data.value.username }}
        </router-link>
      </template>
    </b-table-lite>
    <r-paginator @changed="load"/>
  </r-admin-layout>
</template>


<script>
import api from '@/api';
import models from '@/models';
import { debounce } from 'lodash';

export default {
  name: 'a-bans',
  methods: {
    async load(page) {
      api.adminBans.load({
        limit: this.limit,
        offset: this.limit * (page || 0),
        query: this.term,
      });
    },
    search: debounce(function () {
      if (this.term) {
        this.$router.replace({
          name: 'admin-bans',
          query: { query: this.term },
        });
      } else {
        this.$router.replace({ name: 'admin-bans' });
      }
      this.load(0);
    }, 600),
  },
  computed: {
    bans() {
      return models.AdminBan.query().orderBy('id', 'desc').all()
        .map((b) => ({
          username: { username: b.username, id: b.id },
          start: b.ban_start.substring(0, 10),
          end: b.ban_end.substring(0, 10),
          reason: b.reason,
        }));
    },
  },
  data() {
    return {
      limit: 25,
      term: '',
    };
  },
  async created() {
    this.term = new URLSearchParams(window.location.search).get('query');
    await this.load();
  },
};
</script>
